import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { PayPalButton } from 'react-paypal-button-v2';
import Cookies from 'js-cookie';
import { withRouter } from 'react-router';

import User from '../../../helpers/user';
import { POST } from '../../../helpers/requests';

import styles from './Payment.module.css'

const user = new User();

const payment = (props) => {
  return(
    <Container>
      <Row>
        <Col lg={8} md={6}>
          <div className={styles.header}>
            <h3>Order Summary</h3>
          </div>
          <div className={styles.body}>
            <Row>
              <Col xs={9}className={styles.label}>
                Seller Subscription
              </Col>
              <Col xs={3} className={styles.content}>
                $250.00
              </Col>
            </Row>
            <Row style={{paddingTop: '1rem'}}>
              <Col lg={12}className={styles.label}>
                Description:
              </Col>
              <Col lg={12}>
                Monthly recurring subscription to access to advertising services. No Contract, cancel anytime.
              </Col>
            </Row>
          </div>
          <div className={styles.footer}>
            <Row>
              <Col xs={9} className={styles.total}>Total</Col>
              <Col xs={3} className={styles.content}>$250.00*</Col>
            </Row>
            <Row>
              <Col lg={12}>
                * Free for the first 60 days Then $250.00 USD for each month (Renews until you cancel)
              </Col>
            </Row>
          </div>
        </Col>
        <Col lg={4} md={6}>
          <PayPalButton
            options={{
              clientId: process.env.REACT_APP_PAYPALCLIENTID,
              vault: true,
              disableFunding:'credit'
            }}
            createSubscription={(data, actions) => {
              return actions.subscription.create({
                plan_id: process.env.REACT_APP_PAYPALPLANID
              });
            }}
            onApprove={(data, actions) => {
              // Capture the funds from the transaction
              return actions.subscription.get().then(async details => {
                const url=`/subscriptions/subscription/${props.user.id}/${data.subscriptionID}/${data.orderID}`;
                await POST(url);
                const loginUrl = 'users/login';
                const config = {
                  body: {
                    email: props.userFormData.username,
                    password: props.userFormData.password
                  }
                };
                const authResponse = await POST(loginUrl, config);
                if(authResponse.status === 200){
                  user.setUser(authResponse.data.userDetails);
                  Cookies.set('token', authResponse.data.token);
                  props.history.push('/#Registered');
                }
              });
            }}
            onError={err => {
              console.log(err);
            }} 
          />
        </Col>
      </Row>
    </Container>
  )
}

export default withRouter(payment);