import React from 'react';
import {Form, Button, Tooltip, OverlayTrigger} from 'react-bootstrap';

import Modal from '../../../components/Modal/Modal';
import Toast from '../../../components/Toast/Toast'
import Aux from '../../../hoc/Auxiliary/Auxiliary';
import {POST} from '../../../helpers/requests';

import styles from './ContactUs.module.css';
import {ReactComponent as Info} from "../../../assests/images/info.svg";

const EMAIL_CONFIG = [
  {
    label:'Please Select One',
    value:''
  },{
    value: "REGISTRATION_SALES_EMAIL_TO",
    label: 'Sales'
  },{
    value: "REGISTRATION_SUPPORT_EMAIL_TO",
    label: 'Support'
  },{
    value:"REGISTRATION_GENERAL_EMAIL_TO",
    label:'General Inquiry'
  }
];

const contactUs = (props) => {

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.target;
    let subject = '';
    if (form.Recipient.value === 'REGISTRATION_SALES_EMAIL_TO') {
      subject = 'Contact Us - Sales (' + form.Subject.value + ')'
    }
    if (form.Recipient.value === 'REGISTRATION_SUPPORT_EMAIL_TO') {
      subject = 'Contact Us - Support (' + form.Subject.value + ')'
    }
    if (form.Recipient.value === 'REGISTRATION_GENERAL_EMAIL_TO') {
      subject = 'Contact Us - Inquiry (' + form.Subject.value + ')'
    }
    const config = {
      body: {
        Name: form.Name.value,
        Email: form.Email.value,
        Phone: form.Phone.value,
        Subject: subject,
        Message: form.Message.value
      },
      successMessage: 'Your message has been sent.'
    }

    const url = '/lead/' + form.Recipient.value;

    const response = await POST(url, config);
    props.setToastConfig({
      ...response.toast.toastConfig
    })
    props.updateToast();
    props.onHide();
  };

  const routeLead = () => {
    return EMAIL_CONFIG.map(config => {
      return <option key={config.label} value={config.value}>{config.label}</option>
    })
  };

  return (
    <Aux>
      <Toast
        show={props.showContactToast}
        delay={props.toastConfig.delay || 0}
        onClose={props.updateToast}
        autohide={props.toastConfig.autohide}
        variant={props.toastConfig.variant}
        title={props.toastConfig.title}
        message={props.toastConfig.message}
      />
      <Modal
        show={props.show}
        onHide={props.onHide}
        title='Contact Us'
        showbutton='true'
      >
        <div>
          <p>Email us with any questions or inquiries or call <a href='tel:+18665489420'>+1 (866) 548-9420</a>.</p>
          <p>We would be happy to answer your questions and set up a meeting with you.</p>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId='Name'>
              <Form.Label>Name</Form.Label>
              <Form.Control placeholder='Enter your name' required/>
            </Form.Group>
            <Form.Group controlId='Email'>
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="youremail@example.com" id="Email" required/>
            </Form.Group>
            <Form.Group controlId="Phone">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control type="tel" placeholder="5555555555" pattern='^[2-9]\d{2}\d{3}\d{4}$' title='5555555555'
                            required/>
              <Form.Control.Feedback type="invalid">
                Please enter a valid phone number.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Department
                <OverlayTrigger key='top' placement='top' overlay={
                  <Tooltip id='tooltip-top'>
                    Helps us facilitate your message
                  </Tooltip>
                }>
                  <Info width='1rem' height='1rem' fill='#306a39' style={{marginLeft:'0.5rem'}}/>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control as="select" id="Recipient" required>
                {routeLead()}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="Subject">
              <Form.Label>Subject</Form.Label>
              <Form.Control type="text" placeholder="Subject for email"/>
            </Form.Group>
            <Form.Group controlId="Message">
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" rows="3"
                            placeholder="Please give us a brief description of why you are contacting us." required/>
            </Form.Group>
            <Form.Group className={styles.buttonGroup}>
              <Button variant='danger' onClick={props.onHide}>Cancel</Button>
              <Button type='submit'>Submit</Button>
            </Form.Group>
          </Form>
        </div>
      </Modal>
    </Aux>
  )
}

export default contactUs;