import React from 'react';
import { Pagination } from "react-bootstrap";

import styles from './Pagination.module.css';

const pagination = (props) => {
  const isFirstPage = () => {
    return props.offset <= 16;
  }

  const handlePrevious = () => {
    const offset = props.offset - (props.limit*2)
    props.setOffset(offset);
  }

  const handleFirst = () => {
    props.setOffset(0);
  }

  const handleLast = () => {
    const offset = props.count - ((props.count%props.limit) || 16);
    props.setOffset(offset);
  }

  const handlePage = (event) => {
    const offset = parseInt(event.target.dataset.offset, 10);
    props.setOffset(offset);
  }

  const generatePages = () => {
    const pages = [];
    const lastPage = Math.ceil(props.count/props.limit);
    const maxAmount = process.env.REACT_APP_MAX_PAGES || 5 < lastPage ? process.env.REACT_APP_MAX_PAGES || 5 : lastPage;
    let sliceStart = 0;
    let sliceEnd = maxAmount;
    for(let i = 0; i < props.count; i += props.limit) {
      pages.push(
        <Pagination.Item
          key={i}
          onClick={handlePage}
          data-offset={i}
          active={(i / props.limit) + 1 === props.activePage}
        >{i / props.limit + 1}</Pagination.Item>
      )
    }
    const middle = Math.round(maxAmount/2);
    if(props.activePage > middle){
      const offset = props.activePage - middle;
      sliceEnd = (sliceEnd + offset) > lastPage ? lastPage : sliceEnd + offset;
      sliceStart = sliceEnd - (sliceStart + offset) < maxAmount ? lastPage-maxAmount : sliceStart + offset;
    }
    return(pages.slice(sliceStart, sliceEnd));
  }

  return (
    <div className={styles.paginationContainer}>
      <Pagination>
        <Pagination.First disabled={isFirstPage()} onClick={handleFirst}/>
        <Pagination.Prev disabled={isFirstPage()} onClick={handlePrevious}/>
        {generatePages()}
        <Pagination.Next disabled={props.lastPage} onClick={() => {props.getProducts(true)}}/>
        <Pagination.Last disabled={props.lastPage} onClick={handleLast}/>
      </Pagination>
    </div>
  );
};

export default pagination;
