import React from 'react';
import { Button, Navbar, Nav, Form, Table }  from 'react-bootstrap';
import NewAd from './NewAd/NewAd';

const getExistingAds = (props) => {
  const divStyle = {
    display: 'flex'
    
  };
  const getExistingAds = () => {
    let ads = []
    if(props.ExistingAds){
      props.ExistingAds.forEach(result => {
          console.log(result);
            // ads.push(
            //   <tr key={result.users.id}>
            //     <td>{result.users.firstname}</td>
            //     <td>{result.users.lastname}</td>
            //     <td>{result.users.username}</td>
            //     <td>{accountType}</td>
            //     <td>{result.users.license_number}</td>
            //     <td>{result.users.phone}</td>
            //     <td>{salesRep}</td>
            //     <td>
            //       <div style={divStyle}>
            //         <Button variant="info" onClick={props.doHandleEdit} data-id={result.users.id} ><img src="https://cdn2.iconfinder.com/data/icons/flat-ui-icons-24-px/24/new-24-24.png" height="20px" style={{pointerEvents: 'none'}}></img></Button>
            //       </div>
            //     </td>
            //   </tr>
            // )
        });
    }
    return ads;
  }
  return(
    <div>
      <Navbar bg="light" variant="light">
        {/* <Nav className="mr-auto">
        <select name="listView" onChange={props.listViewChange}>
          <option value="">All Users</option>
          <option value="active">Active Users</option>
          <option value="dispensaries">Dispensaries</option>
          <option value="processors">Processors</option>
          <option value="growers">Growers</option>
          <option value="inactive">Inactive Users</option>
        </select>
        </Nav> */}
        <Form inline>
          <Button variant="info" onClick={props.handleNewClick}>New Ad</Button>
        </Form>
      </Navbar>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Name</th>
            <th>Status</th>
            <th>Image</th>
            <th>Display Time</th>
            <th>Row</th>
            <th>Column</th>
            <th>Page</th>
            <th>State</th>
            <th>ad Link</th>
          </tr>
        </thead>
        <tbody>
        {getExistingAds()}
        </tbody>
      </Table>
      <br />
      <br />
      <NewAd {...props} handleClose={props.handleClose} doSubmit={props.doHandleSubmit}/>
    </div>
  )
}
export default getExistingAds;