import React, { Component } from 'react';
import { Container, Spinner} from 'react-bootstrap';
import { withRouter } from 'react-router';
import Cookies from 'js-cookie';
import qs from 'querystring';

import AgeVerify from './AgeVerify/AgeVerify';
import Search from './Search/Search';
import ProductList from './ProductList/ProductList';
import Filters from './Filters/Filters';
import FilterBadges from './FilterBadges/FilterBadges';
import Pagination from './Pagination/Pagination';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import Toast from '../../components/Toast/Toast';
import { GET } from '../../helpers/requests';
import User from '../../helpers/user';
import styles from './Products.module.css';
import Slider from 'react-animated-slider';
import slides from '../../constants/slides';
import ReactGA from 'react-ga';

const trackingId = "UA-189846059-1"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);


class Products extends Component {
  _isMounted = false;
  user = new User();
  state={
    loading: true,
    showAgeVerify: false,
    showToast: false,
    showFilters: false,
    toastConfig: {},
    products: [],
    filters: {
      q:'',
      limit: 16,
      offset: 0
    } ,
    variant: 'success',
    user: this.user.getUser(),
    mylistings: false,
    managelistings: false,
    hasMoreProducts: true,
    count: 0,
    activePage: 0
  }

  UNSAFE_componentWillReceiveProps = async (newProps) => {
    if (this.state.user !== undefined && newProps.location.hash === '#Logout') {
      this.setState({
        user: this.user.getUser()
      })
    }
  }

  componentDidMount = () => {
    this.getConfigs();
    const search = qs.parse(this.props.location.search);
    Object.keys(search).forEach(key => {
      if(key[0] === '?'){
        search[key.substring(1)] = search[key];
      }
    });
    this._isMounted = true;
    const ageVerified = Cookies.get('ageVerified');
    if(ageVerified === 'false'){
      this.under21Redirect();
    }
    else if(ageVerified === 'undefined' || ageVerified === undefined) {
      this.setState({showAgeVerify: true});
    }
    else{
      let isManageListing = false;
      if(this.props.location.pathname === '/manage-listings'){
        isManageListing = true;
      }
      this.setState(prevState => ({
        managelistings: isManageListing,
        filters: {
          ...prevState.filters,
          offset: search.offset || 0
        }
      }), () => {
        this.getProducts();
      });
     
    }
  }

  // handleScroll = () => {
  //     const position =  Math.round(window.innerHeight+window.pageYOffset);
  //     const maxHeight = document.documentElement.offsetHeight;
  //     if (this.state.hasMoreProducts && !this.state.loading){
  //       if(position === maxHeight || position-1 === maxHeight) {
  //         this.setState({isScollLoading: true}, () => {
  //           window.setTimeout(() => {
  //             this.getProducts(true, true);
  //           }, 50)
  //         })
  //       }
  //     }
  // }

  componentWillUnmount = () => {
    this._isMounted = false;
  }

  handleSearchChange = (event) => {
    clearTimeout(this.state.searchTimeout);
    const value = event.currentTarget.value
    this.setState(prevState => ({
        filters: {
          ...prevState.filters,
          q:value
        },
        searchTimeout: window.setTimeout(() => {
            this.setState(prevState => ({
                filters: {
                  ...prevState.filters,
                  offset: 0,
                  limit: 16
                }
            }), () => {
                this.getProducts(true);
            })
        }, 750)
    }));
  }

  handleFilterChange = (event) => {
    const filter = event.target.dataset.type;
    const filters = this.state.filters;
    const filterValue = JSON.parse(event.target.value || event.target.dataset.value);
    let filterIndex;
    if(filters[filter] && filters[filter].length > 0){
      filters[filter].forEach((appliedFilter, index) => {
        if(appliedFilter.Name === filterValue.Name){
          filterIndex = index; 
        }
      })
    }
    if(filterIndex !== undefined){
      filters[filter].splice(filterIndex, 1);
      this.setState({
        filters: {
          ...filters,
          offset:0,
          limit: 16
        }
      })
    }
    else{
      if(Array.isArray(filters[filter]) && (filter !== 'Category' && filter !== 'Delivery' && filter !== 'Tested')){
        filters[filter].push(filterValue);
      }
      else{
        filters[filter] = [filterValue]
      }
      this.setState({
        filters: {
          ...filters,
          offset: 0,
          limit: 16
        }
      });
    }
  }

  clearFilterBadges = (event) => {
    this.handleFilterChange(event);
    window.setTimeout(() => {
      this.getProducts(true);
    }, 100)
  }

  getQueryParams = () => {
    const filterKeys = Object.keys(this.state.filters);
    if(filterKeys.length === 1 && this.state.filters.q === '' && !this.state.managelistings){
      return '';
    }
    let result = '?';

    if(this.state.managelistings){
      if(!this.state.user.isAdmin){
        result += 'owner='+this.state.user.id;
      }
    }
    
    filterKeys.forEach((key) => {
      if(result !== '?' && this.state.filters[key].length!==0){
        result += '&';
      }
      let value;
      switch(key){
        case 'Area':
          value = 'id';
          break;
        case 'Delivery':
        // case 'Tested':
        //   value = 'Value';
        //   break;
        default:
          value = 'Name';
          break;
      }
      if(Array.isArray(this.state.filters[key]) && this.state.filters[key].length > 0){

        this.state.filters[key].forEach((filter, index) => {
          if(index !== 0){
            result = `${result}&`
          }
          result = `${result}${key}=${filter[value]}`;
        })
      }
      else if(!Array.isArray(this.state.filters[key]) && this.state.filters[key] !== ''){
        result = `${result}${key}=${this.state.filters[key]}`
      }
    })
    return(result);
  }

  getProducts = async (updateOffset, appendProducts) => {
    const newOffset = parseInt(this.state.filters.offset) + this.state.filters.limit;
    this.props.history.push({search:qs.stringify({offset: this.state.filters.offset})})
    this.setState({loading: true}, () => {
      window.scrollTo(0,0);
    });
    const url = `/products${this.getQueryParams()}`;
    const response = await GET(url);
    if(Object.keys(response).includes('toastConfig')){
      this.setState({...response, loading: false});
    }
    else{
      this.setState(prevState => ({
        products: appendProducts ? [...prevState.products, ...response.products] : response.products,
        count: response.count,
        loading: false,
        activePage: (newOffset/this.state.filters.limit),
        filters: {
          ...prevState.filters,
          offset: updateOffset ? newOffset : prevState.filters.offset
        },
        hasMoreProducts: response.count > newOffset,
        isScollLoading: false
      }));
    }

  }

  getConfigs = async () => {
    const configs = await GET('/configs');
    if(Object.keys(configs).includes('toastConfig')){
      this.setState({...configs});
    }
    else{
      this.setState({configs})
    }
  }

  updateModal = (modalShowProp) => {
    const newState = {};
    newState[modalShowProp] = !this.state[modalShowProp];
    if(modalShowProp === 'showAgeVerify'){
      this.setAgeVerifiedCookie(true);
    }
    this.setState(newState);
  }

  under21Redirect = () => {
    this.setAgeVerifiedCookie(false);
    this.props.history.push('/under21');
  }

  setAgeVerifiedCookie = (value) => {
    Cookies.set('ageVerified', value);
    this.getProducts();
  }

 setOffset = value => {
    this.setState(prevState => ({
      filters:{
        ...prevState.filters,
        offset:value
      }
    }), () => {
      this.getProducts();
    });
  }

  noProducts = () => {
    if(!this.state.loading && this.state.products && this.state.products.length === 0 && (Object.keys(this.state.filters).length > 1 || this.state.filters.q !== '')){
      return(
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <h1>No products found</h1>
        </div>
      )
    }
  }

  clearFilters = () => {
    this.setState(prevState => (
      {
        filters: {
          q: '',
          offset: prevState.filters.offset,
          limit: 16
        }
      }), () => {
        this.getProducts();
      }
    )
  }

  applyFilters = () => {
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        offset:0,
        limit: 16
      }
    }))
    this.getProducts();
    this.updateModal('showFilters')
  }
  pageContent = () => {
    const loading = () => {
      if(this.state.loading){
        return(
          <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: '3rem', marginBottom:'5rem'}}>
            <Spinner animation="border" />
          </div>
        )
      }
    }
    return(
      <Aux>
        {loading()}
        <ProductList products={this.state.products} ismanagelisting={this.state.managelistings} user={this.state.user}/>
      </Aux>
    )
  }
  showAdButton = (ad) => {
    if(ad.button){
      return  (<button  onClick={(e) => {
                e.preventDefault();
                window.location.href=ad.url;
              }}>
            {ad.button}
          </button>)
    }
  }
  render(){
      return(
        <Aux>
          <Toast 
            {...this.state.toastConfig}
            show={this.state.showToast}
            onClose={() => {this.updateModal('showToast')}}
          />
          <AgeVerify 
            showAgeVerify={this.state.showAgeVerify} 
            updateModal={() => {this.updateModal('showAgeVerify')}}
            under21Redirect={this.under21Redirect}
          />
          <Filters 
            {...this.state.configs} 
            show={this.state.showFilters} 
            onHide={() => {this.updateModal('showFilters')}}
            doClear={this.clearFilters}
            filters={this.state.filters}
            onFilterChange = {this.handleFilterChange}
            varaint={this.state.variant}
            onApplyFilters={this.applyFilters}
          />
          <Container className={styles.container}>
          <Slider classNames={styles} autoplay={4000}>
            {slides.map((item, index) => (
              <div
                key={index}
                className="adContainer"
              >
                 <img src={require('../../assests/images/ads/' + item.image)} alt={item.image} style={{ display: 'flex', alignItems: 'center',width: '100%', height: '100%' }}/>
                <div className="center">
                  <h1 className={styles[item.titleClass]}>{item.title}</h1>
                  <p className={styles[item.descriptionClass]}>{item.description}</p>
                  {this.showAdButton(item)}
                </div>
              </div>
            ))}
          </Slider>
            <Search 
              query={this.state.filters.q} 
              onSearchChange={this.handleSearchChange}
              showFilters={() => {this.updateModal('showFilters')}}
            />
             <FilterBadges filters={this.state.filters} onFilterChange={this.clearFilterBadges}/>
            {this.pageContent()}
            {this.noProducts()}
            <Pagination
              offset={this.state.filters.offset}
              limit={this.state.filters.limit}
              lastPage={!this.state.hasMoreProducts}
              getProducts={this.getProducts}
              setOffset={this.setOffset}
              count={this.state.count}
              activePage={this.state.activePage}
            />
          </Container>
        </Aux>
      )
    }
}

export default withRouter(Products);