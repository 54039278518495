import React, { Component } from 'react';
import { Chart } from "react-google-charts";
import styles from './../Dashboards.module.css';
import { GET } from '../../../helpers/requests'
import User from '../../../helpers/user';

export default class SoldProductsBySubCategory extends Component {
    user = new User();

    _isMounted = false;
  
    state = {
      user: this.user.getUser()
    }
    pieOptions = {
        pieHole: 0,
        legend: {
            position: "right",
            alignment: "center",
            textStyle: {
                color: "233238",
                fontSize: 14
            }
        },
        tooltip: {
            showColorCode: true
        },
        chartArea: {
            left: 0,
            top: 20,
            width: "100%",
            height: "80%"
        },
        fontName: "Roboto"
    };
    componentDidMount() {
        this._isMounted = true;
        this.getSoldProductsBySubCategory();
      }
    componentWillUnmount() {
        this._isMounted = false;
    }
    getSoldProductsBySubCategory = async () => {
        const url = `/dashboards/soldproducts/subcategory/${this.state.user.id}`
        const listedProducts = await GET(url);
        let data = [["Subcategory", "Count"]];
        listedProducts.forEach((item) => {
            let category = [item.Subcategory, item.count];
            data.push(category);
        })
        this.setState({
            "data": data
        });
    }
    render(){
        return (
            <div className={styles.chartContainer}>
                 <div className={styles.title}>SOLD PRODUCTS BY SUBCATEGORY</div>
                 <div>
                 <Chart
                    chartType="PieChart"
                    data={this.state.data}
                    options={this.pieOptions}
                    graph_id="SubCategories"
                    width={"100%"}
                    legend_toggle
                />
                </div>
          </div>
        )
    }
}