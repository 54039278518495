import React from 'react';
import { Button } from 'react-bootstrap';

import Aux from '../../../hoc/Auxiliary/Auxiliary'
import Modal from '../../../components/Modal/Modal';

const deleteModal = (props) => {
  const actions = () => {
   return (
     <Aux>
      <Button variant='success' onClick={props.onDelete}>Yes</Button>
      <Button variant='danger' onClick={props.onHide}>No</Button>
    </Aux>
   )
  }

  return (
    <Modal show={props.show} title='Delete Product' actions={actions()} onHide={props.onHide}>
      <p>Are you sure you would like to remove {props.name} from your cart?</p>
    </Modal>
  )
}

export default deleteModal;