import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import Layout from './hoc/Layout/Layout';
import Router from './components/Router/Router';

import './App.css';

export default class App extends Component {
  state = {
    user:undefined,
    host: process.env.REACT_APP_HOST
  };

  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <Layout {...this.state} handleSetUser={this.setUser} handleLogout={this.logout}>
            <Router {...this.state} handleSetUser={this.setUser}/>
          </Layout>
        </BrowserRouter>
      </div>
    );
  }

}
