import React from 'react';

const cancellationPolicy = (props) => {
  return(
    <p style={{textAlign: 'justify'}}>
      <b>Cancellation of Membership:</b> Membership with greenleaftrader.com is provided on a monthly basis unless otherwise modified by us. 
      You may cancel your membership at any time. No prorations shall be made regardless of date of cancellation by you. For classification 
      purposes only, if you were to cancel on December 15, 2019, you would have the right to continued access to greenleaftrader.com through 
      December 31, 2019 (or the monthly period during which the cancellation was received by us). Nothing contained in this paragraph shall 
      modify or restrict our right to immediately terminate or suspend your greenleaftrader.com account for violation or non-compliance with 
      these Terms of Use or Privacy Policy.   
    </p>
  )
}

export default cancellationPolicy;
