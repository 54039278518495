import React from 'react';

import Aux from '../../../hoc/Auxiliary/Auxiliary'

import OrderItem from '../OrderItem/OrderItem';
const open = (props) => {
  const orderItem = () => {
    if(props.lists.length > 0){
      return props.lists.map(list => {
        return <OrderItem key={list.id} list={list} view='open' onShowModal={props.onShowModal} showDeliveryAddress/>
      })
    }
    return <h4>No Open Orders</h4>
  }

  return(
    <Aux>
      {orderItem()}
    </Aux>
  )
}

export default open;