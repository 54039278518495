import React from 'react';
import { Button, Form } from 'react-bootstrap';


import Modal from '../../../../components/Modal/Modal';
import Aux from '../../../../hoc/Auxiliary/Auxiliary';

const confirmCancel = (props) => {
  const actions = () => {
    return(
      <Aux>
        <Button variant="danger" onClick={props.doSuspension}>Suspend Subscription</Button>
        <Button variant="success" onClick={props.onHide}>Go Back</Button>
      </Aux>
    )
  }

  return(
    <Modal title='Confirm Suspension' show={props.show} actions={actions()} onHide={props.onHide} showbutton="true">
        <div>
          <p>
            Suspend verbiage here.
          </p>
          <Form>
          <Form.Group>
                <Form.Control 
                  as="textarea"
                  id='reason' 
                  maxLength={255}
                  rows={3}
                  placeholder="Please leave a brief description of why you are suspending your susbscription."
                  onChange={props.doInputChange}
                  value={props.reason}
                />
              </Form.Group>
          </Form>
        </div>
    </Modal>
  )
}

export default confirmCancel;