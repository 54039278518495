export default [
	{
		title: '',
		description:'',
		button: '',
		image: 'organicsOKC.PNG',
		url: ''
	},
	{
		title: '',
		description:'',
		button: '',
		image: 'image0.jpeg',
		url: ''
	},
	{
		title: '',
		description:'',
		button: '',
		image: 'organicsOKC.PNG',
		url: ''
	},
	{
		title: '',
		description:'',
		button: '',
		image: 'image0.jpeg',
		url: ''
	},
];