import React, { Component } from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { withRouter, Link } from 'react-router-dom';
import cookies from 'js-cookie';

import Aux from '../../hoc/Auxiliary/Auxiliary';
import Toast from '../../components/Toast/Toast'
import Modal from '../../components/Modal/Modal';
import Copyright from './Copyright/Copyright'
import LoginContainer from './LoginContainer/LoginContainer';
import AboutUs from './AboutUs/AboutsUs';
import ContactUs from './ContactUs/ContactUs';
import BuyerOrSeller from '../../components/BuyerOrSeller/BuyerOrSeller'
import User from '../../helpers/user';
import { GET } from '../../helpers/requests';

import styles from './Navigation.module.css';
import logo from '../../assests/images/logo.jpg';
import { ReactComponent as Cart } from '../../assests/images/smart-cart.svg';

class Navigation extends Component {
  user = new User();

  state = {
    showLogin: false,
    showAboutUs: false,
    showContactUs: false,
    showBuyerOrSeller: false,
    showForgotPassword: false,
    showLoginToast: false,
    showContactToast: false,
    showLogoutToast: false,
    toastConfig: {},
    user: this.user.getUser(),
    copyrightModal: false
  }

  UNSAFE_componentWillReceiveProps = async (newProps) =>{
    if(this.state.user === undefined && newProps.location.hash === '#Login'){
      this.setState({
        showLogin: true
      })
    } else if(newProps.location.hash === '#Login'){
        const user =  this.user.getUser();
        if(Object.keys(user).length > 1) {
            this.setState({
                user: this.user.logout()
            }, () => {
                window.setTimeout(() => {
                    this.setState({
                        showLogin: true,
                        user: this.user.logout(),
                        toastConfig: {
                            title: 'Session Expired',
                            message: 'Please login again',
                            variant: 'danger',
                            delay: 5000,
                            autohide: true
                        },
                        showLoginToast: true
                    })
                }, 500)
                this.props.history.push('/');
            })
        }
    }
    if(this.state.user === undefined && newProps.location.hash === '#Registered'){
      this.setState({
        user: this.user.getUser(),
        showWelcomeMessage: true
      })
    }
    if(newProps.location.hash === '#Logout' && this.state.user !== undefined){
      this.logout();
    }
    const hash = newProps.location.hash;
    if(hash === '#Delete' || hash === '#Add' || hash === '#Submit'){
      this.getListLength();
    }
  }

  componentDidMount = async () => {
    await this.getListLength();
  }

  getListLength = async () => {
    const user = this.user.getUser();
    if(user !== undefined && Object.keys(user).length > 1){
      this.setState({
        user
      }, async () => {
        let listLength = 0;
        if(this.state.user){
          if(this.state.user.listid !== undefined && this.state.user.listid !== ''){
            const url = `/lists/${this.state.user.listid}`;
            const response = await GET(url, {component: this});
            if(!Object.keys(response).includes('toast')){
              listLength = response.length;
            }
          }
          const user = this.user.setUser({
            ...this.state.user,
            listLength
          })
          this.setState({
            user
          }) 
        }
      });
    }
  }

  logout = () => {
    const firstName = this.state.user.firstname;
    this.setState({user: this.user.logout()}, () => {
      this.setToastConfig({
        variant: 'success',
        title: 'Success',
        message: `Logout Successful. Goodbye ${firstName}!`,
        delay: 3000,
        autohide: true
      })
      this.updateModal('showLogoutToast');
      this.props.history.push('/#Logout');
    });
  }

  setToastConfig = (configObj) => {
    this.setState({toastConfig: {...configObj}});
  }

  activeLink = (value, isPermanent) => {
    let results = styles.link;
    if(isPermanent){
      results = `${styles.link} ${styles.permanent}`
    }
    if(this.props.location.pathname === value){
      results += ` ${styles.active}`;
    }
    return results;
  }
  
 growerProcessorLinks = (isMobile) => {
    if(this.state.user !== undefined && (this.state.user.isGrower || this.state.user.isProcessor)) {
      if(!isMobile){
        return(
          <Aux>
            <NavDropdown.Item href="/new">Upload New Listing</NavDropdown.Item>
            <NavDropdown.Item href="/manage-listings">Manage Listings</NavDropdown.Item>
            <NavDropdown.Item href="/dashboard">Dashboard</NavDropdown.Item>
          </Aux>
        )
      }
      return(
        <Aux>
          <Nav.Link href="/new" className={this.activeLink('/new')}>Upload New Listing</Nav.Link>
          <Nav.Link href="/manage-listings" className={this.activeLink('/mylistings')}>Manage Listings</Nav.Link>
          <Nav.Link href="/dashboard" className={this.activeLink('/dashboard')}>Dashboard</Nav.Link>
        </Aux>
      ) 
    }
    
  }

  adminLinks = (isMobile) => {
    if(this.state.user !== undefined && (this.state.user.isAdmin)) {
      if(!isMobile){
        return(
          <NavDropdown.Item href="/admin">Admin</NavDropdown.Item>
        )
      }
      return <Nav.Link href="/admin" className={this.activeLink('/admin')}>Admin</Nav.Link>
    }
  }

  updateModal = (modalShowProp) => {
    const newState = {};
    if(modalShowProp.includes('Toast') && this.state[modalShowProp]){
      // return;
    }
    newState[modalShowProp] = !this.state[modalShowProp];
    this.setState(newState);
  }

  updateModalState = () => {
    this.setState({
      showLogin: false,
    }, () => {    
      if(this.state.showForgotPassword){
        window.setTimeout(() => {
          this.setState({
            showForgotPassword: false
          })
        }, 1000)
      }
    })
  }

  cartIcon = () => {
    if(this.state.user !== '' && this.state.user !== undefined){
      return (
        <Link to='/cart' className={styles.cartContainer}>
          <span><Cart height='30px' width='30px' fill='#306a39' className={styles.cart}/></span>
          <span className={styles.countBadge}>{this.state.user.listLength || 0}</span>
        </Link>
      )
    }
  }

  loginLink = () => {
    if(this.state.user === '' || this.state.user === undefined){
      return(
        <Nav.Link href="#Login" className={`${styles.link} ${styles.permanent}`} onClick={() => {this.updateModal('showLogin')}}>Login / Register</Nav.Link>
      )
    }
    else{
      return(
        <Aux>
          <NavDropdown title={`${this.greeting()} ${this.state.user.firstname}`} id="collasible-nav-dropdown" className={styles.dropdown}>
            <NavDropdown.Item href="/orders">Orders</NavDropdown.Item>
            {this.growerProcessorLinks()}
            <NavDropdown.Item href="/account">My Account</NavDropdown.Item>
            {this.adminLinks()}
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={this.logout}>Logout</NavDropdown.Item>
          </NavDropdown>
        </Aux>
      )
    }
  }

  buyerOrSellerLink = () => {
    if(this.state.user === '' || this.state.user === undefined){
        return(
          <Nav.Link href="#BuyerOrSeller" className={`${styles.link} ${styles.permanent}`} onClick={() => {this.updateModal('showBuyerOrSeller')}}>How It Works</Nav.Link>
        )
    }
  }

  pricingLink = () => {
    if(this.state.user === '' || this.state.user === undefined){
        return ( 
          <Aux>
            <Nav.Link href="/register"  className={this.activeLink('/register', true)}>Pricing</Nav.Link>
            {/* <Nav.Link href='#AboutUs' className={`${styles.link} ${styles.permanent}`} onClick={() => {this.updateModal('showAboutUs')}}>About Us</Nav.Link> */}
          </Aux>
        )
    }
  }

   doSetUser = (value) => {
    this.setState({user: this.user.setUser(value)}, async () => {
      await this.getListLength();
    })}

  logOutLink = () => {
    if(this.state.user !== '' && this.state.user !== undefined){
      return(
        <Nav.Link href="#Logout" className={`${styles.link}`} onClick={this.logout}>Log out</Nav.Link>
      )
    }
  }
  userLinks = () => {
    const orderLink= () => {
      if(this.state.user !== '' && this.state.user !== undefined){
        return (
        <Aux>
          <Nav.Link href="/orders" className={this.activeLink('/orders')}>Orders</Nav.Link>
        </Aux>
        )
      }
    }
    const myAccountLink= () => {
      if(this.state.user !== '' && this.state.user !== undefined){
        return (
        <Aux>
          <Nav.Link href="/account" className={this.activeLink('/account')}>My Account</Nav.Link>
        </Aux>
        )
      }
    }
    return(
      <Aux className={styles.userLinks}>
        {orderLink()}
        {this.growerProcessorLinks(true)}
        {myAccountLink()}
        {this.adminLinks(true)}
      </Aux>
    )
  }

  greeting = () => {
    const time = new Date().getHours();
    if(time >= 0 && time <= 11){
      return "Good Morning";
    }
    if(time > 11 && time <= 16){
      return "Good Afternoon";
    }
    if(time > 16){
      return "Good Evening"
    }
  }

  welcomeMessage = () => {
    if(this.state.user && this.state.user.isDispensary){
      return(
        <p>
            Thank you for registering with Green Leaf Trader.  Your account has now been activated.
            If you have any questions or concerns, please feel free to contact us at any time.
        </p>
      )
    }
    return(
        <div>
            <p>
                Thank you for registering with Green Leaf Trader.  Your account has now been activated.
            </p><p>
                If you have any questions or concerns, please feel free to contact us at any time.
            </p>
        </div>
    )
  }

  handleBack = () => {
    this.props.history.goBack();
  }

  render(){
    return(
      <Aux>
        <Toast {...this.state.toastConfig} show={this.state.showLoginToast} onClose={() => {this.setState({showLoginToast: false})}}/>
        <Modal title='Welcome to Green Leaf Trader' show={this.state.showWelcomeMessage} onHide={() => {this.setState({showWelcomeMessage:false})}} showbutton='true'>
          {this.welcomeMessage()}
        </Modal>
        <Copyright show={this.state.copyrightModal} onHide={() => {this.updateModal('copyrightModal')}}/>
        <Navbar collapseOnSelect expand="lg" className={styles.navbar} sticky='top'>
          <Container className={styles.container}>
            <Navbar.Brand href="/" className={styles.showOnDesktop}>
              <img src={logo} alt='Cannalist Ok' className={styles.logo}/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" className='custom-toggler'/>
            <Navbar.Collapse id="responsive-navbar-nav" className={`${styles.maxHeight} ${styles.collapse}`}>
              <Nav className={`ml-auto ${styles.maxHeight}`}>
                <Nav.Link href="/" className={this.activeLink('/', true)}>Products</Nav.Link>
                {this.pricingLink()}
                {this.userLinks()}
                {this.buyerOrSellerLink()}
                <Nav.Link href='#ContactUs' className={`${styles.link} ${styles.permanent}`} onClick={() => {this.updateModal('showContactUs')}}>Contact Us</Nav.Link>
                {this.loginLink()}
                {this.logOutLink()}
              </Nav>
            </Navbar.Collapse>
            <Navbar.Brand href="/" className={styles.hideOnDesktop}>
              <img src={logo} alt='Cannalist Ok' className={styles.logo}/>
            </Navbar.Brand>
            {this.cartIcon()}
          </Container>
        </Navbar>
        <Navbar fixed='bottom' className={styles.navbarBottom}>
          <Container style={{padding: '1rem'}}>
            <p onClick={() => {this.updateModal('copyrightModal')}}>&copy;{new Date().getFullYear()}  Green Leaf Trader, LLC</p>
          </Container>
        </Navbar>
        <LoginContainer 
          showLogin={this.state.showLogin} 
          showForgotPassword={this.state.showForgotPassword}
          showForgotPasswordModal={() => this.updateModal('showForgotPassword')}
          updateModal={this.updateModalState}
          updateToast={() => {this.updateModal('showLoginToast')}}
          host={this.props.host}
          handleSetUser={this.doSetUser}
          showLoginToast={this.state.showLoginToast}
          toastConfig = {this.state.toastConfig}
          setToastConfig = {this.setToastConfig}
          getListLength = {this.getListLength}
        />
        <AboutUs 
          show={this.state.showAboutUs} 
          onHide={() => {this.updateModal('showAboutUs')}}
        />
        <ContactUs 
          show={this.state.showContactUs}
          onHide={() => {this.updateModal('showContactUs')}}
          host={this.props.host}
          updateToast={() => {this.updateModal('showContactToast')}}
          showContactToast={this.state.showContactToast}
          toastConfig = {this.state.toastConfig}
          setToastConfig = {this.setToastConfig}
        />
        <BuyerOrSeller
          show={this.state.showBuyerOrSeller}
          onHide={() => {this.updateModal('showBuyerOrSeller')}}
        />

      </Aux>
    )
  }
}

export default withRouter(Navigation);
