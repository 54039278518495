import React, { Component } from 'react';
import { GET } from '../../../helpers/requests'
import Aux from '../../../hoc/Auxiliary/Auxiliary';
import User from '../../../helpers/user';
import styles from './../Dashboards.module.css';

export default class SoldProductsTotal extends Component {
    user = new User();

    _isMounted = false;
  
    state = {
      user: this.user.getUser()
    }
    componentDidMount() {
        this._isMounted = true;
        this.getSoldProductsTotal();
      }
    componentWillUnmount() {
        this._isMounted = false;
    }
    currencyFormat = (num) =>{
        return '$' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
     }
    getSoldProductsTotal = async () => {
        const url = `/dashboards/soldproducts/total/${this.state.user.id}`
        const result = await GET(url);
        
        this.setState({
            "count": this.currencyFormat(result[0].total)
        });
    }
    render(){
        return(
            <Aux>
                <div className={styles.tile}>
                    <div >
                        <img className={styles.icon} src="https://image.flaticon.com/icons/svg/1322/1322818.svg" alt='why did we not download this?'/>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.title}>TOTAL SALES</div>
                        <div className={styles.body}>
                            {this.state.count}
                        </div>
                        <div className={styles.footer}>
                        
                        </div>
                    </div>
                </div>
            </Aux>
        )
    }
}