import React, { Component } from 'react';
import { Container, Tabs, Tab, Button, Form, Spinner } from 'react-bootstrap';
import { withRouter } from 'react-router';
import DatePicker from 'react-datepicker';

import Aux from '../../hoc/Auxiliary/Auxiliary';
import Toast from '../../components/Toast/Toast';
import Quantity from '../../containers/Product/Quantity/Quantity';
import Modal from '../../components/Modal/Modal';
import User from '../../helpers/user';
import Purchased from './Purchased/Purchased';
import Sold from './Sold/Sold'
import Open from './Open/Open'
import { GET, PUT, POST, DELETE } from '../../helpers/requests';

import styles from './OrderHistory.module.css';
import 'react-datepicker/dist/react-datepicker.css';
import productList from "../Products/ProductList/ProductList";

const CustomInput = React.forwardRef((props,ref) => {
  return (
      <input
          onClick={props.onClick}
          value={props.value}
          type="text"
          readOnly={true}
          ref={ref}
      />
  )
})

class OrderHistory extends Component {
  user = new User();

  state = {
    user: this.user.getUser(),
    sortedLists: [],
    openLists: [],
    closedLists: [],
    showModal: false,
    modalContent: {},
    reason: '',
    startDate: undefined,
    showToast: false,
    toastConfig: {},
    loading: true
  }

  
  UNSAFE_componentWillReceiveProps = (newProps) => {
    if(!this.state.user && newProps.location.hash === '#LoggedIn'){
      this.setState({user: this.user.getUser()}, () => {
        this.props.history.push(`/orders${this.state.activeLink }`);
        window.location.reload()
      })
    }
  }

  getDefaultLink = () => {
    const hash = this.props.location.hash
    if(hash){
      return hash.substring(1);
    }
    return this.state.user && this.state.user.isDispensary ? 'purchase' : 'open'
  }

  setModalConfig = (product, action) => {
    if(this.state.activeLink === 'open'){
      if(action === 'edit'){
        this.generateEditModal(product);
      }
      else if(action === 'delete'){
        this.generateRejectModal(product);
      }
      else if(action === 'accept'){
        this.generateAcceptModal(product);
      }
      else if(action === 'deliver'){
        this.generateDelivereddModal(product)
      }
    }
    else if(this.state.activeLink === 'purchase'){
      if(action === 'delete'){
        this.generateDeleteModal(product)
      }
      else if(action === 'approve'){
        this.generateApproveModal(product);
      }
      else if(action === 'reason'){
        this.generateCancelledModal(product);
      }
    }
  }

  generateEditModal = (product) => {
    const quantity = this.state.quantity !== undefined ? this.state.quantity : parseInt(product.Quantity);
      this.setState({quantity}, () => {
        this.setState({
          modalContent: {
            title: 'Edit Quantity',
            body: (
              <div>
                <div style={{marginBottom: '1rem'}}>
                  <Quantity 
                    quantity={this.state.quantity} 
                    max={product.amountAvailable} 
                    decrementQuantity={() => {this.handleQuantityChange(product, true)}}
                    incrementQuantity={() => {this.handleQuantityChange(product)}}
                    onChange={(value) => {this.handleQuantityChange(product, undefined, value)}}
                    hideIncrement
                  />
                </div>
                <Form>
                  <Form.Group>
                    <Form.Control 
                      as='textarea' 
                      rows='3' 
                      placeholder='Enter a reason for editing the quantity. Optional.' 
                      onChange={event => {this.handleTextChange(event, product, 'edit')}}
                    />
                  </Form.Group>
                </Form>
              </div>
            ),
            actions: (
              <Aux>
                <Button variant='success' onClick={() => {this.onSubmitEdit(product)}} disabled={this.state.quantity === parseInt(product.Quantity, 10)}>Submit</Button>
                <Button variant='danger' onClick={this.handleReset}>Cancel</Button>
              </Aux>
            )
          },
          showModal: true
        })
      })
  }

  generateRejectModal = (product) => {
    this.setState({
      modalContent: {
        title: 'Reject Item From Order',
        body: (
          <Form>
            <Form.Group>
              <Form.Control 
                as='textarea' 
                rows='3'
                minLength='5'
                placeholder='Enter a reason for rejecting the product here.' 
                onChange={event => {this.handleTextChange(event, product, 'reject')}}
              />
            </Form.Group>
          </Form>
        ),
        actions: (
          <Aux>
            <Button
                variant='danger'
                onClick={() => {this.onSubmitReject(product)}}
                disabled={this.state.reason.length<4}
            >Submit</Button>
            <Button variant='success' onClick={this.handleReset}>Cancel</Button>
          </Aux>
        )
      },
      showModal: true
    })
  }

  generateAcceptModal = (product) => {
    this.setState(prevState => ({
      startDate: prevState.startDate || new Date()
    }), () => {
      this.setState({
        modalContent: {
          title: 'Accept Item',
          body: (
            <div className={styles.modalBodyCenter}>
              {this.acceptModalCaption(product.delivery)}
              <DatePicker 
                selected={this.state.startDate} 
                onChange={date => {this.handleDateChange(date, product)}}
                customInput={<CustomInput/>}
              />
            </div>
          ),
          actions: (
            <Aux>
              <Button variant='success' onClick={() => {this.onSubmitAccept(product)}}>Submit</Button>
              <Button variant='danger' onClick={this.handleReset}>Cancel</Button>
            </Aux>
          )
        },
        showModal: true,
      })
    })
  }

  acceptModalCaption = (isDelievered) => {
    return isDelievered ? <h5>When will the product be delivered?</h5> : <h5>When will the product be available for pick up?</h5>
  }

  generateDeleteModal = (product) => {
    this.setState({
      modalContent: {
        title: 'Remove Product',
        body: (
          <p>{`Are you sure you would like to remove ${product.brand.toUpperCase()} ${product.strain.toUpperCase()} from your order?`}</p>
        ),
        actions: (
          <Aux>
            <Button variant='danger' onClick={() => {this.onSubmitDelete(product)}}>Remove Product</Button>
            <Button variant='success' onClick={this.handleReset}>Cancel</Button>
          </Aux>
        )
      },
      showModal: true
    })
  }

  generateApproveModal = (product) => {
    const showReason = product.Reason ? <div><span>Reason: </span><span>{product.Reason}</span></div> : null;
    this.setState({
      modalContent: {
        title: 'Approve Quantity Change',
        body: (
          <div className={styles.modalBodyCenter}>
            <h5>{`${product.sellerBusinessName} would like to change the requested quantity to ${product.Quantity}.`}</h5>
            {showReason}
          </div>
        ),
        actions: (
          <Aux>
            <Button variant='success' onClick={() => {this.onSubmitApproval(product)}}>Approve</Button>
            <Button variant='danger' onClick={() => {this.onSubmitApproval(product, true)}}>Reject</Button>
            <Button onClick={this.handleReset}>Cancel</Button>
          </Aux>
        )
      },
      showModal: true
    })
  }

  generateCancelledModal = (product) => {
    this.setState({
      modalContent: {
        title: 'Cancelation Reason',
        body: (
          <div className={styles.modalBodyCenter}>
            <h5>{product.Reason}</h5>
          </div>
        ),
        actions: (
          <Aux>
            <Button onClick={this.handleReset}>OK</Button>
          </Aux>
        )
      },
      showModal: true
    })
  }

  generateDelivereddModal = (product) => {
    const deliveredLabel = product.delivery ? 'Delivered' : 'Picked Up';
    this.setState({
      modalContent: {
        title: `Set as ${deliveredLabel}`,
        body: (
          <div className={styles.modalBodyCenter}>
            <h5>{`Would you like to mark the product as ${deliveredLabel}?`}</h5>
          </div>
        ),
        actions: (
          <Aux>
            <Button variant='success' onClick={() => {this.onSubmitDelivered(product)}}>OK</Button>
            <Button variant='danger' onClick={this.handleReset}>Cancel</Button>
          </Aux>
        )
      },
      showModal: true
    })
  }

  handleQuantityChange = (product, decrement, quantity) => {
    let currentQuantity = this.state.quantity;
    if(!decrement && quantity === undefined){
      currentQuantity += 1;
    }
    else if(decrement && quantity === undefined){
      currentQuantity -= 1;
    }
    else{
      currentQuantity = quantity || 0; 
    }
    this.setState({quantity: currentQuantity}, () => {
      this.setModalConfig(product, 'edit');
    })
  }

  handleTextChange = (event, product, type) => {
    this.setState({reason: event.target.value});
    if(type === 'reject'){
      this.generateRejectModal(product);
    }
    else if(type === 'edit'){
      this.generateEditModal(product);
    }
  }

  handleDateChange = (date, product) => {
    this.setState({
      startDate: date
    }, () => {
      this.generateAcceptModal(product);
    })
  }

  onSubmitEdit = async (product) => {
    const url = `/sellerlists/${product.listId}/${product.productId}`;
    const config = {
      successMessage: 'Quantity updated. Product is now pending buyer approval.',
      body: {
        quantity: this.state.quantity,
        price: product.price,
        total: this.state.quantity*product.price,
        reason: this.state.reason.trim()
      },
      component: this
    }
    if(this.state.quantity === 0){
      this.generateRejectModal(product);
    }
    else{
      const response = await PUT(url, config);
      this.setState({
        ...response.toast
      }, () => {
        this.handleReset();
        this.getListData();
      })
    }
  }

  onSubmitAccept = async (product) => {

    const date = this.formatDate(this.state.startDate)
    const url = `/sellerlists/${product.listId}/${product.productId}/Accepted`;
    const config = {
      body: {
        deliveryDate: date
      },
      successMessage: 'Item has been Accepted',
      component: this
    }
    const response = await POST(url, config);
    this.setState({
      ...response.toast
    }, () => {
      this.handleReset();
      this.getListData();
    })
  }

  formatDate = date => {
    let month = date.getMonth()+1;
    const day = date.getDate();
    const year = date.getYear()+1900;
    if(month.length === 1){
      month = '0'+month
    }
    return `${year}-${month}-${day}`
  }

  onSubmitReject = async (product) => {
    const url = `/sellerlists/${product.listId}/${product.productId}/Cancelled`;
    const config = {
      body: {
        reason: this.state.reason.trim()
      },
      successMessage: 'Product has been rejected',
      component: this
    }
    const response = await POST(url, config);
    this.setState({
      ...response.toast
    }, () => {
      this.handleReset();
      this.getListData();
    })
  }

  onSubmitDelete = async (product) => {
    const url = `/lists/${product.listId}/${product.productId}/Cancelled`
    const config = {
      successMessage: 'Product has been removed from the order.',
      component: this
    }
    const response = await POST(url, config);
    this.setState({
      ...response.toast
    }, () => {
      this.handleReset();
      this.getListData();
    })
  }

  onSubmitApproval = async (product, rejected) => {
    const url = `/lists/${product.listId}/${product.productId}`
    const statusUrl = rejected ? `${url}/Cancelled` : `${url}/Submitted`;
    const config = {
      successMessage: rejected ? 'Product Edit Rejected' : 'Product Edit Accpeted',
      component: this
    }
    if(rejected){
      config.body = {
        reason: 'Edit rejected by buyer.'
      }
    }
    const response = await POST(statusUrl, config);
    this.setState({
      ...response.toast
    }, () => {
      this.handleReset();
      this.getListData();
    })
  }

  onSubmitDelivered = async (product) => {
    const url = `/sellerlists/${product.listId}/${product.productId}/Closed`
    const config = {
      successMessage: product.delivery ? 'Product Delivered' : 'Product Picked Up',
      component: this
    }
    const response = await POST(url, config);
    this.setState({
      ...response.toast
    }, () => {
      this.handleReset();
      this.getListData();
    })
  }

  handleReset = () => {
    this.setState({
      quantity: undefined,
      startDate: undefined,
      showModal: false
    })
  }

  setDefaultLink = (link) => {
    this.setState({
      activeLink: link
    }, () => {
      this.getListData();
    })
  }

  componentDidMount = () => {
    if(this.state.user === undefined){
      this.setState({activeLink: this.props.location.hash}, () => {
        this.props.history.push('#Login');
      });
    }
    else{
      this.setState({
        activeLink: this.getDefaultLink()
      }, () => {
        this.getListData();
      })
    }
  }

  getListData = async () => {
    this.setState({
      loading: true
    }, async () => {
      const url = this.state.activeLink === 'purchase' ? `/lists/user/${this.state.user.id}` : `/sellerlists/${this.state.user.id}`
      const response = await GET(url, {component: this});
      if(!Object.keys(response).includes('toast')){
        const newState = {};
        if(this.state.activeLink === 'purchase'){
          const submittedLists = response.filter(list => {
            return list.List.status !== 'Open';
          })
          newState.sortedLists = this.sortProductsToList(submittedLists);
        }
        else if(this.state.activeLink === 'open') {
          newState.openLists = this.sortProductsToList(response.filter(list => {
            return list.Seller_List.status !== 'Closed' && list.Seller_List.status !== 'Cancelled'
          }))
        }
        else if(this.state.activeLink === 'sold') {
          newState.closedLists = this.sortProductsToList(response.filter(list => {
            return list.Seller_List.status !== 'Submitted' && list.Seller_List.status !== 'Accepted'
          }))
        }
        newState.loading = false;
        this.setState(newState)
      }
    });
  }

  sortProductsToList = lists => {
    const listType = this.state.activeLink === 'purchase' ? 'List' : 'Seller_List'
    const listIds = [...new Set(lists.map(list => {
      return list[listType].id;
    }))]
    let processedLists = listIds.map(id => {
      let processedList = {};
      const groupedLists = lists.filter(list => {
        return list[listType].id === id
      })
      const listProducts = groupedLists.map(list => {
        if(Object.keys(processedList).length === 0) {
          processedList = {...processedList, ...list[listType]};
        }
        return({
          ...list.List_Products,
          category: list.Products.Category,
          price: list.Products.Price,
          thc: parseFloat(list.Products.THC),
          cbd: parseFloat(list.Products.CBD),
          trp: parseFloat(list.Products.TRP),
          delivery: true,
          price_uom: list.Products.Price_UOM,
          brand: list.Products.Brand,
          strain: list.Products.Strain,
          ownerId: list.Products.Owner,
          productImage: list.Products.Product_Image,
          productId: list.Products.id,
          amountAvailable: list.Products.Amount_Available,
          listId: list[listType].id,
          orderNumber: list.List.id,
          email: list.Seller ? list.Seller.email : list.users.email,
          sellerBusinessName: list.Seller ? list.Seller.business_name : list.users.business_name,
          buyerBusinessName: list.Buyer ? list.Buyer.business_name : '',
          buyerEmail: list.Buyer ? list.Buyer.email: '',
          buyerLicense: list.Buyer ? list.Buyer.license_number: '',
          buyerOBNDD: list.Buyer ? list.Buyer.OBNDD: '',
          address: list.Buyer ? list.Buyer.address : '',
          city: list.Buyer ? list.Buyer.city : '',
          state: list.Buyer ? list.Buyer.state : '',
          zip: list.Buyer ? list.Buyer.zip : '',
          phone: list.Buyer ? list.Buyer.phone : ''
        })
      })
      processedList.listProducts = this.sortProductsByOwner(listProducts);
      return processedList;
    })
    if(this.state.activeLink === 'purchase'){
      processedLists = [...processedLists].sort((a,b) => {
        const statusA = a.status.toUpperCase();
        const statusB = b.status.toUpperCase();
        if(statusA > statusB) {
          return -1;
        }
        if(statusA < statusB){
          return 1;
        }
        return 0;
      })
    }
    return processedLists;
  }

  sortProductsByOwner = listProducts => {
    const ownerIds = [...new Set(listProducts.map(listProduct => {
      return listProduct.ownerId
    }))]
    return ownerIds.map(id => {
      return listProducts.filter(listProduct => {
        return listProduct.ownerId === id;
      })
    })
  }

  showLoading = () => {
    if(this.state.loading){
      return(
        <div className={styles.modalBodyCenter}>
          <h4>Loading Orders</h4>
          <Spinner animation='border'/>
        </div>
      )
    }
    else{
      return this.navTabs();
    }
  }

  navTabs = () => {
    if(this.state.user && (this.state.user.isGrower || this.state.user.isProcessor)){
      return (
        <Tabs activeKey={this.state.activeLink} onSelect={key => {this.setDefaultLink(key)}}>
          <Tab eventKey='open' title='Open Orders'><Open lists={this.state.openLists} onShowModal={this.setModalConfig} onQuantityChange={this.handleQuantityChange}/></Tab>
          <Tab eventKey='purchase' title='Purchase History'><Purchased lists={this.state.sortedLists} onShowModal={this.setModalConfig}/></Tab>
          <Tab eventKey='sold' title='Sold History'><Sold lists={this.state.closedLists} onShowModal={this.setModalConfig}/></Tab>
        </Tabs>
      )
    }
    return (
      <Purchased lists={this.state.sortedLists} onShowModal={this.setModalConfig}/>
    )
  }

  render() {
    return (
      <Aux>
        <Toast show={this.state.showToast} {...this.state.toastConfig} onClose={() => {this.setState({showToast: false})}}/>
        <Modal 
          show={this.state.showModal} 
          title={this.state.modalContent.title} 
          actions={this.state.modalContent.actions}
          onHide={this.handleReset}
        >{this.state.modalContent.body}</Modal>
        <Container className={styles.container}>
          {this.showLoading()}
        </Container>
      </Aux>
    )
  }
}

export default withRouter(OrderHistory);