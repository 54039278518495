import React from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';

import styles from './PasswordForm.module.css';

const passwordForm = (props) => {
  return(
    <div className={styles.container}>
      <h4>Update Password</h4>
      <Form onSubmit={props.doPasswordUpdate}>
        <Row>
          <Col md={4}>
            <Form.Group>
              <Form.Label>Current Password</Form.Label>
              <Form.Control 
                type="password" 
                id='oldPassword' 
                value={props.passwordForm.oldPassword}
                onChange={props.doInputChange}
                data-form='passwordForm'
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label>New Password</Form.Label>
              <Form.Control 
                type="password" 
                id='newPassword' 
                value={props.passwordForm.newPassword}
                onChange={props.doInputChange}
                data-form='passwordForm'
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                title="Password must have 8 characters, an uppercase character, a lowercase character, and a number."
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label>Re-enter New Password</Form.Label>
              <Form.Control 
                type="password"
                id='newPassword2' 
                value={props.passwordForm.newPassword2}
                onChange={props.doInputChange}
                data-form='passwordForm'
                pattern={RegExp.escape(props.passwordForm.newPassword)}
                title="Your passwords do not match"
              />
            </Form.Group>
          </Col>
        </Row>
        <div className={styles.buttonContainer}>
          <Button 
            variant='success' 
            disabled={props.disablePasswordSubmit()} 
            type='submit'
          >Submit</Button>
        </div>
      </Form>
    </div>
  )
}

export default passwordForm;