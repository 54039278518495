import React from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import Cookies from 'js-cookie';
import { withRouter } from 'react-router'

import Aux from '../../../../hoc/Auxiliary/Auxiliary';
import { POST } from '../../../../helpers/requests';

import styles from './Login.module.css';

const login = (props) => {

  const redirectToRegister = () => {
    props.history.push('/register')
    props.updateModal();
  }
  const redirectToForgotPassword = () => {
    props.showForgotPasswordModal();
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const url = 'users/login';

    const config = {
      body: {
        email: event.target.username.value,
        password: event.target.password.value,
        component: this
      }
    };

    const response = await POST(url, config);
    if(response.error && response.error.response && response.error.response.status === 400){
      props.setToastConfig({
        ...response.toast.toastConfig,
        message: 'Invalid username or password'
      });
      props.updateToast();
    }
    else if(response.status === 200){
      props.setToastConfig({
        ...response.toast.toastConfig,
        message: `Welcome back ${response.data.userDetails.firstname}`
      });
      props.updateToast();
      props.handleSetUser(response.data.userDetails);
      Cookies.set('token', response.data.token);
      props.updateModal();
      window.location.reload();
    }
    else{
      props.setToastConfig({
        variant: 'danger',
        title: 'Server Error',
        message: 'Something went wrong. Please try again later.',
        delay: 10000,
        autohide: true
      })
      props.updateToast();
  }
}

  return(
    <Aux>
      <Container>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId='username'>
            <Form.Label>Email</Form.Label>
            <Form.Control type='email' placeholder='Enter email'/>
          </Form.Group>
          <Form.Group controlId='password'>
            <Form.Label>Password</Form.Label>
            <Form.Control type='password' placeholder='Enter password'/>
          </Form.Group>
          <div className={styles.forgotPassword}>
            <a href='#forgotpassword' onClick={redirectToForgotPassword}>Forgot password?</a>
          </div>
          <div className={styles.loginButton}>
            <Button type='submit'>Login</Button>
          </div>
        </Form>
      <div className={styles.register}>
        <p>Don't have an account yet?</p>
        <Button onClick={redirectToRegister}>Register</Button>
      </div>
      </Container>
    </Aux>
  )
}

export default withRouter(login);