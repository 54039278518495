import React, { Component } from 'react';
import { Container, Col, Row, Button, Badge, Spinner } from 'react-bootstrap';
import { withRouter } from 'react-router';

import Toast from '../../components/Toast/Toast';
import Description from './Description/Description';
import Quantity from './Quantity/Quantity'
import Aux from '../../hoc/Auxiliary/Auxiliary';
import Modal from '../../components/Modal/Modal';
import Users from '../../helpers/user';
import { GET, POST } from '../../helpers/requests';

import styles from './Product.module.css'

class Product extends Component {
  user = new Users();
  _isMounted = false;

  state = {
    showToast: false,
    showDescription: false,
    showDeliveryDetails: false,
    showTestResults: false,
    toastConfig: {},
    showModal: false,
    quantity: 0,
    user: this.user.getUser(),
    loading: true,
    label: 'Added'
  }

  UNSAFE_componentWillReceiveProps = (newProps) => {
    if(!this.state.user && newProps.location.hash === '#LoggedIn'){
      this.setState({user: this.user.getUser()})
    }
  }

  updateModal = (modalAttr) => {
    const modalState = {};
    modalState[modalAttr] = !this.state[modalAttr];
    this.setState({...modalState});
  }

  updateQuatity = (increase) => {
    let value = this.state.quantity;
    if(increase){
      value += 1;
    }
    else{
      value -= 1;
    }
    this.setState({
      quantity: value
    })
  }

  updateList = async () => {
    const body = {
      listid: this.state.user.listid || '',
      productid: this.props.location.pathname.substring(1),
      quantity: this.state.quantity,
      price: this.state.Products.Price,
      total: parseInt(this.state.quantity, 10) * parseFloat(this.state.Products.Price),
      userid: this.state.user.id
    };
    const successMessage = 'Product added to cart successfully.'
    const response = await POST('/lists/addProduct', {
      body,
      successMessage,
      component: this
    });
    if(Object.keys(response).includes('error')){
      this.setState({...response.toast});
    }
    else if(response.data.listid){
      this.user.setUser( {
        ...this.state.user,
        listid: response.data.listid
      });
      this.props.history.push('#Add');
      this.setState({showModal: true})
    }
    else{
      this.props.history.push('#Add');
      this.setState({showModal: true})
    }
  }

  getCurrentQuantity = async () => {
    let currentQuantity = 0;
    let label = 'Added';
    if(this.user.listid !== '' && this.user.listid !== undefined && this.state.Products.id !== undefined){
      const url = `/lists/${this.user.listid}/${this.state.Products.id}/Quantity`;
      const resposne = await GET(url);
      if(!Object.keys(resposne).includes('error')){
        currentQuantity = resposne.msg.Quantity;
        label = 'Edited';
      }
    }
    this.setState({
      quantity: currentQuantity,
      label
    });
  }

  componentDidMount = async () => {
    this._isMounted = true;
    const url = `${this.props.host}/products${this.props.location.pathname}`;
    const product = await GET(url);
    if(Object.keys(product).includes('toastConfig')){
      this.setState({...product});
    }
    else{
      this.setState({
        ...product[0],
        loading: false
      }, () => {
        this.getCurrentQuantity();
      })
    }
  }

  getTHCField = () => {
    if(this.state.Products.Category !== 'Accessories / Other'){
      if(this.state.Products.Category !== 'Edibles'){
        return <p className={styles.productText}>{`THC: ${parseFloat(this.getProductInfo('Products', 'THC'))}%`}</p>
      }
      return <p className={styles.productText}>{`THC: ${this.getProductInfo('Products', 'THC')}mg`}</p>
    }
  }

  getCBDField = () => {
    if(this.state.Products.Category !== 'Accessories / Other'){
      return <p className={styles.productText}>{`CBD: ${parseFloat(this.getProductInfo('Products', 'CBD'))}%`}</p>
      
    }
  }

  getTRPField = () => {
    if(this.state.Products.Category !== 'Accessories / Other'){
      return <p className={styles.productText}>{`TRP: ${parseFloat(this.getProductInfo('Products', 'TRP'))}%`}</p>
    }
  }
  componentWillUnmount = () => {
    this._isMounted = false;
  }

  getProductInfo = ((object, attr) => {
    if(this.state[object]){
      if(attr === 'DeliveryDetails'){
        console.log(this.state[object][attr]);
      }
      return this.state[object][attr];
    }
    return '';
  })
  listButton = () => {
    if(!this.state.loading){
      if(this.state.user){
        return(
          <Button block className={styles.button} variant='success' onClick={this.updateList} disabled={this.state.quantity === 0}>Add to Cart</Button>
        )
      }else{
        return(
          <Button block className={styles.button} variant='success' href='#Login'>Login / Register</Button>
        )
      }
    }
  }

  hasTestResults = () => {
    return this.state.Products.Tested ? 'Test Results on Request' : 'Test Results not Available';
  }

  contactSeller = () =>{
    if(!this.state.loading){
      if(this.state.user){
        return(
          <Button block variant='success' className={styles.button} href={`mailto:${this.getProductInfo('users', 'email')}`} target='_top'>Contact Seller</Button>
        );
      }
    }
  }
  price = () =>{
    if(this.state.user){
      return(
        <p className={styles.price}>{`$${this.getProductInfo('Products', 'Price')} - ${this.getProductInfo('Products', 'Price_UOM')}`}</p>
      );
    }else{
      return(
        <p className={styles.price}>{`$$$ - ${this.getProductInfo('Products', 'Price_UOM')}`}</p>
      );
    }
  }
  pageDetails = () => {
    if(this.state.loading){
      return(
        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: '3rem', marginBottom:'5rem'}}>
          <Spinner animation="border" />
        </div>
      )
    }else{
      return(
        <Aux>
          <Modal show={this.state.showModal} title={`Product ${this.state.label}!`}>
            <Row>
              <Col sm={6}>
                <Button block onClick={() => {this.props.history.push('/cart')}}>Go to Cart</Button>
              </Col>
              <Col sm={6}>
                <Button block variant='success' onClick={() => {this.props.history.push('/')}}>Keep Shopping</Button>
              </Col>
            </Row>
          </Modal>
          <Container className={styles.container}>
            <div className={styles.centeredColumns}>
              <div className={styles.brand}>
                <h4>{this.getProductInfo('Products', 'Brand').toUpperCase()}</h4>
              </div>
            </div>
            <div className={styles.centeredColumns}>
              <div className={styles.strain}>
                <h3>{this.getProductInfo('Products', 'Strain').toUpperCase()}</h3>
              </div>
            </div>
              <Row>
                <Col md={6}>
                  <div className={styles.imageContainer}>
                    <img src={this.getProductInfo('Products', 'Product_Image')} alt='Error loading product' className={styles.image}/>
                  </div>
                  {this.price()}
                  <div className={styles.buttonContainer}>
                    <Button block variant='success' className={styles.button} onClick={() => {this.updateModal('showDescription')}}>Description</Button>
                    {this.contactSeller()}
                  </div>
                </Col>
                <Col md={6} className={styles.secondDiv}>
                  
                  <div className={styles.badgeContainer}>
                    <h3>
                      <Badge pill className={styles[this.getProductInfo('Products', 'Subcategory')]}>{this.getProductInfo('Products', 'Subcategory')}</Badge>
                    </h3>
                    <h3>
                      <Badge pill variant='light' className={styles.otherBadge}>{this.getProductInfo('Products', 'Category')}</Badge>
                    </h3>
                    <h3>
                      <Badge pill variant='light' className={styles.otherBadge}>{this.getProductInfo('Products', 'Tag')}</Badge>
                    </h3>
                    <h3>
                      <Badge pill variant='light' className={styles.otherBadge}>{this.getProductInfo('State_Areas', 'Name')}</Badge>
                    </h3>
                  </div>
                  <div className={styles.centeredColumns}>
                    {this.getTHCField()}
                    {this.getTRPField()}
                    {this.getCBDField()}
                  </div>
                  <div className={styles.centeredColumns}>
                    <p className={`${styles.productText} ${styles.bold}`}>{`Availibility: ${this.getProductInfo('Products', 'Amount_Available')} ${this.getProductInfo('Products', 'Price_UOM')}s`}</p>
                  </div>
                  <div className={styles.centeredColumns}>
                    <p className={`${styles.productText} ${styles.bold}`}>{this.hasTestResults()}</p>
                  </div>
                  <div className={styles.centeredColumns}>
                    <p className={styles.productText}>Choose your quantity:</p>
                  </div>
                  <div>
                    <Quantity 
                      quantity={this.state.quantity}
                      incrementQuantity={() => this.updateQuatity(true)}
                      decrementQuantity={() => {this.updateQuatity(false)}}
                      max={this.getProductInfo('Products', 'Amount_Available')}
                      onChange={(value) => {this.setState({quantity:value})}}
                    />
                  </div>
                  <div className={`${styles.buttonContainer} ${styles.topMargin}`}>
                    {this.listButton()}
                    <Button block variant='success' className={styles.button} onClick={() => {this.updateModal('showDeliveryDetails')}}>Delivery Details</Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </Aux>
      )
    }
  }
  render(){
    return(
      <Aux>
        <Toast {...this.state.toastConfig} show={this.state.showToast} onClose={() => {this.updateModal('showToast')}}/>
        <Description show={this.state.showDescription} onHide={() => this.updateModal('showDescription')} description={this.getProductInfo('Products', 'Description')} title='Description'/>
        <Description show={this.state.showDeliveryDetails} onHide={() => this.updateModal('showDeliveryDetails')} description={this.getProductInfo('Products', 'DeliveryDetails')} title='Delivery Details'/>
        {this.pageDetails()}
      </Aux>
    )
  }
}

export default withRouter(Product);