import axios from 'axios';
import Cookie from 'js-cookie';

const token = Cookie.get('token');

const buildToast = (config) => {
  return({
    toastConfig: {
      variant: config.variant || 'danger',
      title: config.title || 'Server Error',
      message: config.message || 'Something went wrong. Try again later.',
      delay: config.delay || 10000,
      autohide: true
    },
    showToast: true
  })
}

const handleError = async (error, config) => {
  if(error.response && error.response.status && error.response.status === 403 && config && config.component){
    config.errorMessage = 'Session Expired. Please login again.'
    handleUnauthorized(config);
  }
  const toastConfig = {};
  toastConfig.message = config && config.errorMessage ? 
    config.errorMessage : undefined;
  console.error(error);
  
  return ({
    toast: buildToast(toastConfig),
    error
  })
}

const instance = axios.create({
  baseURL: process.env.REACT_APP_HOST,
  timeout: process.env.REACT_APP_TIMEOUT || 5000,
  headers: {
    'Content-type': 'application/json',
    'Authorization': token ? `bearer ${token}` : ''
  },
  method: 'GET'
})

const GET = async (url, config) => {
  const successStatus = config && config.successStatus ? config.successStatus : 200;

  try{
    const response = await instance(url);
    if(response.status !== successStatus){
      throw new Error(response);
    }
    else{
      return(response.data);
    }
  }
  catch(e){
    return handleError(e, config)
  }
} 

const POST = async (url, config, method='POST') => {
  const instanceConfig = {
    method,
    data: config && config.body ? config.body : {}
  }
  const successStatus = config && config.successStatus ? config.successStatus : 200;
  try{
    const response = await instance(url, instanceConfig);
    if(response.status !== successStatus){
      throw new Error(response);
    }
    else{
      const toast =  buildToast({
        variant: 'success',
        title: 'Success',
        message: config.successMessage || 'Operation successful',
        delay: 5000,
        autohide: true
      })
      return ({
        data: response.data,
        status: response.status,
        toast
      })
    }
  }
  catch(e){
    return handleError(e, config)
  }
} 

const PUT =  async (url, config) => {
  return POST(url, config, 'PUT');
};

const DELETE =  async (url, config) => {
  return POST(url, config, 'DELETE');
};

const handleUnauthorized = (config) => {
  config.component.props.history.push('/#Login');
}

export {
  GET,
  POST,
  PUT,
  DELETE
}