import React from 'react';

import Aux from '../Auxiliary/Auxiliary'
import Navigation from '../../containers/Navigation/Navigation';

const layout = (props) => {
  return(
    <Aux>
      <Navigation {...props}/>
      {props.children}
    </Aux>
  )
}

export default layout;