import React from 'react';
import CardDeck from 'react-bootstrap/CardDeck'

import ProductListItem from './ProductListItem/ProductListItem';

import styles from './ProductList.module.css'

const productList = (props) => {
    let items;
    if(props.products && Array.isArray(props.products)){
        items = props.products.map(product => {
            return (
                <ProductListItem key={product.id} {...product} ismanagelisting={props.ismanagelisting} user={props.user}/>
            )
        });
    }
    return(
        <CardDeck className={styles.cardDeck}>
            {items}
        </CardDeck>
    )
}

export default productList;