import React from 'react';
import {Card, Button, Col, Row} from 'react-bootstrap'
import {withRouter} from 'react-router';
import * as moment from 'moment';

import Aux from '../../../hoc/Auxiliary/Auxiliary'

import styles from './OrderItem.module.css';

const orderItem = (props) => {
  const getDate = () => {
    const utcOffset = moment().utcOffset();
    const offsetDate = moment(new Date(props.list.createdAt)).utc().utcOffset(utcOffset).format('MM/DD/YYYY hh:mm:ss A');
    return offsetDate;
  }

  const convertDate = (date) => {
    const baseDate = moment(new Date(date)).add(10, 'minutes').toISOString();
    return moment(baseDate).add(1, 'days').format('MM/DD/YYYY');
  }
  const showDeliveryDate = (product) => {
    if (product.status === 'Accepted') {
      const label = 'Delivery Date: ';
      return (
        <Col className={styles.description} lg={12}>
          <h6 style={{textAlign: 'center', paddingTop: '0.5rem'}}>{`${label}${convertDate(product.DeliveryDate)}`}</h6>
        </Col>
      )
    }
  }

  const controls = product => {
    const deliveredButton = product.delivery ? 'Delivered' : 'Picked Up';
    switch (props.view) {
      case('open'):
        if (product.status === 'Submitted') {
          return (
            <Aux>
              <Row className={styles.buttonContainer}>
                <Col lg={6} md={4} className={styles.row}>
                  <Button className={styles.button} onClick={() => {
                    props.onShowModal(product, 'edit')
                  }}>Edit</Button>
                </Col>
                <Col lg={6} md={4} className={styles.row}>
                  <Button className={styles.button} variant='danger' onClick={() => {
                    props.onShowModal(product, 'delete')
                  }}>Reject</Button>
                </Col>
                <Col className={styles.row} md={4} lg={12}>
                  <Button className={styles.button} variant='success' onClick={() => {
                    props.onShowModal(product, 'accept')
                  }}>Accept</Button>
                </Col>
              </Row>
            </Aux>
          )
        } else if (product.status === 'Accepted') {
          return (
            <Aux>
              <Row>
                {showDeliveryDate(product)}
                <Col>
                  <Button className={styles.button} onClick={() => {
                    props.onShowModal(product, 'deliver')
                  }}>
                    {deliveredButton}
                  </Button>
                </Col>
              </Row>
            </Aux>
          )
        }
        break
      case('purchased'):
        if (product.status === 'Submitted' || product.status === 'Accepted') {
          return (
            <Aux>
              <Row className={styles.buttonContainer}>
                {showDeliveryDate(product)}
                <Col sm={6} className={styles.row}>
                  <Button className={styles.button} variant='danger' onClick={() => {
                    props.onShowModal(product, 'delete')
                  }}>Cancel Item</Button>
                </Col>
                <Col sm={6} className={styles.row}>
                  <Button className={styles.button} variant='dark'
                          href={`mailto:${product.email}?subject=Green Leaf Trader Product Question - ${product.brand} ${product.strain}`}>Contact</Button>
                </Col>
              </Row>
            </Aux>
          )
        } else if (product.status === 'Pending') {
          return (
            <Aux>
              <Row className={styles.buttonContainer}>
                <Col lg={6} md={4} className={styles.row}>
                  <Button className={styles.button} variant='danger' onClick={() => {
                    props.onShowModal(product, 'delete')
                  }}>Cancel Item</Button>
                </Col>
                <Col lg={6} md={4} className={styles.row}>
                  <Button className={styles.button} variant='dark'
                          href={`mailto:${product.email}?subject=Green Leaf Trader Product Question - ${product.brand} ${product.strain}`}>Contact</Button>
                </Col>
                <Col lg={12} md={4} className={styles.row}>
                  <Button className={styles.button} variant='warning' onClick={() => {
                    props.onShowModal(product, 'approve')
                  }}> Review Qty Adjustment</Button>
                </Col>
              </Row>
            </Aux>
          )
        } else if (product.status === 'Cancelled') {
          const reasonClasses = product => {
            return product.Reason === "" ? styles.hideReason : styles.row;
          }
          return (
            <Aux>
              <Row className={styles.buttonContainer}>
                <Col lg={6} md={4} className={styles.row}>
                  <Button className={styles.button} variant='success' onClick={() => {
                    props.history.push(`/${product.productId}`)
                  }}>Reorder</Button>
                </Col>
                <Col lg={6} md={4} className={styles.row}>
                  <Button className={styles.button} variant='dark'
                          href={`mailto:${product.email}?subject=Green Leaf Trader Product Question - ${product.brand} ${product.strain}`}>Contact</Button>
                </Col>
                <Col lg={12} md={4} className={reasonClasses(product)}>
                  <Button className={styles.button} onClick={() => {
                    props.onShowModal(product, 'reason')
                  }}>See Cancelled Reason</Button>
                </Col>
              </Row>
            </Aux>
          )
        } else {
          return (
            <Aux>
              <Row className={styles.buttonContainer}>
                <Col md={6} className={styles.row}>
                  <Button className={styles.button} variant='success' onClick={() => {
                    props.history.push(`/${product.productId}`)
                  }}>Reorder</Button>
                </Col>
                <Col md={6} className={styles.row}>
                  <Button className={styles.button} variant='dark'
                          href={`mailto:${product.email}?subject=Green Leaf Trader Product Question - ${product.brand} ${product.strain}`}>Contact</Button>
                </Col>
              </Row>
            </Aux>
          )
        }
      default:
        return;
    }
  }

  const getThcUnit = product => {
    return product.category === "Edibles" ? " mg" : "%"
  }

  const productLayout = (product => {
    return (
      <Aux key={product.id}>
        <Col lg={9} md={12} className={styles.productInfo}>
          <Row>
            <Col md={3} xl={2} className={styles.imageColumn}>
              <div className={styles.imageContainer}>
                <img src={product.productImage} alt={`${product.brand} ${product.strain}`} className={styles.image}/>
              </div>
            </Col>
            <Col md={6} className={styles.textContainer}>
              <div>
                <p className={styles.owner}>{`${product.brand} - ${product.strain}`}</p>
                <div className={styles.numberContainer}>
                  <p>{`THC: ${parseFloat(product.thc)}${getThcUnit(product)}`}</p>
                  <p>{`CBD: ${parseFloat(product.cbd)}%`}</p>
                  <p>{`TRP: ${parseFloat(product.trp)}%`}</p>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <Row className={styles.textContainer}>
                <Col md={12} xs={4} className={styles.quantity}>
                  {`Status: ${product.status}`}
                </Col>
                <Col md={12} xs={4} className={styles.quantity}>
                  {`Quantity: ${product.Quantity} ${product.price_uom}(s)`}
                </Col>
                <Col md={12} xs={4} className={styles.price}>
                  {`Price: $${product.price * product.Quantity}`}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col lg={3} md={12} className={styles.controls}>
          {controls(product)}
        </Col>
      </Aux>
    )
  })

  const generateAddress = (isUrl) => {
    const productInfo = props.list.listProducts[0][0];
    if (isUrl) {
      return `http://maps.apple.com/?daddr=${productInfo.address} ${productInfo.city}, ${productInfo.state} ${productInfo.zip}`
    }
    return `${productInfo.address} ${productInfo.city}, ${productInfo.state} ${productInfo.zip}`
  }

  const generateAddressClass = (phone) => {
    return props.showDeliveryAddress ? styles.showAddress : styles.hideAddress;
  }

  const generatePhone = (phone) => {
    return `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6)}`
  }

  const sellerList = () => {
    return props.list.listProducts.map(listProductList => {
      return (
        <Card.Body className={styles.body} key={listProductList[0].id}>
          <p className={styles.owner}>{`Sold By: ${listProductList[0].sellerBusinessName}`}</p>
          <Row>
            {listProductList.map(listProduct => productLayout(listProduct))}
          </Row>
        </Card.Body>
      )
    })
  }

  const buyerEmail = () => {
    if(props.list.listProducts[0][0].buyerEmail !== ''){
      return(
        <Col lg={3} sm={6}>
          <div>
            <p className={styles.headerTitle}>Purchaser's Email</p>
            <p className={styles.orderNumber}>{props.list.listProducts[0][0].buyerEmail}</p>
          </div>
        </Col>
      )
    }
  }
  return (
    <Card className={styles.card}>
      <Card.Header className={styles.header}>
        <Row>
          <Col lg={3} sm={6}>
            <div>
              <p className={styles.headerTitle}>Order Placed</p>
              <p className={styles.headerContent}>{getDate()}</p>
            </div>
          </Col>
          <Col lg={3} sm={6}>
            <div>
              <p className={styles.headerTitle}>Total</p>
              <p className={styles.total}>{`$${props.list.Total || 'error'}`}</p>
            </div>
          </Col>
          <Col lg={3} sm={6}>
            <div>
              <p className={styles.headerTitle}>Order Number</p>
              <p className={styles.orderNumber}>{props.list.listProducts[0][0].orderNumber}</p>
            </div>
          </Col>
          {buyerEmail()}
        </Row>
        <Row className={generateAddressClass()}>
          <Col md={4} lg={2} sm={6}>
            <p className={styles.headerTitle}>Purchaser</p>
            {`${props.list.listProducts[0][0].buyerBusinessName}`}
          </Col>
          <Col md={4} lg={3} sm={6}>
            <p className={styles.headerTitle}>Address</p>
            <a href={generateAddress(true)}>{generateAddress()}</a>
          </Col>
          <Col md={4} lg={2} sm={6}>
            <p className={styles.headerTitle}>Phone Number</p>
            <a
              href={`tel:${props.list.listProducts[0][0].phone}`}>{generatePhone(props.list.listProducts[0][0].phone)}</a>
          </Col>
          <Col lg={2} sm={6}>
            <p className={styles.headerTitle}>License Number</p>
            <p>{props.list.listProducts[0][0].buyerLicense}</p>
          </Col>
          <Col lg={2} sm={6}>
            <p className={styles.headerTitle}>OBNDD</p>
            <p>{props.list.listProducts[0][0].buyerOBNDD || 'N/A'}</p>
          </Col>
        </Row>
      </Card.Header>
      {sellerList()}
    </Card>
  )
}

export default withRouter(orderItem);