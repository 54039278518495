import React from 'react';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from '../../../../components/Modal/Modal';
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';

import styles from './NewCategory.module.css';

const newCategory = (props) => {
  const buildDropDown = (itemsList, controlId, name, clickedOption) => {
      let defaultValue = clickedOption;
        const options = [];
        itemsList.forEach(item => {
          options.push(<option key={item.id}  value={item.id} >{item.Name}</option>);
        });
    
        return(
            <Form.Group controlId={controlId} key={controlId}>
                <Form.Label>{name}</Form.Label>
                <Form.Control as="select" value={defaultValue} onChange={(event) => {defaultValue = event.target.value}}>
                    {options}
                </Form.Control>
             </Form.Group>
        )
  }
  const actions = () => {
    return(
      <div className={styles.buttons}>
        <Button variant="secondary" onClick={props.doHandleClose}>Close</Button>
        <Button variant="primary" type="submit">Save Changes</Button>
      </div>
    );
  }
  const modalDetails = () => {
    
    if(props.addConfigModal){
      let fields = [];
      props.addConfigModal.fields.forEach(field => {
        if(field === 'name'){
          fields.push(
            <InputGroup size="lg" key={field} >
              <InputGroup.Prepend>
                <InputGroup.Text >Name</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl id="name" aria-label="Large" aria-describedby="inputGroup-sizing-sm" defaultValue={props.editRecord ? props.editRecord.Name : ''}/>
            </InputGroup>
          );
        }
        if(field === 'state'){
          fields.push(
            <InputGroup size="lg" key={field}>
              <InputGroup.Prepend>
                <InputGroup.Text >State</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl id="state" aria-label="Large" aria-describedby="inputGroup-sizing-sm" defaultValue={props.editRecord ? props.editRecord.State : ''}/>
            </InputGroup>
          );
        }
        if(field === 'category'){
          fields.push(buildDropDown(props.categories, 'category', 'Related Category', props.clickedcategoryid));
        }
        if(field === 'subcategory'){
          fields.push(buildDropDown(props.subcategories, 'subcategory', 'Related Subcategory',props.clickedsubcategoryid));
        }
      })
      return(
        
          <Modal show={props.showAddModal} onHide={props.doHandleClose} 
          title={props.addConfigModal.title}
          >
            <Form onSubmit={props.doSubmit}>
            {fields}
            {actions()}
            </Form>
          </Modal>
       
      );
    }
  }

  return(
    <div>
    {modalDetails()}   
   </div> 
  );
}
export default newCategory;