import React, { Component } from 'react';
import { GET } from '../../../helpers/requests'
import Aux from '../../../hoc/Auxiliary/Auxiliary';
import User from '../../../helpers/user';
import styles from './../Dashboards.module.css';

export default class ListedProducts extends Component {
    user = new User();

    _isMounted = false;
  
    state = {
      user: this.user.getUser()
    }
    componentDidMount() {
        this._isMounted = true;
        this.getListedProducts();
      }
    componentWillUnmount() {
        this._isMounted = false;
    }
    getListedProducts = async () => {
        const url = `/dashboards/listedproducts/${this.state.user.id}`
        const listedProducts = await GET(url);
        this.setState({
            "count": listedProducts.count
        });
    }
    render(){
        return(
            <Aux>
                <div className={styles.tile}>
                    <div >
                        <img className={styles.icon} src="https://static.thenounproject.com/png/1375593-200.png" alt='again, download...'/>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.title}>LISTED PRODUCTS</div>
                        <div className={styles.body}>
                            {this.state.count}
                        </div>
                        <div className={styles.footer}>
                        
                        </div>
                    </div>
                </div>
            </Aux>
        )
    }
}