import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router';

import Aux from '../../hoc/Auxiliary/Auxiliary';
import Toast from '../../components/Toast/Toast'
// import ListOfLists from './ListOfLists/ListOfLists';
// import ListPreview from './LIstPreview/ListPreview';
import User from '../../helpers/user';
import { GET, POST, PUT, DELETE } from '../../helpers/requests' 

import styles from './List.module.css';

class List extends Component {
  user = new User();

  _isMounted = false;

  state = {
    toastConfig: {},
    showToast: false,
    showPreviewPane: false,
    showUpdateModal: false,
    updateProduct: {},
    user: this.user.getUser()
  }

  componentDidMount = async () => {
    this._isMounted = true;
    if(this.state.user){
      this.getLists()
    }
    if(this._isMounted){
      let direction
      if(this.state.user && (this.state.user.isGrower || this.state.user.isProcessor)){
        direction = 'In'
      }
      else {
        direction = 'Out';
      }
      this.handleSetDirection(direction);
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  }

  disableUpdate = () => {
    if(this.state && this.state.updateProduct){
      return this.state.updateProduct.Starting_Quantity === this.state.updateProduct.Quantity;
    }
    return true;
  }

  getLists = async () => {
    const statuses = ['Open', 'Submitted', 'Closed', 'Saved for later']
    const url = `${this.props.host}/lists/${this.state.user.id}`
    const lists = await GET(url);
    if(Object.keys(lists).includes('toastConfig')){
      this.setState({...lists});
    }
    else if(this._isMounted){
      const productLists = this.sortProductsToList(lists);
      const sortedLists = {};
      statuses.forEach(status => {
        sortedLists[status] = this.sortListsByStatus(status, productLists);
      })
      this.setState({
        sortedLists,
      }, () => {
        if(this.state.selectedList && this.state.selectedList.id && this.state.selectedList.status){
          this.onListSelect(false, this.state.selectedList)
        }
        const savedForLaterId = this.state.sortedLists['Saved for later'] && this.state.sortedLists['Saved for later'][0] ? this.state.sortedLists['Saved for later'][0].id : undefined;
        if((this.state.user.savedForLaterId !== savedForLaterId) && savedForLaterId !== undefined){
          this.user.setUser({
            ...this.state.user,
            savedForLaterId
          })
        }
      });
    }
  }

  getRequestedLists = async () => {
    const statuses = ['Submitted', 'Closed']
    const url = `${this.props.host}/lists/owner/${this.state.user.id}`
    const lists = await GET(url);
    if(Object.keys(lists).includes('toastConfig')){
      this.setState({...lists});
    }
    else if(this._isMounted){
      const productLists = this.sortProductsToList(lists);
      const sortedLists = {};
      statuses.forEach(status => {
        sortedLists[status] = this.sortListsByStatus(status, productLists);
      })
      this.setState({
        sortedLists,
      }, () => {
        if(this.state.selectedList && this.state.selectedList.id && this.state.selectedList.status){
          this.onListSelect(false, this.state.selectedList)
        }
      });
    }
  }

  getListId(status){
    if(status === 'Open'){
      return this.state.user.listid || '';
    }
    else if(status === 'Saved for later'){
      return this.state.user.savedForLaterId || '';
    }
  }

  getProducts = async () => {
    if(this.state.selectedList !== undefined){
      const productIds = this.getProductIds(this.state.selectedList);
      let queryParams = '?';
      if(productIds && Array.isArray(productIds)){
        productIds.forEach((id, index) => {
          if(index !== 0){
            queryParams+='&';
          }
          queryParams+=`id=${id}`;
        })
      }
      const products = await GET(`products/${queryParams}`);
      if(Object.keys(products).includes('toastConfig')){
        this.setState({...products});
      }
      else{
        const processedProducts = products.map(product => {
          const listProduct = this.state.selectedList.listProducts.filter(listProduct => {
            return listProduct.ProductId === product.id;
          })[0];
          const quantity = listProduct.Quantity;
          const status = listProduct.status
          return {...product, quantity, status}
        })
        this.setState({products: processedProducts}, async () => {
          if(this.state.direction === 'In'){
            const viewedProducts = this.state.selectedList.listProducts.filter(product => {
              const status = product.status;
              return status === 'Submitted' || status === 'Open' || status === undefined;
            }).map(product => {
              return product.id;
            })
            if(viewedProducts.length > 0){
              let query = '?';
              viewedProducts.forEach((id, index) => {
                if(index !== 0){
                  query += `&`;
                }
                query += `id=${id}`
              })
              const url = `/lists/${this.state.selectedList.id}/Viewed${query}`;
              const config = {
                successMessage: 'Product status updated to Viewed',
                component: this
              }
              const response = await POST(url, config);
              this.setState({...response.toast}, () => {
                this.getLists()
              })
            }
          }
        });
      }
    }
  }

  getProductIds = list => {
    if(list && list.listProducts){
      return list.listProducts.map(listProduct => {
        return listProduct.ProductId
      })
    }
  }

  handleDelete = async (id, isSavedForLater) => {
    const url = `/lists/${this.state.selectedList.id}/${id}`
    const config = {
      successMessage: 'Product deleted from list',
      component: this
    }
    const response = await DELETE(url, config);
    if(!isSavedForLater){
      this.setState({
        ...response.toast,
        showUpdateModal: false
      }, () => {
        this.getLists()
      })
    }
    else{
      this.getLists()
    }
  }

  handleQuantityChange = (value) => {
    this.setState(prevState => ({
    updateProduct:{
      ...prevState.updateProduct,
      Quantity:value
    }
  }))}

  handleQuantityUpdate = doIncrement => {
    if(this.state.updateProduct){
      if(doIncrement){
        this.setState(prevState => ({
          updateProduct: {
            ...prevState.updateProduct,
            Quantity: prevState.updateProduct.Quantity += 1
          }
        }))
      }
      else{
        this.setState(prevState => ({
          updateProduct: {
            ...prevState.updateProduct,
            Quantity: prevState.updateProduct.Quantity -= 1
          }
        }))
      }
    }
  }

  handleSaveForLater = async (id, quantity, status) => {
    const listid = this.getListId(status);
    const config = {
      body:{
        listid,
        status,
        userid: this.state.user.id,
        productid: id,
        quantity
      },
      successMessage: status === 'Saved for later' ? 'Product saved for later' : 'Product moved to open request'
    }
    const url = '/lists/addProduct';
    const resposne = await POST(url, config);
    this.setState({
      ...resposne.toast,
      showUpdateModal: false
    }, () => {
      if(resposne.status === 200){
        this.handleDelete(id, status)
      }
      this.getLists()
    })
  }

  handleSetDirection = (direction) => {
    if(direction === 'In'){
      this.getRequestedLists();
    }
    else{
      this.getLists();
    }
    this.setState({
      direction,
      selectedList: undefined,
      products: undefined
    })
  }

  handleShowUpdateModal = (event) => {
    if(event && event.target){
      const updateProduct = JSON.parse(event.target.value);
      this.setState({updateProduct}, () => {
        this.updateModalState('showUpdateModal');
      })
      return;
    }
    this.updateModalState('showUpdateModal')
  }

  handleSubmit = async () => {
    const url = `/lists/${this.state.selectedList.id}/Submitted`
    const user = {
      ...this.state.user,
      listid:''
    }
    const config = {
      successMessage: `Request ${this.state.selectedList.id.substring(0,8)} was submitted`,
      component: this
    }
    const response = await POST(url, config);
    this.setState({
      ...response.toast
    }, () => {
      this.getLists();
      this.user.setUser(user);
    })
  }

  getNextIndex = currentStatus => {
    const statuses = [undefined, 'Open', 'Submitted', 'Viewed','In Progress','Fufilled', 'Delivered', 'Closed'];
    let index;
    statuses.forEach((status,i) => {
      if(status === currentStatus){
        index = i+1;
      }
    })
    return statuses[index];
  }

  handleProductStatusUpdate = async (event) => {
    const { id, status, strain, brand } = event.target.dataset;
    const nextStatus = this.getNextIndex(status);
    const url = `/lists/${this.state.selectedList.id}/${id}/${nextStatus}`;
    const config = {
      successMessage: `${brand.toUpperCase()} ${strain.toUpperCase()} has been updated to ${nextStatus}`,
      component: this
    }
    const response = await POST(url, config);
    this.setState({
      ...response.toast
    }, () => {
      this.getRequestedLists();
    })
  }

  handleUpdateProduct = async () => {
    const url = `/lists/${this.state.selectedList.id}/${this.state.updateProduct.Id}`;
    const config = {
      body: {
        quantity:this.state.updateProduct.Quantity
      },
      successMessage: 'Product quantity updated',
      component: this
    }
    const response = await PUT(url, config);
    this.setState({
      ...response.toast,
      showUpdateModal: false
    }, () => {
      this.getLists();
    })
  }

  listOfListsClasses = () => {
    return this.state.showPreviewPane ? `${styles.listOfLists} ${styles.hideList}` :  styles.listOfLists;
  }

  listPreviewClasses = () => {
    return this.state.showPreviewPane ? styles.listPreview : `${styles.listPreview} ${styles.hideList}`;
  }

  onListSelect = (event, args) => {
    let id;
    let status;
    if(event){
      id = event.target.dataset.id;
      status = event.target.dataset.status;
    }
    else if(args && args.id && args.status){
      id=args.id;
      status=args.status;
    }
    else{
      return;
    }

    const sortedLists = {...this.state.sortedLists};
    if(this.state.selectedList){
      const listStatus = this.state.selectedList.status;
      if(sortedLists[listStatus] && Array.isArray(sortedLists[listStatus])){
        sortedLists[listStatus].forEach(list => {
          if(list.id === this.state.selectedList.id){
            list.active = false;
          }
        })   
      }   
    }

    if(sortedLists[status] && sortedLists[status].length > 0){
      sortedLists[status].forEach(list => {
        if(list.id === id){
          list.active = true;
          this.setState({
            selectedList:list,
            showPreviewPane: true
          }, () => {
            if(this.state.selectedList.listProducts.length > 0){
              this.getProducts();
            }
          })
        }
      })
    }
    else{
      this.setState({
        selectedList:undefined,
        products:undefined,
        showPreviewPane: false
      })
    }
  }

  onBack = () => {
      this.setState({
    showPreviewPane: false
  }, () => {
        });
  }

  updateModalState = modalShowProp => {
    const newState = {}
    newState[modalShowProp] = !this.state[modalShowProp];
    this.setState(newState)
  }

  sortListsByStatus = (status, lists) => {
    if(lists !== undefined){
      return lists.filter(list => {
        const listStatus = list.status;
        return listStatus === status;
      })
    }
  }

  sortProductsToList = lists => {
    let lastListId = '';
    const result = [];

    lists.forEach(list => {
      if(lastListId !== list.List.id){
        result.push(list.List);
        lastListId = list.List.id;
      }
      if(result[result.length-1] && result[result.length-1].listProducts === undefined){
        result[result.length-1].listProducts = [];
      }
      result[result.length-1].listProducts.push(list.List_Products);
    })
    return result
  }

  render(){
    return(
      <Aux>
        <Toast {...this.state.toastConfig} show={this.state.showToast} onClose={() => {this.updateModalState('showToast')}}/>
        <Container className={styles.container}>
          <div>
            <Row>
              {/* <Col md={4} className={`${this.listOfListsClasses()}`}>
                <ListOfLists 
                  {...this.state.sortedLists}  
                  handleListSelect={this.onListSelect}
                  user={this.state.user} 
                  direction={this.state.direction} 
                  onDirectionChange={this.handleSetDirection}
                />
              </Col>
              <Col md={8} className={`${this.listPreviewClasses()}`}>
                <ListPreview 
                  list={this.state.selectedList} 
                  products={this.state.products} 
                  handleBack={this.onBack} 
                  showUpdateModal={this.state.showUpdateModal}
                  toggleUpdateModal={this.handleShowUpdateModal}
                  updateProduct={this.state.updateProduct}
                  onQuantityUpdate={this.handleQuantityUpdate}
                  onQuantityChange={(value) => this.handleQuantityChange(value)}
                  onUpdateProduct={this.handleUpdateProduct}
                  disableUpdate={this.disableUpdate()}
                  onDelete={this.handleDelete}
                  onSaveForLater={this.handleSaveForLater}
                  onSubmit={this.handleSubmit}
                  direction={this.state.direction}
                  onProductStatusUpdate={this.handleProductStatusUpdate}
                /> */}
              {/* </Col> */}
            </Row>
          </div>
        </Container>
      </Aux>
    )
  }
}

export default withRouter(List);