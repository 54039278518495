import React from 'react';
import { Badge } from 'react-bootstrap';

import { ReactComponent as Close } from '../../../assests/images/close.svg';
import styles from './FilterBadges.module.css'

const filterBadges = (props) => {

  const badges = () => {
    const usedBadges = [];
    if(props.filters){
      Object.keys(props.filters).forEach(key => {
        if(key !== 'q' && key !== 'offset' && key !== 'limit'){
          props.filters[key].forEach(filter => {
            usedBadges.push(<h5 key={filter.id}>
              <Badge 
                pill 
                variant='secondary' 
                className={styles.badge} 
                data-value={JSON.stringify(filter)} 
                onClick={props.onFilterChange}
                data-type={key}
              >
                <div className={styles.badgeContentContainer}>
                  <Close width='0.75rem' fill='white'/>
                  <span className={styles.labelSpan}>{filter.Name}</span>
                </div>
              </Badge>
            </h5>);
          })
        }
      })
      return usedBadges;
    }
    return(<p>filterBadges</p>)
  }
  
  return(
    <div className={styles.badgeContainer}>
      {badges()}
    </div>
  )
}

export default filterBadges;