import React, { Component } from 'react';
import { GET } from '../../../helpers/requests'
import Aux from '../../../hoc/Auxiliary/Auxiliary';
import User from '../../../helpers/user';
import styles from './../Dashboards.module.css';

export default class ListedProducts extends Component {
    user = new User();

    _isMounted = false;
  
    state = {
      user: this.user.getUser()
    }
    componentDidMount() {
        this._isMounted = true;
        this.getCompletedOrders();
      }
    componentWillUnmount() {
        this._isMounted = false;
    }
    getCompletedOrders = async () => {
        const url = `/dashboards/orders/closed/${this.state.user.id}`
        const listedProducts = await GET(url);
        this.setState({
            "count": listedProducts.count
        });
    }
    render(){
        return(
            <Aux>
                <div className={styles.tile}>
                    <div >
                        <img className={styles.icon} src="https://image.flaticon.com/icons/svg/2521/2521536.svg" alt='why did we not download this?'/>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.title}>COMPLETED ORDERS</div>
                        <div className={styles.body}>
                            {this.state.count}
                        </div>
                        <div className={styles.footer}>
                        
                        </div>
                    </div>
                </div>
            </Aux>
        )
    }
}