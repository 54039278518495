import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Form, Button, Card, Container } from "react-bootstrap";

import Toast from "./../../components/Toast/Toast";
import Aux from "./../../hoc/Auxiliary/Auxiliary"
import { GET, POST } from '../../helpers/requests';

import styles from './ResetPassword.module.css';
class ResetPassword extends Component {
  constructor() {
    super();

    this.state = {
      username: '',
      password: '',
      updated: false,
      isLoading: true,
      error: false,
      toastConfig: {},
      showToast: false
    };
  }

  componentDidMount = async() => {
    const token = this.props.location.pathname.split('/')[2];
    const url = `/users/resetpassword/${token}`;
    const response = await GET(url);
    if(!response.msg || response.msg !== 'Token verified'){
      this.setState({
        error: true,
        isLoading: false
      })
    }else{
      this.setState({
        username: response.user.username,
        userid: response.user.id,
        updated: false,
        isLoading: false,
        error: false,
      })
    }
  }

  updatePassword = async (event) => {
    event.preventDefault();
    if(event.currentTarget.password.value !== event.currentTarget.confirmpassword.value){
      this.setState({
        toastConfig: {
          variant: 'danger',
          title: 'Error',
          message: 'Passwords do not match.',
          delay: 10000,
          autohide: true,
          onClose: this.updateToast('showToast')
        },
        showToast: true
      })
    }else{
      const url = '/users/changepassword';
      const config = {
        body: {
          id: this.state.userid,
          password: event.currentTarget.password.value
        },
        successMessage: 'Password successfully reset. Redirecting to home page.'
      };
      const response = await POST(url, config);
      this.setState({
        ...response.toast
      }, () => {
        if(response.status && response.status === 200){
          window.setTimeout(() => {
              this.props.history.push('/');
            }, 3000);
          }
        })
      }
  };
  updateToast = (toastShowProp) => {
    const modalState = {};
    modalState[toastShowProp] = !this.state[toastShowProp];
    this.setState(modalState);
  }
  pageDetails = () => {
    if(this.state.error){
      return(
        <Card className="loginCard">
          <Card.Body>
            <Card.Title>Reset Password</Card.Title>
            <p>An error occurred when trying to load this page.</p>
          </Card.Body>
        </Card>
      );
    }else{
      return(
        <Card className="loginCard">
            <Card.Body>
              <Card.Title>Reset Password</Card.Title>
              <Form onSubmit={this.updatePassword}>
                <Form.Group controlId="password">
                  <Form.Label className="cardContent">Password</Form.Label>
                  <Form.Control
                    className="cardContent"
                    type="password"
                    placeholder="Password"
                  />
                </Form.Group>
                <Form.Group controlId="confirmpassword">
                  <Form.Label className="cardContent">Confirm Password</Form.Label>
                  <Form.Control
                    className="cardContent"
                    type="password"
                    placeholder="Confirm Password"
                  />  
                </Form.Group>
                <Button
                  variant="primary"
                  type="submit"
                  className="cardContent loginButton"
                >
                  Reset Password
                </Button>
              </Form>
            </Card.Body>
          </Card>
      );
    }
  }
  render() {
      
      return (
        <Aux>
          <Toast show={this.state.showToast} {...this.state.toastConfig}  onClose={() => {this.setState({showToast:false})}}> </Toast>
          <Container className={styles.container}>
            {this.pageDetails()}
          </Container>
        </Aux>
      )
  }
}
export default withRouter(ResetPassword);