import React from 'react';
import { Button, Card, Row, Col } from 'react-bootstrap';
import NewCategory from './NewCategory/NewCategory';
import styles from './Category.module.css';
const category = (props) => {
  const getExistingAreas = () => {
    let areas = []
    props.areas.forEach(area => {
        areas.push(
            <div key={area.id} className={styles.item}>
              <div className={styles.itemLabel}>
                {area.Name}
              </div>
              <div className="removeItem">
                <Button variant="danger" onClick={props.doHandleDelete} data-value={area.id} data-type="areas">Delete</Button>
                <Button variant="info" onClick={props.doHandleEdit} data-id={area.id} data-value="areas">Edit</Button>
              </div>
            </div>
        );
    })
    return areas
}
const getExistingCategories = () => {
    let categories = []
    props.categories.forEach(cat => {
        categories.push(
            <div className={styles.item} key={cat.id} onClick={props.doCategoryClick} data-value={cat.id} data-name={cat.Name}>
              <div className={styles.itemLabel}>
                {cat.Name}
              </div>
              <div className="removeItem">
                <Button variant="danger" onClick={props.doHandleDelete} data-value={cat.id} data-type="categories">Delete</Button>
                <Button variant="info" onClick={props.doHandleEdit} data-id={cat.id} data-value="categories">Edit</Button>
              </div>
            </div>
        );
    })
    return categories
}

const getExistingSubcategories = () => {
    let subcategories = []
    if(props.clickedcategoryid){
      props.subcategories.forEach(subcat => {
            if(props.clickedcategoryid === subcat.Category){
                subcategories.push(
                    <div className={styles.item} key={subcat.id} onClick={props.doSubcategoryClick} data-value={subcat.id} data-name={subcat.Name}>
                      <div className={styles.itemLabel}>
                        {subcat.Name}
                      </div>
                      <div className="removeItem">
                        <Button variant="danger" onClick={props.doHandleDelete} data-value={subcat.id} data-type="subcategories">Delete</Button>
                        <Button variant="info" onClick={props.doHandleEdit} data-id={subcat.id} data-value="subcategories">Edit</Button>
                      </div>
                    </div>
                );
            }
        })
        let subcategoriesContainer = [];
        subcategoriesContainer.push(
          <Card className="adminCard" key="subCatContainer">
            <Card.Body>
                <Card.Title>
                    <div>{props.clickedcategoryname} > Subcategories</div>
                    <div className="addButton"> <Button variant="warning" data-value="subcategories" onClick={props.doHandleShowAddModal}>Add</Button></div>
                </Card.Title>
                {subcategories}
            </Card.Body>
          </Card>
        )
        return subcategoriesContainer
    }else{
      getExistingTags();
      subcategories.push(
        <Card className="adminCard" key="defaultsubcat">
            <Card.Body>
                <Card.Title>
                    <div>Subcategories</div>
                </Card.Title>
                <div>Please select a category</div>
            </Card.Body>
          </Card>
      );
      return subcategories;
    }
}

const getExistingTags = () => {
    let tags = []
    if(props.clickedsubcategoryid){
          props.tags.forEach(tag => {
            if(props.clickedsubcategoryid === tag.Subcategory){
                tags.push(
                    <div className={styles.item} key={tag.id} data-value={tag.id} data-name={tag.Name}>
                      <div className={styles.itemLabel}>
                        {tag.Name}
                      </div>
                      <div className="removeItem">
                        <Button variant="danger" onClick={props.doHandleDelete} data-value={tag.id} data-type="tags">Delete</Button>
                        <Button variant="info" onClick={props.doHandleEdit} data-id={tag.id} data-value="tags">Edit</Button>
                      </div>
                    </div>
                );
            }
        })
        let tagsContainer = [];
        tagsContainer.push(
          <Card className="adminCard" key="headerTag"> 
            <Card.Body>
                <Card.Title>
                    <div>{props.clickedcategoryname} > {props.clickedsubcategoryname} > Tags</div>
                    <div className="addButton"> <Button variant="warning" data-value="tags" onClick={props.doHandleShowAddModal}>Add</Button></div>
                </Card.Title>
                {tags}
            </Card.Body>
          </Card>
        )
        return tagsContainer
    }else{
      tags.push(
        <Card className="adminCard" key="defaultTag">
            <Card.Body>
                <Card.Title>
                    <div>Tags</div>
                </Card.Title>
                <div>Please select a subcategory</div>
            </Card.Body>
          </Card>
      );
      return tags;
    }
  }
  const getExistingUOM = () => {
    let uoms = []
    if(props.clickedcategoryid){
      props.uom.forEach(uom => {
            if(props.clickedcategoryid === uom.Category){
                uoms.push(
                    <div className={styles.item} key={uom.id}  data-value={uom.id} data-name={uom.Name}>
                      <div className={styles.itemLabel}>
                        {uom.Name}
                      </div>
                      <div className="removeItem">
                        <Button variant="danger" onClick={props.doHandleDelete} data-value={uom.id} data-type="uom">Delete</Button>
                        <Button variant="info" onClick={props.doHandleEdit} data-id={uom.id} data-value="uom">Edit</Button>
                      </div>
                    </div>
                );
            }
        })
        let UOMContainer = [];
        UOMContainer.push(
          <Card className="adminCard" key="UOMContainer">
            <Card.Body>
                <Card.Title>
                    <div>{props.clickedcategoryname} > UOM</div>
                    <div className="addButton"> <Button variant="warning" data-value="uom" onClick={props.doHandleShowAddModal}>Add</Button></div>
                </Card.Title>
                {uoms}
            </Card.Body>
          </Card>
        )
        return UOMContainer
    }else{
      uoms.push(
        <Card className="adminCard" key="defaultUOM">
            <Card.Body>
                <Card.Title>
                    <div>UOM</div>
                </Card.Title>
                <div>Please select a category</div>
            </Card.Body>
          </Card>
      );
      return uoms;
    }
}
  return(
    <div>
      <Row>
        <Col sm>
          <Card className="adminCard">
            <Card.Body>
                <Card.Title>
                    <div>Categories</div>
                    <div className="addButton"> <Button variant="warning" data-value="categories" onClick={props.doHandleShowAddModal}>Add</Button></div>
                </Card.Title>
                {getExistingCategories()}
            </Card.Body>
          </Card>
        </Col>
        <Col>
          {getExistingSubcategories()}
        </Col>
        <Col sm>
          {getExistingTags()}
        </Col>
      </Row>
      <Row>
        <Col sm>
          <Card className="adminCard">
            <Card.Body>
              <Card.Title>
                      <div>Areas</div>
                      <div className="addButton"> <Button variant="warning" data-value="areas" onClick={props.doHandleShowAddModal}>Add</Button></div>
                  </Card.Title>
                  {getExistingAreas()}
              </Card.Body>
            </Card>
        </Col>
        <Col sm>
          {getExistingUOM()}
        </Col>
      </Row>
                
      <NewCategory {...props} doHandleClose={props.doHandleCloseAddModal} doSubmit={props.doHandleSubmit}/>
    </div>
  )
}
export default category;