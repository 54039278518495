import React from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import Modal from '../../../../components/Modal/Modal';
import styles from './NewUser.module.css';
import pricestyles from '../../../Register/Pricing/Pricing.module.css';
import UploadImages from '../../../../components/UploadImages/UploadImages';

const newUser = (props) => {
  // const area = () => {
  //   let editArea = '';
  //   if (props.editRecord && props.editRecord.area) {
  //     editArea = props.editRecord.area;
  //   }
  //   let options = []
  //   props.areas.forEach(area => {
  //     if (editArea === area.id) {
  //       options.push(<option key={area.id} value={area.id} selected>{area.Name}</option>);
  //     } else {
  //       options.push(<option key={area.id} value={area.id}>{area.Name}</option>);
  //     }

  //   });
  //   return (
  //     <Form.Group as={Col} controlId="area">
  //       <Form.Label>Area</Form.Label>
  //       <Form.Control as="select" id="area">
  //         {options}
  //       </Form.Control>
  //     </Form.Group>
  //   )
  // }

  const area = () => {
    let options = []
    options.push(<option value="" key="Select">Please Select One</option>);
    options.push(<option value="Central" key="Central" selected={props.editRecord && props.editRecord.area === 'Central' ? props.editRecord.area : false}>Central</option>);
    options.push(<option value="Northwest" key="Northwest" selected={props.editRecord && props.editRecord.area === 'Northwest' ? props.editRecord.area : false}>Northwest</option>);
    options.push(<option value="Northeast" key="Northeast" selected={props.editRecord && props.editRecord.area === 'Northeast' ? props.editRecord.area : false}>Northeast</option>);
    options.push(<option value="Southeast" key="Southeast" selected={props.editRecord && props.editRecord.area === 'Southeast' ? props.editRecord.area : false}>Southeast</option>);
    options.push(<option value="Southwest" key="Southwest" selected={props.editRecord && props.editRecord.area === 'Southwest' ? props.editRecord.area : false}>Southwest</option>);
    return (
      <Form.Group as={Col} controlId="area">
        <Form.Label>Area</Form.Label>
        <Form.Control as="select" id="area" required >
          {options}
        </Form.Control>
      </Form.Group>
    )
  }
  
  const accountStatus = () => {
    let active = false;
    let pending = false;
    let inactive = false;
    if (props.editRecord) {
      if (props.editRecord.status === 'active') {
        active = true;
      }
      if (props.editRecord.status === 'pending') {
        pending = true;
      }
      if (props.editRecord.status === 'inactive') {
        inactive = true;
      }
    }
    let options = []
    options.push(<option value="active" key="active" selected={active}>Active</option>);
    options.push(<option value="pending" key="pending" selected={pending}>Pending</option>);
    options.push(<option value="inactive" key="inactive" selected={inactive}>Inactive</option>);
    return (
      <Form.Group as={Col} controlId="accountStatus">
        <Form.Label>Account Status</Form.Label>
        <Form.Control as="select" id="accountStatus">
          {options}
        </Form.Control>
      </Form.Group>
    )
  }
  const displayLicenseImage = () => {
    if (props.editRecord && props.editRecord.license_image) {
      return (
        <img src={props.editRecord.license_image} alt='Upload failed' className={styles.image} />
      );
    }
  }
  const accountType = () => {
    let options = []
    options.push(<option value="" key="Select">Please Select One</option>);
    options.push(<option value="Admin" key="admin" selected={props.editRecord ? props.editRecord.isAdmin : 'false'}>Admin</option>);
    options.push(<option value="Dispensary" key="dispensary" selected={props.editRecord ? props.editRecord.isDispensary : 'false'}>Dispensary</option>);
    options.push(<option value="Grower" key="grower" selected={props.editRecord ? props.editRecord.isGrower : 'false'}>Grower</option>);
    options.push(<option value="Processor" key="processor" selected={props.editRecord ? props.editRecord.isProcessor : 'false'}>Processor</option>);
    options.push(<option value="SalesRep" key="salesrep" selected={props.editRecord ? props.editRecord.isSalesRep : 'false'}>Sales Rep</option>);
    return (
      <Form.Group as={Col} controlId="accountType">
        <Form.Label>Account Type</Form.Label>
        <Form.Control as="select" id="accountType" required >
          {options}
        </Form.Control>
      </Form.Group>
    )
  }
  const memberTier = () => {
    let buyeronly = false;
    let sellerpremium = false;
    if (props.editRecord) {
      if (props.editRecord.membership_tier === 'BuyerOnly') {
        buyeronly = true;
      }
      if (props.editRecord.membership_tier === 'SellerPremium') {
        sellerpremium = true;
      }
    }
    let options = []

    options.push(<option value="" key="Select">Please Select One</option>);
    options.push(<option value="BuyerOnly" key="buyeronly" selected={buyeronly}>Buyer Only - FREE</option>);
    options.push(<option className={pricestyles.header} style={{ backgroundColor: '#E4E307' }} value="SellerPremium" key="sellerpremium" selected={sellerpremium}>Seller Premium - $399/month</option>);
    return (
      <Form.Group as={Col} controlId="membership">
        <Form.Label>Membership Tier</Form.Label>
        <Form.Control as="select" id="memberTier" required>
          {options}
        </Form.Control>
      </Form.Group>
    )
  }
  const salesrepresentative = () => {
    let josh = false;
    let morgan = false;
    let nicole = false;
    let na = false;
    if (props.editRecord) {
      if (props.editRecord.salesrep === 'Josh') {
        josh = true;
      }
      if (props.editRecord.salesrep === 'Morgan') {
        morgan = true;
      }
      if (props.editRecord.salesrep === 'Nicole') {
        morgan = true;
      }
      if (props.editRecord.salesrep === 'N/A') {
        na = true;
      }
    }
    let options = []
    options.push(<option value="" key="Select">Please Select One</option>);
    options.push(<option value="Josh" key="josh" selected={josh}>Josh</option>);
    options.push(<option value="Morgan" key="morgan" selected={morgan}>Morgan</option>);
    options.push(<option value="Nicole" key="nicole" selected={nicole}>Nicole</option>);
    options.push(<option value="N/A" key="na" selected={na}>N/A</option>);
    return (
      <Form.Group as={Col} controlId="salesrepresentative">
        <Form.Label>Sales Representative</Form.Label>
        <Form.Control as="select" id="salesrep" required>
          {options}
        </Form.Control>
      </Form.Group>
    )
  }
  
  const actions = () => {
    return (
      <div className={styles.actions}>
        <Button variant="secondary" onClick={props.handleClose}>Cancel</Button>
        <Button variant="primary" type="submit" >Save</Button>
      </div>
    )
  }

  const modalDetails = () => {
    if (props.showNewUser) {
      return (
        <Modal show={props.showNewUser} onHide={props.handleClose} title={props.editRecord ? 'Edit User' : 'New User'}>
          <Form onSubmit={props.doNewUserSubmit}>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridfirstname">
                <Form.Label>First Name</Form.Label>
                <Form.Control placeholder="Bob" id="firstname" required defaultValue={props.editRecord ? props.editRecord.firstname : ''} />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridlastname">
                <Form.Label>Last Name</Form.Label>
                <Form.Control placeholder="Smith" id="lastname" required defaultValue={props.editRecord ? props.editRecord.lastname : ''} />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formHorizontalAddress">
                <Form.Label>Business Name (For verification and/or product delivery)</Form.Label>
                <Form.Control placeholder="Business Name" id="businessName" required defaultValue={props.editRecord ? props.editRecord.business_name : ''} />
              </Form.Group>
              <Form.Group as={Col} controlId="formHorizontalAddress">
                <Form.Label>Business Address (Delivery address if applicable)</Form.Label>
                <Form.Control placeholder="123 maple ave" id="address" required defaultValue={props.editRecord ? props.editRecord.address : ''} />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formHorizontalCity">
                <Form.Label>City</Form.Label>
                <Form.Control placeholder="City" id="city" required defaultValue={props.editRecord ? props.editRecord.city : ''} />
              </Form.Group>
              <Form.Group as={Col} controlId="formHorizontalState">
                <Form.Label>State</Form.Label>
                <Form.Control placeholder="State" id="state" required defaultValue={props.editRecord ? props.editRecord.state : ''} />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formHorizontalZip">
                <Form.Label>Zip</Form.Label>
                <Form.Control placeholder="zip" id="zip" required defaultValue={props.editRecord ? props.editRecord.zip : ''} />
              </Form.Group>
              {area()}
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formHorizontalPhone">
                <Form.Label>Phone</Form.Label>
                <Form.Control placeholder="Phone" id="phone" required defaultValue={props.editRecord ? props.editRecord.phone : ''} />
              </Form.Group>
              <Form.Group as={Col} controlId="formHorizontalEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="Email" id="email" required defaultValue={props.editRecord ? props.editRecord.email : ''} />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formHorizontalPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Password" id="password" disabled={props.editRecord ? true : false} />
              </Form.Group>
              <Form.Group as={Col} controlId="formHorizontalPassword">
                <Form.Label>Re-enter Password</Form.Label>
                <Form.Control type="password" placeholder="Re-enter Password" id="password2" disabled={props.editRecord ? true : false} />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              {accountType()}
              {memberTier()}
            </Form.Row>
            <Form.Row>
              {accountStatus()}
              {salesrepresentative()}
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formHorizontalLicense">
                <Form.Label>License Number</Form.Label>
                <Form.Control placeholder="ABD1234" id="license_number" required defaultValue={props.editRecord ? props.editRecord.license_number : ''} />
              </Form.Group>
              <Form.Group as={Col} controlId="formHorizontalLicenseImage">
                <Form.Label>Upload File\Photo of Business License</Form.Label>
                {/* <UploadImages onFileAdded={(file) => { onImageUpload(file, 'license_image') }} picture={this.state.license_image} /> */}
              </Form.Group>
              {displayLicenseImage()}
            </Form.Row>
            {actions()}
          </Form>
        </Modal>
      )
    }
  }
  return (
    <div>
      {modalDetails()}
    </div>
  );
}
export default newUser;