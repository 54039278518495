import React from 'react';
import { Button } from 'react-bootstrap';

import Modal from '../../../components/Modal/Modal'

import styles from './Filters.module.css';

const filters= (props) => {
  const checkActive = (filterArray, value) => {
    let active = false;
    if(props.filters[filterArray]){
      props.filters[filterArray].forEach(filter => {
        if(filter.Name === value){
          active = true;
        }
      })
    }
    return active;
  }

  const cancel = () => {
    props.doClear();
    props.onHide();
  }

  const actions = () => {
    return(
      <div className={styles.buttonContainer}>
        <Button onClick={cancel}>Cancel</Button>
        <Button variant='danger' onClick={props.doClear}>Clear All</Button>
        <Button variant='success' onClick={props.onApplyFilters}>Apply</Button>
      </div>
    )
  }

  const filterButtons = (filterArray, filterAttr, filterValue) => {
    const buttons = [];
    const used = [];
    const stateAttr = getStateAttribute(filterArray);
    if(props[filterArray]){
      props[filterArray].forEach(filter => {
        if(filterValue && filterValue[0]){
           if (filter[filterAttr] === filterValue[0].id && !used.includes(filter.Name)) {
             buttons.push(
              <Button 
                key={filter.id} 
                value={JSON.stringify(filter)} 
                onClick={props.onFilterChange} 
                data-type={stateAttr} 
                variant='outline-success'
                active={checkActive(stateAttr, filter.Name)}
                className={styles.filterButton}
              >{filter.Name}</Button>);
             used.push(filter.Name);
           }
        }
        else{
          buttons.push(
            <Button 
              key={filter.id} 
              value={JSON.stringify(filter)} 
              onClick={props.onFilterChange}  
              variant='outline-success' 
              active={checkActive(stateAttr, filter.Name)} 
              data-type={stateAttr}
              className={styles.filterButton}
            >{filter.Name}</Button>);
        }
      })
    }
    return buttons;
  }

  const getStateAttribute = (filterArray) => {
    switch(filterArray){
      case('areas'):
        return 'Area';
      case('tags'):
        return 'Tag';
      case('categories'):
        return 'Category'
      case('subcategories'):
        return 'Subcategory';
      default:
        return '';
    }
  }

  const tags = (filterAttr) => {
    if(props.filters[filterAttr] !== undefined && props.filters[filterAttr].length !== 0){
      return(
      <div>
        <h5>Tags</h5>
        <div>
          {filterButtons('subcategories', 'Category', props.filters.Category)}
        </div>
        <div>
          {filterButtons('tags', 'Category', props.filters.Category)}
        </div>
      </div>
      )
    }
  }

  return(
    <Modal 
      show={props.show} 
      title='Filters' 
      actions={actions()} 
      onHide={cancel}
      showbutton='true'
    >
      <div className={styles.filterContainer}>
        {/* <div>
          <h5>Delivery Areas</h5>
          {filterButtons('areas')}
        </div> */}
        <div>
        <h5>Areas</h5>
          <Button 
            className={styles.filterButton} 
            variant='outline-success'
            data-type='Area'
            value={JSON.stringify({id: 'Central', Name: 'Central', Value: 1})}
            active={checkActive('Area', 'Central')}
            onClick={props.onFilterChange} 
          >Central</Button>
          <Button 
            className={styles.filterButton} 
            variant='outline-success'
            data-type='Area'
            value={JSON.stringify({id: 'Northwest', Name: 'Northwest', Value: 2})}
            active={checkActive('Area', 'Northwest')}
            onClick={props.onFilterChange} 
          >Northwest</Button>
          <Button 
            className={styles.filterButton} 
            variant='outline-success'
            data-type='Area'
            value={JSON.stringify({id: 'Northeast', Name: 'Northeast', Value: 2})}
            active={checkActive('Area', 'Northeast')}
            onClick={props.onFilterChange} 
          >Northeast</Button>
          <Button 
            className={styles.filterButton} 
            variant='outline-success'
            data-type='Area'
            value={JSON.stringify({id: 'Southeast', Name: 'Southeast', Value: 2})}
            active={checkActive('Area', 'Southeast')}
            onClick={props.onFilterChange} 
          >Southeast</Button>
          <Button 
            className={styles.filterButton} 
            variant='outline-success'
            data-type='Area'
            value={JSON.stringify({id: 'Southwest', Name: 'Southwest', Value: 2})}
            active={checkActive('Area', 'Southwest')}
            onClick={props.onFilterChange} 
          >Southwest</Button>
        </div>
      </div>
      <div>
        <h5>Categories</h5>
        {filterButtons('categories')}
      </div>
      {tags('Category')}
    </Modal>
  )

}

export default filters