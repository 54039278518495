import React, {Component} from 'react';
import { Form, Button, Container } from "react-bootstrap";

import Toast from '../../../../components/Toast/Toast';
import Aux from '../../../../hoc/Auxiliary/Auxiliary';
import { POST } from '../../../../helpers/requests';

class ForgotPassword extends Component {
  state = {
    errors: [],
    success : false,
    toastConfig: {},
    showToast: false
  };

  updateModal = (value) => {
    const newState = {};
    newState[value] =!this.state[value];
    this.setState(newState);
  }

  resetPassword = async (event) => {
    event.preventDefault();
      const url = '/users/resetpassword';
      const config = {
        body: {email: event.currentTarget.email.value},
        successMessage: 'Password has been reset',
        component: this
      };
      const response = await POST(url, config);
      if(response.error && response.error.response.status === 400){
        this.setState({
          toastConfig: {
            ...response.toast.toastConfig,
            message: 'Email not found. Please enter a valid email'
          },
          showToast: response.toast.showToast
        })
      }
      else{
        this.setState({
          ...response.toast,
          errors:[],
          success:true
        })
      }
  };
  
  render() {
    const pageDetails = () => {
      const { errors, success } = this.state;
      if(!success){
        return(
          <Form onSubmit={this.resetPassword}>
            <Form.Group controlId="email">
              <Form.Label className="cardContent">Please enter the email associated with your account.</Form.Label>
              {errors.map(error => (
                <p key={error}>Error: {error}</p>
              ))}
              <Form.Control
                className="cardContent"
                type="email"
                placeholder="Email"
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              className="cardContent loginButton"
            >
              Reset Password
            </Button>
          </Form>
          )
      }else{
        return(
          <div>
            <p>Email Sent!</p>
            <p>We have sent you an email with instructions to reset your password.</p>
          </div>
        );
      }
    }
    return(
      <Aux>
        <Toast {...this.state.toastConfig} show={this.state.showToast} onClose={() => {this.updateModal('showToast')}}/>
        <Container>
          {pageDetails()}
        </Container>
      </Aux>
    )
  }
}

export default ForgotPassword;