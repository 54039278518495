import React from 'react';
import { withRouter } from 'react-router';

import Login from './Login/Login';
import ForgotPassword from './ForgotPassword/ForgotPassword'
import Modal from '../../../components/Modal/Modal';
import Aux from '../../../hoc/Auxiliary/Auxiliary';

const loginContainer = (props) => {
  const containerContent = () => {
    if(props.showForgotPassword){
      return(
        <ForgotPassword {...props} />
      )
    }else{
      return(
        <Login {...props} />
      )
    }
  }
  const containerTitle = () => {
    if(props.showForgotPassword){
      return 'Forgot Password';
    }else{
      return 'Login';
    }
  }
  return(
    <Aux>
      <Modal 
        show={props.showLogin} 
        title={containerTitle()}
        onHide={props.updateModal}
        showbutton="true"
      >
        {containerContent()}
      </Modal>
    </Aux>
  )
}

export default withRouter(loginContainer);