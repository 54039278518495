import React from 'react';
import { Button } from 'react-bootstrap'; 

import Modal from '../../../components/Modal/Modal';
import Aux from '../../../hoc/Auxiliary/Auxiliary';

import styles from './Submit.module.css';

const submit = (props) => {
  const total = () => {
    if(props.order && props.order.list){
      return props.order.list.Total;
    }
  }

  const actions = () => {
    return(
      <Aux>
        <Button variant='success' onClick={props.onSubmit}>Submit Order</Button>
        <Button variant='danger' onClick={props.onBack}>Back to Products</Button>
      </Aux>
    )
  }

  return(
    <Aux>
      <Modal show={props.show} title='Submit Order' onHide={props.onHide} actions={actions()}>
      <p>You are submitting and order for <b>{props.productCount} product(s)</b> with a total of <b>${props.total}</b>. Are you sure your order is correct, and you are ready to checkout?</p>
      </Modal>
      <div style={{paddingBottom: '1rem'}}>
        <div className={styles.totalContainer}>
          <p className={styles.total}>Total:</p>
          <p className={styles.total}>${total()}</p>
        </div>
        <Button variant='success' block onClick={props.onHide}>Submit Order</Button>
      </div>
    </Aux>
  )
}

export default submit;