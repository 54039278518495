import React from 'react';

import Modal from '../../../components/Modal/Modal';

const aboutUs = (props) => {
  return(
    <Modal 
      title='About Us' 
      show={props.show}
      onHide={props.onHide}
      showbutton='true'
    >
      <div>
        <p>Green Leaf Trader is an Oklahoma owned and operated company.</p>
        <p>We provide licensed cultivators, processors and dispensaries with the outlet they need to expand their network and grow their business for higher profits.</p>
        <p>Our account managers travel state wide searching for Oklahoma's premier cannabis suppliers and dispensaries.</p>
      </div>
    </Modal>
  )
}

export default aboutUs;