import React from 'react';
import CardDeck from 'react-bootstrap/CardDeck'
import { Container, Badge, Form, Button, Card, CardGroup, Row, Col, Nav, Navbar } from 'react-bootstrap';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import { ReactComponent as Plus } from '../../assests/images/plus.svg'
import { ReactComponent as Minus } from '../../assests/images/minus.svg'
import stylesProduct from './Product.module.css';
import stylesNav from './Navigation.module.css';
import stylesSearch from './Search.module.css'
import stylesShopCart from './OrderItemCart.module.css';
import stylesMail from './OrderItemMail.module.css';
import stylesOrders from './OrderItemHistory.module.css';
import stylesProductListItm from './ProductListItem.module.css';
import stylesQty from './Quantity.module.css';
import { ReactComponent as Delivery } from '../../assests/images/delivery.svg';
import howtoBuyProductImg from '../../assests/images/howToBuyPineappleExpress.jfif';
import howtoBuyProdImg2 from '../../assests/images/howToBuyGranddaddyPurple.jpg';
import howtoBuyWhiteFire from '../../assests/images/howToBuyWhiteFire.jpg';
import howtoBuyGrapeApe from '../../assests/images/howToBuyGrapeApe.jpg';
import howtoBuyBlueBerry from '../../assests/images/howToBuyBlueBerry.jpg';
import logo from '../../assests/images/logo.jpg';
import ReactGA from 'react-ga';

const trackingId = "UA-189846059-1"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);

const howToBuy = () => {
    return (
        <div>
            <Card style={{ textAlign: 'center' }}>
                <Card.Header style={{ fontSize: '26px', fontWeight: 'bold', backgroundColor: 'white' }}>How It Works</Card.Header>
                <Card.Header style={{ fontSize: '30px', fontWeight: 'bold', color: 'blue' }}>BUYER</Card.Header>
                <Card.Body>
                    <Card.Title style={{ fontSize: '20px' }}>100 % FREE<br />NO TRANSACTION FEES</Card.Title>
                    <Card.Text style={{ fontSize: '18px' }}>
                        Green Leaf Trader makes it easier to purchase from your current vendors and
                        find new products and or new vendors throughout the state, all in one easy
                        to use format.
                        <br /><br />
                    </Card.Text>
                </Card.Body>
                {/* <Card.Footer style={{ fontSize: '30px' }}>Registration</Card.Footer> */}
            </Card>
            <br />

            {/* BEGIN NAV  */}
            <Navbar disabled='true' style={{ margin: '0 auto', width: '92%', borderStyle: 'solid', borderWidth: '1px' }} expand="lg" className={stylesNav.navbar} >
                <Container className={stylesNav.container}>
                    <Navbar.Brand href="/" className={stylesNav.showOnDesktop}>
                        <img src={logo} alt='Cannalist Ok' className={stylesNav.logo} />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" className='custom-toggler' />
                    <Navbar.Collapse id="responsive-navbar-nav" className={`${stylesNav.maxHeight} ${stylesNav.collapse}`}>
                        <Nav className={`ml-auto ${stylesNav.maxHeight}`}>
                            <Nav.Link className={`${stylesNav.link} ${stylesNav.permanent}`}>Products</Nav.Link>
                            <Aux>
                                <Nav.Link className={`${stylesNav.link} ${stylesNav.permanent}`}>Pricing</Nav.Link>
                            </Aux>
                            {/* <Aux>
                                <Nav.Link className={`${stylesNav.link} ${stylesNav.permanent}`}>Orders</Nav.Link>
                            </Aux> */}
                            <Nav.Link className={`${stylesNav.link} ${stylesNav.permanent}`} >How It Works</Nav.Link>
                            <Nav.Link className={`${stylesNav.link} ${stylesNav.permanent}`}>Contact Us</Nav.Link>
                            <Nav.Link className={`${stylesNav.link} ${stylesNav.permanent}`} >Login / Register</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                    <Navbar.Brand href="/" className={stylesNav.hideOnDesktop}>
                        <img src={logo} alt='Cannalist Ok' className={stylesNav.logo} />
                    </Navbar.Brand>
                </Container>
            </Navbar>
            <Navbar fixed='bottom' className={stylesNav.navbarBottom}>
                <Container style={{ padding: '1rem' }}>
                    <p>Green Leaf Trader, LLC</p>
                </Container>
            </Navbar>
            <br />
            {/* END NAV  */}

            <div>
                <Form className={stylesSearch.searchBarContainer}>
                    <Row className={stylesSearch.searchBar}>
                        <Col sm={8} md={8}>
                            <Form.Group controlId='q' className={stylesSearch.formGroup}>
                                <Form.Control disabled='true' placeholder='Search by brand or strain' />
                            </Form.Group>
                        </Col>
                        <Col sm={4} md={4}>
                            <Button disabled='true' className={stylesSearch.button} variant="success">Filter</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
            {/* end nav search */}

            {/* BEGIN Main PRODUCTS */}
            <Aux>
                {/* <CardGroup style={{ margin: '0 auto', width: '90%', borderStyle: 'solid', borderWidth: '1px' }}> */}
                {/* Main Products Item One */}
                <CardDeck style={{ margin: '0 auto', width: '96%', borderStyle: 'solid', borderWidth: '1px' }}>
                    <Card className={stylesProductListItm.productCard}>
                        <div className={stylesProductListItm.noClick}>
                            <Card.Body>
                                <div className={`${stylesProductListItm.infoContainer} card-text`}>
                                    <h5 style={{ margin: 0 }}><Badge pill className={stylesProductListItm.Sativa}>Sativa</Badge></h5>
                                    {/* <span className={stylesProductListItm.delivery}>
                                        <Delivery width='30px' height='30px' />
                                    </span> */}
                                </div>
                            </Card.Body>
                            <div className={stylesProductListItm.imageContainer}>
                                <Card.Img variant="top" src={howtoBuyBlueBerry} className={stylesProductListItm.image} />
                            </div>
                            <Card.Body>
                                <div className={`${stylesProductListItm.infoContainer} card-text`}>
                                    <p>THC: 23%</p>
                                    <p>CBD: 5%</p>
                                </div>
                                <div className={`${stylesProductListItm.infoContainerCentered} card-text`}>
                                    <p>TRP: 3%</p>
                                </div>
                                <div className={`${stylesProductListItm.infoContainerCenteredGreen} card-text`}>
                                    <p>$1600/Lb</p>
                                </div>
                                <p className={stylesProductListItm.brand}>KONG</p>
                                <Card.Title style={{ justifyContent: 'center', textAlign: 'center', fontWeight: '900', display: 'flex' }}>BLUEBERRY</Card.Title>
                            </Card.Body>
                        </div>
                    </Card>

                    {/* Main products item 2 */}
                    <Card className={stylesProductListItm.productCard}>
                        <div className={stylesProductListItm.noClick}>
                            <Card.Body>
                                <div className={`${stylesProductListItm.infoContainer} card-text`}>
                                    <h5 style={{ margin: 0 }}><Badge pill className={stylesProductListItm.Indica}>Indica</Badge></h5>
                                    {/* <span className={stylesProductListItm.delivery}>
                                        <Delivery width='30px' height='30px' />
                                    </span> */}
                                </div>
                            </Card.Body>
                            <div className={stylesProductListItm.imageContainer}>
                                <Card.Img variant="top" src={howtoBuyProductImg} className={stylesProductListItm.image} />
                            </div>
                            <Card.Body>
                                <div className={`${stylesProductListItm.infoContainer} card-text`}>
                                    <p>THC: 20%</p>
                                    <p>CBD: 3%</p>
                                </div>
                                <div className={`${stylesProductListItm.infoContainerCentered} card-text`}>
                                    <p>TRP: 4%</p>
                                </div>
                                <div className={`${stylesProductListItm.infoContainerCenteredGreen} card-text`}>
                                    <p>$2600/Lb</p>
                                </div>
                                <p className={stylesProductListItm.brand}>LA PAZ</p>
                                <Card.Title style={{ justifyContent: 'center', textAlign: 'center', fontWeight: '900', display: 'flex' }}>SOUR DIESEL</Card.Title>
                            </Card.Body>
                        </div>
                    </Card>

                    {/* Main products item 3 */}
                    <Card className={stylesProductListItm.productCard}>
                        <div className={stylesProductListItm.noClick}>
                            <Card.Body>
                                <div className={`${stylesProductListItm.infoContainer} card-text`}>
                                    <h5 style={{ margin: 0 }}><Badge pill className={stylesProductListItm.Sativa}>Sativa</Badge></h5>
                                    {/* <span className={stylesProductListItm.delivery}>
                                        <Delivery width='30px' height='30px' />
                                    </span> */}
                                </div>
                            </Card.Body>
                            <div className={stylesProductListItm.imageContainer}>
                                <Card.Img variant="top" src={howtoBuyGrapeApe} className={stylesProductListItm.image} />
                            </div>
                            <Card.Body>
                                <div className={`${stylesProductListItm.infoContainer} card-text`}>
                                    <p>THC: 75%</p>
                                    <p>CBD: 1%</p>
                                </div>
                                <div className={`${stylesProductListItm.infoContainerCentered} card-text`}>
                                    <p>TRP: 7%</p>
                                </div>
                                <div className={`${stylesProductListItm.infoContainerCenteredGreen} card-text`}>
                                    <p>$25/Gram</p>
                                </div>
                                <p className={stylesProductListItm.brand}>KONG</p>
                                <Card.Title style={{ justifyContent: 'center', textAlign: 'center', fontWeight: '900', display: 'flex' }}>GRAPE APE</Card.Title>
                            </Card.Body>
                        </div>
                    </Card>

                    {/* Main Orders Item 4 */}
                    <Card className={stylesProductListItm.productCard}>
                        <div className={stylesProductListItm.noClick}>
                            <Card.Body>
                                <div className={`${stylesProductListItm.infoContainer} card-text`}>
                                    <h5 style={{ margin: 0 }}><Badge pill className={stylesProductListItm.Hybrid}>Hybrid</Badge></h5>
                                    {/* <span className={stylesProductListItm.delivery}>
                                        <Delivery width='30px' height='30px' />
                                    </span> */}
                                </div>
                            </Card.Body>
                            <div className={stylesProductListItm.imageContainer}>
                                <Card.Img variant="top" src={howtoBuyWhiteFire} className={stylesProductListItm.image} />
                            </div>
                            <Card.Body>
                                <div className={`${stylesProductListItm.infoContainer} card-text`}>
                                    <p>THC: 91%</p>
                                    <p>CBD: 3%</p>
                                </div>
                                <div className={`${stylesProductListItm.infoContainerCentered} card-text`}>
                                    <p>TRP: 4%</p>
                                </div>
                                <div className={`${stylesProductListItm.infoContainerCenteredGreen} card-text`}>
                                    <p>$30/Unit</p>
                                </div>
                                <p className={stylesProductListItm.brand}>HIGH RISE</p>
                                <Card.Title style={{ justifyContent: 'center', textAlign: 'center', fontWeight: '900', display: 'flex' }}>WHITE FIRE</Card.Title>
                            </Card.Body>
                        </div>
                    </Card>
                </CardDeck>
                {/* </CardGroup> */}
            </Aux>
            {/* END MAIN PRODUCTS/*/}

            <br /><br />

            {/* Begin filter */}

            <Card.Text style={{ fontSize: '18px', textAlign: 'center' }}>Use our filter to find exactly what your business needs.
                <br />
            </Card.Text>
            <Col xs={10} sm={10} md={8} lg={7} xl={7} style={{ margin: '0 auto', width: '80%', borderStyle: 'solid', borderWidth: '1px' }}>
                <CardGroup >
                    <Card>
                        <Row>
                            <Card.Body>
                                <Card.Text style={{ fontSize: '18px', fontBold: 'true' }}>Testing</Card.Text>
                                <Button variant="outline-success" active="true">Test Results</Button>{' '}
                                <Button variant="outline-success" disabled="true">No Test Results</Button>{' '}
                            </Card.Body>
                        </Row>
                        <Row>
                            <Card.Body>
                                <Card.Text style={{ fontSize: '18px', fontBold: 'true' }}>Categories</Card.Text>
                                <Button variant="outline-success" active="true">Flower</Button>{' '}
                                <Button variant="outline-success" disabled="true">Concentrates</Button>{' '}
                                <Button variant="outline-success" disabled="true">Edibles</Button>{' '}
                                <Button variant="outline-success" disabled="true">Prerolls</Button>{' '}
                                <Button variant="outline-success" disabled="true">Trim</Button>{' '}
                                <Button variant="outline-success" disabled="true">Crop</Button>{' '}
                                <Button variant="outline-success" disabled="true">Clones</Button>{' '}
                            </Card.Body>
                        </Row>
                        <Row>
                            <Card.Body>
                                <Card.Text style={{ fontSize: '18px', fontBold: 'true' }}>Tags</Card.Text>
                                <Button variant="outline-success" disabled="true">Indica</Button>{' '}
                                <Button variant="outline-success" active="true">Sativa</Button>{' '}
                                <Button variant="outline-success" disabled="true">Hybrid</Button>{' '}
                                <Button variant="outline-success" active="true">Top Shelf</Button>{' '}
                                <Button variant="outline-success" disabled="true">Mid-Grade</Button>{' '}
                                <Button variant="outline-success" disabled="true">Low Grade</Button>{' '}
                            </Card.Body>
                        </Row>
                        <Row>
                            <Card.Body>
                                <div align='right'>
                                    <Button variant="primary" disabled="true">Cancel</Button>{' '}
                                    <Button variant="success" disabled="true">Apply</Button>{' '}
                                    <Button variant="danger" disabled="true">Clear All</Button>
                                </div>
                            </Card.Body>
                        </Row>
                    </Card>
                </CardGroup>
            </Col>
            {/* end filter */}

            <br /><br />
            <Card.Text style={{ fontSize: '18px', textAlign: 'center' }}>Dispensaries can use the marketplace to create orders, request samples, and send messages with a few clicks.
                <br />
            </Card.Text>
            {/* Product Display */}
            <Col xs={10} sm={10} md={10} lg={10} xl={7} style={{ margin: '0 auto', width: '80%', borderStyle: 'solid', borderWidth: '1px' }}>
                <CardGroup >
                    <Card>
                        <Aux>
                            <Container className={stylesProduct.container}>
                                <div className={stylesProduct.centeredColumns}>
                                    <div className={stylesProduct.brand}>
                                        <h4>KONG</h4>
                                    </div>
                                </div>
                                <div className={stylesProduct.centeredColumns}>
                                    <div className={stylesProduct.strain}>
                                        <h3>BLUEBERRY</h3>
                                    </div>
                                </div>
                                <Row>
                                    <Col md={6}>
                                        <div className={stylesProduct.imageContainer}>
                                            <img src={howtoBuyBlueBerry} className={stylesProduct.image} style={{ height: '80%' }} />
                                        </div>
                                        <p className={stylesProduct.price}>$1600/Lb</p>
                                        <div className={stylesProduct.buttonContainer}>
                                            <Button disabled='true' block variant='success' className={stylesProduct.button} >Description</Button>
                                            <Button disabled='true' block variant='success' className={stylesProduct.button} >Contact Seller</Button>
                                        </div>
                                    </Col>
                                    <Col md={6} className={stylesProduct.secondDiv}>
                                        <div className={stylesProduct.badgeContainer}>
                                            <h3>
                                                <Badge className={stylesProduct.Sativa}>Sativa</Badge>
                                            </h3>
                                            <h3>
                                                <Badge variant='light' className={stylesProduct.otherBadge}>Flower</Badge>
                                            </h3>
                                            <h3>
                                                <Badge variant='light' className={stylesProduct.otherBadge}>Top Shelf</Badge>
                                            </h3>
                                            <h3>
                                                <Badge variant='light' className={stylesProduct.otherBadge}>OKC</Badge>
                                            </h3>
                                        </div>
                                        <div className={stylesProduct.centeredColumns}>
                                            <p className={stylesProduct.productText}>{`% THC: 23`}</p>
                                            <p className={stylesProduct.productText}>{`% TRP: 3`}</p>
                                            <p className={stylesProduct.productText}>{`% CBD: 5`}</p>
                                        </div>
                                        <div className={stylesProduct.centeredColumns}>
                                            <p className={`${stylesProduct.productText} ${stylesProduct.bold}`}>{`Availibility: 41 Lbs`}</p>
                                        </div>
                                        <div className={stylesProduct.centeredColumns}>
                                            <p className={`${stylesProduct.productText} ${stylesProduct.bold}`}>{`Test Results Available`}</p>
                                        </div>
                                        <div className={stylesProduct.centeredColumns}>
                                            <p className={stylesProduct.productText}>Choose your quantity:</p>
                                        </div>
                                        <div>
                                            <div className={stylesQty.quantityContainer}>
                                                <Button disabled='true' variant='success'><Minus width='2.5rem' fill='white' /></Button>
                                                <input disabled='true' type='number' value={'5'} />
                                                <Button disabled='true' variant='success' ><Plus width='2.5rem' fill='white' /></Button>
                                            </div>
                                        </div>
                                        <div className={`${stylesProduct.buttonContainer} ${stylesProduct.topMargin}`}>
                                            <Button block className={stylesProduct.button} variant='success' disabled="true">Add to Cart</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Aux>
                    </Card>
                </CardGroup>
            </Col>
            <br /><br />
            {/* end of product */}

            {/*Begin Shopping Cart */}
            <Card.Text style={{ fontSize: '18px', textAlign: 'center' }}>View, edit, track, and analyze orders from one platform.
                <br />
            </Card.Text>
            <Aux >
                {/* ITEM 1 */}
                <Col xs={10} sm={10} md={10} lg={8} xl={6} style={{ margin: '0 auto', width: '90%'}}>
                    <Col>
                        <Aux>
                            <Card className={stylesShopCart.card}>
                                <Card.Body className={stylesShopCart.cardBody}>
                                    <div className={stylesShopCart.container}>
                                        <div className={stylesShopCart.imageContainer}>
                                            <img src={howtoBuyProdImg2} alt='Failed to load' className={stylesShopCart.image} />
                                        </div>
                                        <div className={stylesShopCart.infoContainer}>
                                            <div className={stylesShopCart.info}>
                                                <p className={stylesShopCart.brand}>KONG</p>
                                                <p className={stylesShopCart.strain}>BLUEBERRY</p>
                                            </div>
                                            <Row className={stylesShopCart.row}>
                                                <Col className={stylesShopCart.column}>
                                                    <p className={stylesShopCart.quantity}>5 Lb(s)</p>
                                                </Col>
                                                <Col className={stylesShopCart.column}>
                                                    <p className={stylesShopCart.price}>$8000</p>
                                                </Col>
                                            </Row>
                                            <div>
                                                <div className={stylesShopCart.buttonContainer}>
                                                    <Button
                                                        variant='success'
                                                        block
                                                        data-quantity='5'
                                                        data-strain='BLUEBERRY'
                                                        data-brand='KONG'
                                                        data-uom='Lb(s)'
                                                        data-price='$8000'
                                                    >Edit</Button>
                                                    <Button
                                                        variant='danger'
                                                        block
                                                        data-strain='BLUEBERRY'
                                                        data-brand='KONG'
                                                    >Delete</Button>
                                                    <Button variant='dark' block >Contact</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Aux>

                        {/* SHOPPING CART ITEM 2 */}
                        <Aux>
                            <Card className={stylesShopCart.card}>
                                <Card.Body className={stylesShopCart.cardBody}>
                                    <div className={stylesShopCart.container}>
                                        <div className={stylesShopCart.imageContainer}>
                                            <img src={howtoBuyProdImg2} alt='Failed to load' className={stylesShopCart.image} />
                                        </div>
                                        <div className={stylesShopCart.infoContainer}>
                                            <div className={stylesShopCart.info}>
                                                <p className={stylesShopCart.brand}>LA PAZ</p>
                                                <p className={stylesShopCart.strain}>SOUR DIESEL</p>
                                            </div>
                                            <Row className={stylesShopCart.row}>
                                                <Col className={stylesShopCart.column}>
                                                    <p className={stylesShopCart.quantity}>5 Lb(s)</p>
                                                </Col>
                                                <Col className={stylesShopCart.column}>
                                                    <p className={stylesShopCart.price}>$8000</p>
                                                </Col>
                                            </Row>
                                            <div>
                                                <div className={stylesShopCart.buttonContainer}>
                                                    <Button
                                                        variant='success'
                                                        block
                                                        data-quantity='6'
                                                        data-strain='SOUR DIESEL'
                                                        data-brand='LA PAZ'
                                                        data-uom='Lb(s)'
                                                        data-price='$15600'
                                                    >Edit</Button>
                                                    <Button
                                                        variant='danger'
                                                        block
                                                        data-strain='SOUR DIESEL'
                                                        data-brand='LA PAZ'
                                                    >Delete</Button>
                                                    <Button variant='dark' block >Contact</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Aux>

                        {/* SHOPPING CART ITEM 3 */}
                        <Aux>
                            <Card className={stylesShopCart.card}>
                                <Card.Body className={stylesShopCart.cardBody}>
                                    <div className={stylesShopCart.container}>
                                        <div className={stylesShopCart.imageContainer}>
                                            <img src={howtoBuyProdImg2} alt='Failed to load' className={stylesShopCart.image} />
                                        </div>
                                        <div className={stylesShopCart.infoContainer}>
                                            <div className={stylesShopCart.info}>
                                                <p className={stylesShopCart.brand}>KONG</p>
                                                <p className={stylesShopCart.strain}>GRAPE APE</p>
                                            </div>
                                            <Row className={stylesShopCart.row}>
                                                <Col className={stylesShopCart.column}>
                                                    <p className={stylesShopCart.quantity}>30 Gram(s)</p>
                                                </Col>
                                                <Col className={stylesShopCart.column}>
                                                    <p className={stylesShopCart.price}>$750</p>
                                                </Col>
                                            </Row>
                                            <div>
                                                <div className={stylesShopCart.buttonContainer}>
                                                    <Button
                                                        variant='success'
                                                        block
                                                        data-quantity='6'
                                                        data-strain='GRAPE APE'
                                                        data-brand='KONG'
                                                        data-uom='Gram(s)'
                                                        data-price='$750'
                                                    >Edit</Button>
                                                    <Button
                                                        variant='danger'
                                                        block
                                                        data-strain='GRAPE APE'
                                                        data-brand='KONG'
                                                    >Delete</Button>
                                                    <Button variant='dark' block >Contact</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Aux>

                        {/* SHOPPING CART ITEM 4 */}
                        <Aux>
                            <Card className={stylesShopCart.card}>
                                <Card.Body className={stylesShopCart.cardBody}>
                                    <div className={stylesShopCart.container}>
                                        <div className={stylesShopCart.imageContainer}>
                                            <img src={howtoBuyProdImg2} alt='Failed to load' className={stylesShopCart.image} />
                                        </div>
                                        <div className={stylesShopCart.infoContainer}>
                                            <div className={stylesShopCart.info}>
                                                <p className={stylesShopCart.brand}>HIGH RISE</p>
                                                <p className={stylesShopCart.strain}>WHITE FIRE</p>
                                            </div>
                                            <Row className={stylesShopCart.row}>
                                                <Col className={stylesShopCart.column}>
                                                    <p className={stylesShopCart.quantity}>30 Gram(s)</p>
                                                </Col>
                                                <Col className={stylesShopCart.column}>
                                                    <p className={stylesShopCart.price}>$750</p>
                                                </Col>
                                            </Row>
                                            <div>
                                                <div className={stylesShopCart.buttonContainer}>
                                                    <Button
                                                        variant='success'
                                                        block
                                                        data-quantity='6'
                                                        data-strain='WHITE FIRE'
                                                        data-brand='HIGH RISE'
                                                        data-uom='Gram(s)'
                                                        data-price='$750'
                                                    >Edit</Button>
                                                    <Button
                                                        variant='danger'
                                                        block
                                                        data-strain='WHITE FIRE'
                                                        data-brand='HIGH RISE'
                                                    >Delete</Button>
                                                    <Button variant='dark' block >Contact</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Aux>
                    </Col>
                    <Col>
                        <Card style={{ textAlign: 'center', margin: '0 auto', width: '100%' }}>
                            <Row>
                                <Card.Body className={stylesShopCart.cardBody}>
                                    <Card.Title style={{ textAlign: 'center', fontSize: '30px', fontBold: 'true' }}>TOTAL: $25,850</Card.Title>
                                    <Button size="md" width="80%" disabled="true" variant="success" type="submit">Submit Order</Button>
                                </Card.Body>
                            </Row>
                        </Card>
                    </Col>
                </Col>
            </Aux>
            <br /><br />

            {/* end shopping cart */}

            {/* BEGIN Submission email */}
            <Card.Text style={{ fontSize: '18px', textAlign: 'center' }}>Receive updates on your personal email.
                <br />
            </Card.Text>

            {/*Submission email start */}
            {/* Submission email ITEM 1 */}
            <CardGroup >
                <Col xs={9} sm={8} md={7} lg={6} xl={5} style={{ margin: '0 auto', width: '100%', borderStyle: 'solid', borderWidth: '1px' }}>
                    <br />
                    <Card.Img variant="left" src={logo} width='40%' />
                    <p></p>
                    <p>Dear Example Dispensary,</p>
                    <p>Your order has been submitted successfully.</p>
                    <Card className={stylesMail.card}>
                        <Card.Body className={stylesMail.cardBody}>
                            <Row className={stylesMail.row}>
                                <Col className={`${stylesMail.column}  ${stylesMail.imageCol}`} md={3} xs={4}>
                                    <div className={stylesMail.imageContainer}>
                                        <img src={howtoBuyProdImg2} alt='Failed to load' className={stylesMail.image} />
                                    </div>
                                </Col>
                                <Col className={stylesMail.column} md={6} xs={8}>
                                    <p className={stylesMail.brand}>KONG</p>
                                    <p className={stylesMail.strain}>BLUEBERRY</p>
                                    {/* <p className={stylesMail.delivery}>Delivery: Yes</p> */}
                                    <div className={`${stylesMail.info} ${stylesMail.showOnMobile}`}>
                                        <div>
                                            <p className={stylesMail.quantity}>Qty:</p>
                                            <p>5 Lb(s)</p>
                                        </div>
                                        <p className={stylesMail.price}>$8000</p>
                                    </div>
                                </Col>
                                <Col md={3} className={`${stylesMail.info} ${stylesMail.hideOnMobile}`}>
                                    <div>
                                        <p className={stylesMail.quantity}>Quantity</p>
                                        <p>5 Lb(s)</p>
                                    </div>
                                    <p className={stylesMail.price}>$8000</p>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                    {/* Submission email ITEM 2 */}
                    <Card className={stylesMail.card}>
                        <Card.Body className={stylesMail.cardBody}>
                            <Row className={stylesMail.row}>
                                <Col className={`${stylesMail.column}  ${stylesMail.imageCol}`} md={3} xs={4}>
                                    <div className={stylesMail.imageContainer}>
                                        <img src={howtoBuyProductImg} alt='Failed to load' className={stylesMail.image} />
                                    </div>
                                </Col>
                                <Col className={stylesMail.column} md={6} xs={8}>
                                    <p className={stylesMail.brand}>LA PAZ</p>
                                    <p className={stylesMail.strain}>SOUR DIESEL</p>
                                    {/* <p className={stylesMail.delivery}>Delivery: Yes</p> */}
                                    <div className={`${stylesMail.info} ${stylesMail.showOnMobile}`}>
                                        <div>
                                            <p className={stylesMail.quantity}>Qty:</p>
                                            <p>6 Lb(s)</p>
                                        </div>
                                        <p className={stylesMail.price}>$15600</p>
                                    </div>
                                </Col>
                                <Col md={3} className={`${stylesMail.info} ${stylesMail.hideOnMobile}`}>
                                    <div>
                                        <p className={stylesMail.quantity}>Quantity</p>
                                        <p>6 Lb(s)</p>
                                    </div>
                                    <p className={stylesMail.price}>$15600</p>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                    {/* Submission email ITEM 3 */}
                    <Card className={stylesMail.card}>
                        <Card.Body className={stylesMail.cardBody}>
                            <Row className={stylesMail.row}>
                                <Col className={`${stylesMail.column}  ${stylesMail.imageCol}`} md={3} xs={4}>
                                    <div className={stylesMail.imageContainer}>
                                        <img src={howtoBuyGrapeApe} alt='Failed to load' className={stylesMail.image} />
                                    </div>
                                </Col>
                                <Col className={stylesMail.column} md={6} xs={8}>
                                    <p className={stylesMail.brand}>KONG</p>
                                    <p className={stylesMail.strain}>GRAPE APE</p>
                                    {/* <p className={stylesMail.delivery}>Delivery: Yes</p> */}
                                    <div className={`${stylesMail.info} ${stylesMail.showOnMobile}`}>
                                        <div>
                                            <p className={stylesMail.quantity}>Qty:</p>
                                            <p>30 Gram(s)</p>
                                        </div>
                                        <p className={stylesMail.price}>$750</p>
                                    </div>
                                </Col>
                                <Col md={3} className={`${stylesMail.info} ${stylesMail.hideOnMobile}`}>
                                    <div>
                                        <p className={stylesMail.quantity}>Quantity</p>
                                        <p>30 Gram(s)</p>
                                    </div>
                                    <p className={stylesMail.price}>$750</p>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                    {/* Submission email ITEM 4 */}
                    <Card className={stylesMail.card}>
                        <Card.Body className={stylesMail.cardBody}>
                            <Row className={stylesMail.row}>
                                <Col className={`${stylesMail.column}  ${stylesMail.imageCol}`} md={3} xs={4}>
                                    <div className={stylesMail.imageContainer}>
                                        <img src={howtoBuyWhiteFire} alt='Failed to load' className={stylesMail.image} />
                                    </div>
                                </Col>
                                <Col className={stylesMail.column} md={6} xs={8}>
                                    <p className={stylesMail.brand}>HIGH RISE</p>
                                    <p className={stylesMail.strain}>WHITE FIRE</p>
                                    {/* <p className={stylesMail.delivery}>Delivery: Yes</p> */}
                                    <div className={`${stylesMail.info} ${stylesMail.showOnMobile}`}>
                                        <div>
                                            <p className={stylesMail.quantity}>Qty:</p>
                                            <p>50 Unit(s)</p>
                                        </div>
                                        <p className={stylesMail.price}>$1500</p>
                                    </div>
                                </Col>
                                <Col md={3} className={`${stylesMail.info} ${stylesMail.hideOnMobile}`}>
                                    <div>
                                        <p className={stylesMail.quantity}>Quantity</p>
                                        <p>50 Unit(s)</p>
                                    </div>
                                    <p className={stylesMail.price}>$1500</p>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card style={{ textAlign: 'center', margin: '0 auto', width: '100%' }}>
                        <Row>
                            <Card.Body className={stylesMail.cardBody}>
                                <Card.Title style={{ textAlign: 'center', fontSize: '30px', fontBold: 'true' }}>TOTAL: $25,850</Card.Title>
                                <Button size="md" width="80%" disabled="true" variant="success" type="submit">View or Manage Order</Button>
                            </Card.Body>
                        </Row>
                    </Card>
                </Col>
            </CardGroup>
            <br /><br />


            {/* End of submission email */}

            {/* My Orders */}

            <Card.Text style={{ fontSize: '18px', textAlign: 'center' }}>Track all orders in one integrated system.
                <br />
            </Card.Text>
            <Col xs={10} sm={10} md={10} lg={10} xl={8} style={{ margin: '0 auto', width: '90%', borderStyle: 'solid', borderWidth: '1px' }}>
                <CardGroup >
                    <Card className={stylesOrders.card}>
                        <Card.Header className={stylesOrders.header}>
                            <Row>
                                <Col lg={3} xs={12} sm={6}>
                                    <div>
                                        <p className={stylesOrders.headerTitle}>Order Placed</p>
                                        <p className={stylesOrders.headerContent}>04/20/2020 4:20 PM</p>
                                    </div>
                                </Col>
                                <Col lg={3} xs={12} sm={6}>
                                    <div>
                                        <p className={stylesOrders.headerTitle}>Total</p>
                                        <p className={stylesOrders.total}>$25,850</p>
                                    </div>
                                </Col>
                                <Col lg={6} xs={12}>
                                    <div>
                                        <p className={stylesOrders.headerTitle}>Order Number</p>
                                        <p className={stylesOrders.orderNumber}>00010245</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row className={stylesOrders.hideAddress}>
                                <Col md={3}>
                                    {/* {`${props.list.listProducts[0][0].buyerBusinessName}`} */}
                                </Col>
                                <Col md={3}>
                                    {/* <a href={generateAddress(true)}>{generateAddress()}</a> */}
                                </Col>
                                <Col md={3}>
                                    {/* <a href={`tel:${props.list.listProducts[0][0].phone}`}>{generatePhone(props.list.listProducts[0][0].phone)}</a> */}
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body className={stylesOrders.body}>
                            <Row>

                                {/* Orders Item One */}
                                <Aux >
                                    <Col lg={9} md={12} className={stylesOrders.productInfo}>
                                        <p className={stylesOrders.owner}>Sold By: SOONER THC</p>
                                        <Row>
                                            <Col md={3} xl={2} className={stylesOrders.imageColumn}>
                                                <div className={stylesOrders.imageContainer}>
                                                    <img src={howtoBuyProdImg2} className={stylesOrders.image} />
                                                </div>
                                            </Col>
                                            <Col md={6} className={stylesOrders.textContainer}>
                                                <div>
                                                    <p className={stylesOrders.owner}>KONG - BLUEBERRY</p>
                                                    <div className={stylesOrders.numberContainer}>
                                                        <p>{'THC: 23%'}</p>
                                                        <p>{'CBD: 5%'}</p>
                                                        <p>{'TRP: 3%'}</p>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={3}>
                                                <Row className={stylesOrders.textContainer}>
                                                    <Col md={12} xs={4} className={stylesOrders.quantity}>
                                                        {`Status: Submitted`}
                                                    </Col>
                                                    <Col md={12} xs={4} className={stylesOrders.quantity}>
                                                        {`Quantity: 5 Lb(s)`}
                                                    </Col>
                                                    <Col md={12} xs={4} className={stylesOrders.price}>
                                                        {`Price: $8,000`}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={3} md={12} className={stylesOrders.controls}>
                                        <Aux>
                                            <Row className={stylesOrders.buttonContainer}>
                                                <Col lg={6} md={4} className={stylesOrders.row}>
                                                    <Button disabled='true' className={stylesOrders.button} variant='danger'>Remove</Button>
                                                </Col>
                                                <Col lg={6} md={4} className={stylesOrders.row}>
                                                    <Button disabled='true' className={stylesOrders.button} variant='dark' >Contact</Button>
                                                </Col>
                                            </Row>
                                        </Aux>
                                    </Col>
                                </Aux>

                                {/* Order Item Two */}
                                <Aux >
                                    <Col lg={9} md={12} className={stylesOrders.productInfo}>
                                        <p className={stylesOrders.owner}>Sold By: MIRACLE FARMS</p>
                                        <Row>
                                            <Col md={3} xl={2} className={stylesOrders.imageColumn}>
                                                <div className={stylesOrders.imageContainer}>
                                                    <img src={howtoBuyProductImg} className={stylesOrders.image} />
                                                </div>
                                            </Col>
                                            <Col md={6} className={stylesOrders.textContainer}>
                                                <div>
                                                    <p className={stylesOrders.owner}>LA PAZ - SOUR DIESEL</p>
                                                    <div className={stylesOrders.numberContainer}>
                                                        <p>{'THC: 20%'}</p>
                                                        <p>{'CBD: 3%'}</p>
                                                        <p>{'TRP: 4%'}</p>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={3}>
                                                <Row className={stylesOrders.textContainer}>
                                                    <Col md={12} xs={4} className={stylesOrders.quantity}>
                                                        {`Status: Submitted`}
                                                    </Col>
                                                    <Col md={12} xs={4} className={stylesOrders.quantity}>
                                                        {`Quantity: 6 Lb(s)`}
                                                    </Col>
                                                    <Col md={12} xs={4} className={stylesOrders.price}>
                                                        {`Price: $15,600`}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={3} md={12} className={stylesOrders.controls}>
                                        <Aux>
                                            <Row className={stylesOrders.buttonContainer}>
                                                <Col lg={6} md={4} className={stylesOrders.row}>
                                                    <Button disabled='true' className={stylesOrders.button} variant='danger'>Remove</Button>
                                                </Col>
                                                <Col lg={6} md={4} className={stylesOrders.row}>
                                                    <Button disabled='true' className={stylesOrders.button} variant='dark' >Contact</Button>
                                                </Col>
                                            </Row>
                                        </Aux>
                                    </Col>
                                </Aux>

                                {/* Order Item 3 */}
                                <Aux >
                                    <Col lg={9} md={12} className={stylesOrders.productInfo}>
                                        <p className={stylesOrders.owner}>Sold By: SOONER THC</p>
                                        <Row>
                                            <Col md={3} xl={2} className={stylesOrders.imageColumn}>
                                                <div className={stylesOrders.imageContainer}>
                                                    <img src={howtoBuyGrapeApe} className={stylesOrders.image} />
                                                </div>
                                            </Col>
                                            <Col md={6} className={stylesOrders.textContainer}>
                                                <div>
                                                    <p className={stylesOrders.owner}>KONG - GRAPE APE</p>
                                                    <div className={stylesOrders.numberContainer}>
                                                        <p>{'THC: 75%'}</p>
                                                        <p>{'CBD: 1%'}</p>
                                                        <p>{'TRP: 7%'}</p>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={3}>
                                                <Row className={stylesOrders.textContainer}>
                                                    <Col md={12} xs={4} className={stylesOrders.quantity}>
                                                        {`Status: Submitted`}
                                                    </Col>
                                                    <Col md={12} xs={4} className={stylesOrders.quantity}>
                                                        {`Quantity: 30 Gram(s)`}
                                                    </Col>
                                                    <Col md={12} xs={4} className={stylesOrders.price}>
                                                        {`Price: $750`}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={3} md={12} className={stylesOrders.controls}>
                                        <Aux>
                                            <Row className={stylesOrders.buttonContainer}>
                                                <Col lg={6} md={4} className={stylesOrders.row}>
                                                    <Button disabled='true' className={stylesOrders.button} variant='danger'>Remove</Button>
                                                </Col>
                                                <Col lg={6} md={4} className={stylesOrders.row}>
                                                    <Button disabled='true' className={stylesOrders.button} variant='dark' >Contact</Button>
                                                </Col>
                                            </Row>
                                        </Aux>
                                    </Col>
                                </Aux>

                                {/* Order Item 4 */}
                                <Aux >
                                    <Col lg={9} md={12} className={stylesOrders.productInfo}>
                                        <p className={stylesOrders.owner}>Sold By: DMB HARVESTS</p>
                                        <Row>
                                            <Col md={3} xl={2} className={stylesOrders.imageColumn}>
                                                <div className={stylesOrders.imageContainer}>
                                                    <img src={howtoBuyWhiteFire} className={stylesOrders.image} />
                                                </div>
                                            </Col>
                                            <Col md={6} className={stylesOrders.textContainer}>
                                                <div>
                                                    <p className={stylesOrders.owner}>HIGH RISE - WHITE FIRE</p>
                                                    <div className={stylesOrders.numberContainer}>
                                                        <p>{'THC: 91%'}</p>
                                                        <p>{'CBD: 3%'}</p>
                                                        <p>{'TRP: 4%'}</p>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={3}>
                                                <Row className={stylesOrders.textContainer}>
                                                    <Col md={12} xs={4} className={stylesOrders.quantity}>
                                                        {`Status: Submitted`}
                                                    </Col>
                                                    <Col md={12} xs={4} className={stylesOrders.quantity}>
                                                        {`Quantity: 50 Gram(s)`}
                                                    </Col>
                                                    <Col md={12} xs={4} className={stylesOrders.price}>
                                                        {`Price: $1,500`}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={3} md={12} className={stylesOrders.controls}>
                                        <Aux>
                                            <Row className={stylesOrders.buttonContainer}>
                                                <Col lg={6} md={4} className={stylesOrders.row}>
                                                    <Button disabled='true' className={stylesOrders.button} variant='danger'>Remove</Button>
                                                </Col>
                                                <Col lg={6} md={4} className={stylesOrders.row}>
                                                    <Button disabled='true' className={stylesOrders.button} variant='dark' >Contact</Button>
                                                </Col>
                                            </Row>
                                        </Aux>
                                    </Col>
                                </Aux>
                            </Row>
                        </Card.Body>
                    </Card>
                </CardGroup>
            </Col>
            {/* end orders */}
            <br /><br />

            {/*accepted email start */}
            <Card.Text style={{ fontSize: '18px', textAlign: 'center' }}>You will also receive an acceptance notification showing the delivery date for your reference.
                <br />
            </Card.Text>
            <CardGroup>
                {/* accepted email ITEM 1 */}
                <Col xs={9} sm={8} md={7} lg={6} xl={5} style={{ margin: '0 auto', width: '100%', borderStyle: 'solid', borderWidth: '1px' }}>
                    <p></p>
                    <Card.Img variant="left" src={logo} width='40%' />
                    <p></p>
                    <p>Dear Example Dispensary,</p>
                    <p>Your order has been accepted.</p>
                    <Card className={stylesMail.card}>
                        <Card.Body className={stylesMail.cardBody}>
                            <Row className={stylesMail.row}>
                                <Col className={`${stylesMail.column}  ${stylesMail.imageCol}`} md={3} xs={4}>
                                    <div className={stylesMail.imageContainer}>
                                        <img src={howtoBuyProdImg2} alt='Failed to load' className={stylesMail.image} />
                                    </div>
                                </Col>
                                <Col className={stylesMail.column} md={6} xs={8}>
                                    <p className={stylesMail.brand}>KONG</p>
                                    <p className={stylesMail.strain}>BLUEBERRY</p>
                                    <p className={stylesMail.delivery}>Delivery Date: 04/25/2020</p>
                                    <div className={`${stylesMail.info} ${stylesMail.showOnMobile}`}>
                                        <div>
                                            <p className={stylesMail.quantity}>Qty:</p>
                                            <p>5 Lb(s)</p>
                                        </div>
                                        <p className={stylesMail.price}>$8000</p>
                                    </div>
                                </Col>
                                <Col md={3} className={`${stylesMail.info} ${stylesMail.hideOnMobile}`}>
                                    <div>
                                        <p className={stylesMail.quantity}>Quantity</p>
                                        <p>5 Lb(s)</p>
                                    </div>
                                    <p className={stylesMail.price}>$8000</p>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                    {/* accepted email ITEM 2 */}
                    <Card className={stylesMail.card}>
                        <Card.Body className={stylesMail.cardBody}>
                            <Row className={stylesMail.row}>
                                <Col className={`${stylesMail.column}  ${stylesMail.imageCol}`} md={3} xs={4}>
                                    <div className={stylesMail.imageContainer}>
                                        <img src={howtoBuyProductImg} alt='Failed to load' className={stylesMail.image} />
                                    </div>
                                </Col>
                                <Col className={stylesMail.column} md={6} xs={8}>
                                    <p className={stylesMail.brand}>LA PAZ</p>
                                    <p className={stylesMail.strain}>SOUR DIESEL</p>
                                    <p className={stylesMail.delivery}>Delivery Date: 04/28/2020</p>
                                    <div className={`${stylesMail.info} ${stylesMail.showOnMobile}`}>
                                        <div>
                                            <p className={stylesMail.quantity}>Qty:</p>
                                            <p>6 Lb(s)</p>
                                        </div>
                                        <p className={stylesMail.price}>$15600</p>
                                    </div>
                                </Col>
                                <Col md={3} className={`${stylesMail.info} ${stylesMail.hideOnMobile}`}>
                                    <div>
                                        <p className={stylesMail.quantity}>Quantity</p>
                                        <p>6 Lb(s)</p>
                                    </div>
                                    <p className={stylesMail.price}>$15600</p>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                    {/* accepted email ITEM 3 */}
                    <Card className={stylesMail.card}>
                        <Card.Body className={stylesMail.cardBody}>
                            <Row className={stylesMail.row}>
                                <Col className={`${stylesMail.column}  ${stylesMail.imageCol}`} md={3} xs={4}>
                                    <div className={stylesMail.imageContainer}>
                                        <img src={howtoBuyGrapeApe} alt='Failed to load' className={stylesMail.image} />
                                    </div>
                                </Col>
                                <Col className={stylesMail.column} md={6} xs={8}>
                                    <p className={stylesMail.brand}>KONG</p>
                                    <p className={stylesMail.strain}>GRAPE APE</p>
                                    <p className={stylesMail.delivery}>Delivery Date: 04/27/2020</p>
                                    <div className={`${stylesMail.info} ${stylesMail.showOnMobile}`}>
                                        <div>
                                            <p className={stylesMail.quantity}>Qty:</p>
                                            <p>30 Gram(s)</p>
                                        </div>
                                        <p className={stylesMail.price}>$750</p>
                                    </div>
                                </Col>
                                <Col md={3} className={`${stylesMail.info} ${stylesMail.hideOnMobile}`}>
                                    <div>
                                        <p className={stylesMail.quantity}>Quantity</p>
                                        <p>30 Gram(s)</p>
                                    </div>
                                    <p className={stylesMail.price}>$750</p>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                    {/* accepted email ITEM 4 */}
                    <Card className={stylesMail.card}>
                        <Card.Body className={stylesMail.cardBody}>
                            <Row className={stylesMail.row}>
                                <Col className={`${stylesMail.column}  ${stylesMail.imageCol}`} md={3} xs={4}>
                                    <div className={stylesMail.imageContainer}>
                                        <img src={howtoBuyWhiteFire} alt='Failed to load' className={stylesMail.image} />
                                    </div>
                                </Col>
                                <Col className={stylesMail.column} md={6} xs={8}>
                                    <p className={stylesMail.brand}>HIGH RISE</p>
                                    <p className={stylesMail.strain}>WHITE FIRE</p>
                                    <p className={stylesMail.delivery}>Delivery Date: 04/26/2020</p>
                                    <div className={`${stylesMail.info} ${stylesMail.showOnMobile}`}>
                                        <div>
                                            <p className={stylesMail.quantity}>Qty:</p>
                                            <p>50 Unit(s)</p>
                                        </div>
                                        <p className={stylesMail.price}>$1500</p>
                                    </div>
                                </Col>
                                <Col md={3} className={`${stylesMail.info} ${stylesMail.hideOnMobile}`}>
                                    <div>
                                        <p className={stylesMail.quantity}>Quantity</p>
                                        <p>50 Unit(s)</p>
                                    </div>
                                    <p className={stylesMail.price}>$1500</p>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card style={{ textAlign: 'center', margin: '0 auto', width: '100%' }}>
                        <Row>
                            <Card.Body className={stylesMail.cardBody}>
                                <Card.Title style={{ textAlign: 'center', fontSize: '30px', fontBold: 'true' }}>TOTAL: $25,850</Card.Title>
                                <Button size="md" width="80%" disabled="true" variant="success" type="submit">View or Manage Order</Button>
                            </Card.Body>
                        </Row>
                    </Card>
                </Col>
            </CardGroup>
            <br /><br />
            {/* End of accepted email */}

            {/* Start Footer */}
            <CardGroup>
                <Card sm={9} md={4} lg={3} style={{ textAlign: 'center' }}>
                    <Card.Header style={{ fontSize: '30px' }}>Simple Benefits Include</Card.Header>
                    <br /><br />
                    <Card.Title>100 % Free to use our site forever with no transaction fees.</Card.Title>
                    <Card.Title>Search all products from multiple growers and processors in the only true open marketplace with a click of a button.</Card.Title>
                    <Card.Title>Send unlimited orders to growers and processors with no transaction or site fees.</Card.Title>
                    <br /><br />
                </Card>
            </CardGroup>
            <br /><br />

            {/* End Footer */}
        </div >
    );
}
export default howToBuy;