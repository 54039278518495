import React from 'react';
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import {withRouter} from 'react-router';

import {ReactComponent as Delivery} from '../../../../assests/images/delivery.svg';

import styles from './ProductListItem.module.css'

const productListItem = (props) => {

  const getOutOfStock = amountAvailable => {
    if (parseInt(amountAvailable, 10) === 0) {
      return (
        <span className={styles.outOfStock}>
          <p className={styles.outOfStockText}>OUT OF STOCK</p>
        </span>
      );
    }
  }
  const getPercent = value => {
    let result = value;
    if (typeof result !== 'number') {
      result = parseFloat(result);
    }
    return `${result}%`;
  }

  const hasDelivery = value => {
    let result = null;
    if (!value) return;
    if (value) {
      result = (
        <span className={styles.delivery}>
          <Delivery width='30px' height='30px'/>
        </span>
      )
    }
    return result;
  }

  const handleClick = (event) => {
    if (props.ismanagelisting) {
      props.history.push(`/manage-listings/${event.target.id}`);
    } else if (props.location.pathname === '/') {
      props.history.push(`/${event.target.id}`);
    }
  }

  const cardClasses = () => {
    if (props.location.pathname === '/new') {
      return `${styles.productCard} ${styles.modalCard}`;
    } else {
      return styles.productCard
    }
  }

  const thcField = () => {
    if (props.Category !== 'Accessories / Other') {
      let value;
      if (props.Category !== 'Edibles') {
        value = getPercent(props.THC);
      }
      else{
        value = parseFloat(props.THC);
      }
      return (
        <div  className={styles.info}>
          <p>THC</p>
          <p>{value}</p>
        </div>
      )
    }
  }

  const cbdField = () => {
    if (props.Category !== 'Accessories / Other') {
      return (
        <div className={styles.info}>
          <p>CBD</p>
          <p>{getPercent(props.CBD)}</p>
        </div>
      )
    }
  }

  const trpField = () => {
    if (props.Category !== 'Accessories / Other') {
      return (
        <div  className={styles.info}>
          <p>TRP</p>
          <p>{getPercent(props.TRP)}</p>
        </div>
      )
    }
  }

  const price = () => {
    if (props.user) {
      return (
        <p>{`$${props.Price.replace('.00', '')} - ${props.Price_UOM}`}</p>
      );
    } else {
      return (
        <p>{`$$$ - ${props.Price_UOM}`}</p>
      );
    }
  }

  const wrapperClasses = () => {
    if (props.location.pathname === '/new') {
      return `${styles.cardWrapper} ${styles.modalCard}`;
    } else {
      return styles.cardWrapper
    }
  }

  return (
    <div className={wrapperClasses()}>
      <Card style={{cursor: 'pointer'}} className={cardClasses()} id={props.id} onClick={handleClick}>
        <div className={styles.noClick}>
          <Card.Body className={styles.header}>
            <div className={`${styles.infoContainer} card-text`}>
              <h5><Badge pill className={styles[props.Subcategory]}>{props.Subcategory}</Badge></h5>
            </div>
          </Card.Body>
          <div className={styles.imageContainer}>
            <Card.Img variant="top" src={props.Product_Image} alt="Problem loading image" className={styles.image}/>
            {getOutOfStock(props.Amount_Available)}
          </div>
          <Card.Body>
            <div className={`${styles.infoContainerCentered} card-text`}>
              {thcField()}
              {cbdField()}
              {trpField()}
            </div>
            <div className={`${styles.infoContainerCenteredGreen} card-text`}>
              {price()}
            </div>
            <p className={styles.brand}>{props.Brand.toUpperCase()}</p>
            <Card.Title className={styles.title}>{props.Strain.toUpperCase()}</Card.Title>
          </Card.Body>
        </div>
      </Card>
    </div>
  )
}

export default withRouter(productListItem);