import Cookies from 'js-cookie';

export default class Users {
  getUser = () => {
    const user = Cookies.get('user');
    if(user !== undefined && user !== 'undefined'){
      return JSON.parse(atob(user));
    }
    return
  }
  
  setUser = (value) => {
    let user = value;
    if(typeof user === 'object'){
      user = JSON.stringify(user);
    }
    Cookies.set('user', btoa(user));
    return(JSON.parse(user));
  }

  logout = () => {
    Cookies.remove('user');
    Cookies.remove('token');
    return;
  }
}