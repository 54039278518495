import React from 'react';
import Container from 'react-bootstrap/Container';
import Cookies from 'js-cookie';
import { withRouter } from 'react-router';
 
import styles from './Under21.module.css';

const under21 = (props) => {
  const ageVerified = Cookies.get('ageVerified');
  if(ageVerified === 'undefined' || ageVerified === 'true' || ageVerified === undefined){
    props.history.push('/');
  }

  return(
    <Container>
      <h1 className={styles.header}>Underage Access Denied</h1>
      <p style={{textAlign: 'center'}}>
        You have indicated that you are under 21, and you access has been denied. If you have reached this page by mistake, feel free to use the <b>Contact Us</b> form to report the issue. Thank you.
      </p>
    </Container>
  )
}

export default withRouter(under21);