import React from 'react';

const privacyPolicy = (props) => {
  return(
    <div>
      <p>
        <b>Privacy Policy</b>
      </p><p>
        This Privacy Policy, effective December 1st, 2019, discloses the privacy practices for
        greenleaftrader.com and applies only to information collected by this website. By using
        greenleaftrader.com, you are agreeing to the terms outlined in this Privacy Policy.
      </p><p>
        <b>Children:</b> greenleaftrader.com is for the exclusive use of those over the age of 21 who have a
        grower’s, processor’s, or dispensary license from the Oklahoma Medical Marijuana Authority.
        We will not under any circumstances knowingly allow anyone under the age of 21 to use our
        website or knowingly collect personal information from anyone under the age of 21. We abide
        by the United States policy as outlined on the Children’s Online Privacy Protection Act and will
        take all efforts to delete any information on our server which personally identifies a child under
        the age of 21 upon receiving notice of the same. If you become aware of any child under the age
        of 21 using greenleaftrader.com, please notify our Privacy Officer immediately.
      </p><p>
        <b>Links:</b> greenleaftrader.com may contain links to other websites over which we have no control.
        We are not responsible for the privacy policies or practices of other websites to which you to
        choose to link from greenleaftrader.com. We encourage you to review the privacy policies of
        those other web sites so you can understand how they collect, use and share your information.
      </p><p>
        <b>Collection and Use of Information:</b> We gather or may gather certain information about your
        use of greenleaftrader.com, such as what areas you visit and what products you access.
        Moreover, there is information about your computer hardware and software that is or may be
        collected by us. This information can include without limitation your IP address, browser type,
        domain names, access times and referring Web site addresses, but is not linked to your personal
        information.
      </p><p>
        We may sometimes afford you the opportunity to provide descriptive, cultural, behavioral,
        preferential and/or life-style information about yourself, but it is solely up to you whether you
        furnish such information. If you do provide such information, you are thereby consenting to the
        use of that information in accordance with the policies and practices described in this Privacy
        Policy. For example, such information may be used for the purpose of determining your potential
        interest in receiving e-mail or other communications about particular products or services.
      </p><p>
        <b>Our Use of your Information:</b> To use greenleaftrader.com, users must first register. This
        registration requires the user to give certain information such as the user’s name, address, email
        address, age, telephone number, and the user’s Oklahoma Medical Marijuana Authority license
        number. This information is used to contact you about the services on greenleaftrader.com in
        which you have expressed interest and to verify your license status from time to time with the
        Oklahoma Medical Marijuana Authority.
      </p><p>
        We use the information we gather on greenleaftrader.com, for the purpose of operating and
        improving greenleaftrader.com, fostering a positive user experience, and delivering the products
        and available on our website.
      </p><p>
        If you have provided consent for us to do so, we may also use the information we gather to
        inform you of other products or services available from us or our affiliated companies.
      </p><p>
        We may use your contact information in order to send you e-mail or other communications
        regarding updates to greenleaftrader.com, to contact you about your opinion of current products
        and services or potential new products and services that may be offered. We may also provide
        additional communications, promotions and information on new cannabis listings which may be
        of interest to you. The nature and frequency of these messages will vary depending upon the
        information we have about you.
      </p><p>
        In addition, you may receive informational newsletters from us relating to topics that may be of
        special interest to you.
      </p><p>
        We may have areas on greenleaftrader.com where you can submit feedback. Any feedback you
        submit in these areas becomes our property, and we can use such feedback (such as reviews) for
        marketing purposes or to contact you for further information.
      </p><p>
        <b>Choices Regarding the Disclosure of Personal Information to Others:</b> We do not disclose
        your personal information to third parties, or your combined personal and demographic
        information or information about your use of greenleaftrader.com (such as the areas you visit or
        the products you access), except as set forth below.
      </p><p style={{paddingLeft:'5rem'}}>    
        We disclose information to third parties if you consent to such disclosure. For
        example, if you indicate that you would like to receive information about the
        listings, products or services of third-parties (including specific cannabis growers,
        processors, or dispensaries ) at the time you register for a greenleaftrader.com
        account, we supply your contact information to third parties such as data
        aggregators or others for the purpose of sending you e-mail or otherwise
        communicating with you. We use data we have about you (such as the interests
        and preferences you have expressed) to determine whether you might be
        interested in the certain products or services of a particular third-party.
      </p><p style={{paddingLeft: '5rem'}}>
        We disclose information to companies and individuals we employ to perform
        functions on our behalf. Examples include hosting our web servers, analyzing
        data, providing marketing assistance, processing credit card payments, and
        providing customer service. These companies and individuals will have access to
        your information as necessary to perform their functions.
      </p><p style={{paddingLeft: '5rem'}}>
        We disclose information if legally required to do so, if requested to do so by a
        governmental entity or if we believe in good faith that such action is necessary to:
        (a) conform to legal requirements or comply with legal process; (b) protect our
        rights or property or our affiliated companies; (c) prevent a crime or protect
        national security; or (d) protect the personal safety of users or the public.
      </p><p style={{paddingLeft: '5rem'}}>
        We disclose and transfer information to a third-party who acquires all or a
        substantial portion of our business, whether such acquisition is by way of merger,
        consolidation or purchase of all or a substantial portion of our assets. In addition,
        in the event we become the subject of a bankruptcy proceeding, whether
        voluntary or involuntary, we or our trustee in bankruptcy may sell, license or
        otherwise dispose of such information in a transaction approved by the
        bankruptcy court. You will be notified of sale of all or a substantial portion of our
        business to a third-party via email or through a prominent notice posted on
        greenleaftrader.com.
      </p><p>
        <b>Other Uses of Information:</b> We also share aggregated anonymous information about visitors to
        greenleaftrader.com with our clients, partners and other third parties so that they can understand
        the kinds of visitors to greenleaftrader.com and how those visitors use greenleaftrader.com.
      </p><p>
        <b>Posting to Public Areas:</b> Please remember that if you post any of your personal information in
        public areas of greenleaftrader.com, such as in online forums or review panels, such information
        may be collected and used by others over whom we have no control. We are not responsible for
        the use made by third parties of information you post or otherwise make available in public areas
        of greenleaftrader.com.
      </p><p>
        <b>Access to and Modification of your Information:</b> You may review, correct, update or change
        your canalistok.com account information at any time. From your greenleaftrader.com account
        you may also opt out of receiving newsletters and certain other information from us.
      </p><p>
        To change your greenleaftrader.com account information, simply log into your
        greenleaftrader.com account, go to your account profile, review your account information and, if
        you wish, edit it with the options provided.
      </p><p>
        We retain indefinitely all the information we gather about you in an effort to make your repeat
        use of greenleaftrader.com more efficient, practical, and relevant. Of course, you can close your
        greenleaftrader.com account at any time, in which event we will remove all your
        greenleaftrader.com account information, except for an archival copy which is not accessible on
        the Internet or any information we are required to retain pursuant to applicable laws.
      </p><p>
        <b>Security of Personal Information:</b> We have implemented commercially reasonable technical
        and organizational measures designed to secure your personal information from accidental loss
        and from unauthorized access, use, alteration or disclosure. However, we cannot guarantee that
        unauthorized third parties will never be able to defeat those measures or use your personal
        information for improper purposes.
      </p><p>
        When you place an order online at greenleaftrader.com, your credit card information is protected
        through the use of encryption, such as the Secure Socket Layer (“SSL”) protocol. SSL makes it
        difficult for your credit card information to be intercepted or stolen while being transmitted. We
        use a service company to process its credit card transactions. For further information on this
        company’s privacy and security practices, please <a href='mailto:marc@greenleaftrader.com'>click here</a>.
      </p><p>
        <b>Cookies:</b> We use “cookies” to help personalize and maximize your online experience and time
        online. A cookie is a text file that is placed on your hard drive by a web page server. Cookies are
        not used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to
        your computer and can only be read by a web server in the domain that issued the cookie to you.
      </p><p>
        One of the primary purposes of cookies is to provide a convenience feature to save you time. The
        purpose of a cookie is to tell the web server that you have returned to a specific page. For
        example, if you personalize greenleaftrader.com pages, or register for services, a cookie helps us
        to recall your specific information (such as user name, password and preferences). Because of
        our use of cookies, we can deliver faster and more accurate results and a more personalized site
        experience. When you return to greenleaftrader.com, the information you previously provided
        can be retrieved, so you can easily use the features that you customized. We also use cookies to
        track click streams and for load balancing.
      </p><p>
        You may have the ability to accept or decline cookies. Most web browsers automatically accept
        cookies, but you can usually modify your browser setting to decline all cookies if you prefer.
        Alternatively, you may be able to modify your browser setting to notify you each time a cookie
        is tendered and permit you to accept or decline cookies on an individual basis. If you choose to
        decline cookies, however, that may hinder performance and negatively impact your experience
        on greenleaftrader.com.
      </p><p>
        <b>Web Beacons:</b> greenleaftrader.com may contain electronic images known as web beacons
        (sometimes called single-pixel gifs) that allow us to count users who have visited those pages
        and to deliver co-branded services. Web beacons are not used to access your personally
        identifiable information; they are a technique we use to compile aggregated statistics about our
        web site usage. Web beacons collect only a limited set of information including a cookie
        number, time and date of a page view, and a description of the page on which the web beacon
        resides.
      </p><p>
        You may not decline web beacons; however, they can be rendered ineffective by declining all
        cookies or modifying your browser setting to notify you each time a cookie is tendered and
        permit you to accept or decline cookies on an individual basis.
      </p><p>
        <b>Changes to Privacy Policy:</b> If we decide to materially change our Privacy Policy, we will post
        those changes through a prominent notice on the website so that you will always know what
        information we gather, how we might use that information, and to whom we will disclose it.
      </p><p>
        If at any time, you have questions or concerns about this Privacy Policy or believe that we have
        not adhered to this Privacy Policy, please feel free to email our Privacy Officer at
        marc@greenleaftrader.com. We will use commercially reasonable efforts to promptly answer your question
        or resolve your problem.
      </p><p>
        Contact Information: If you ever feel that we are not abiding by this Privacy Policy, or if you
        have questions regarding this Privacy Policy, please contact our Privacy Officer immediately at
        marc@greenleaftrader.com.
      </p><p>
        Thank you for using greenleaftrader.com
      </p>
    </div>
  )
}

export default privacyPolicy;