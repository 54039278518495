import React from 'react';
import { Button } from 'react-bootstrap'

import styles from './Quantity.module.css';
import {ReactComponent as Plus} from '../../../assests/images/plus.svg'
import {ReactComponent as Minus} from '../../../assests/images/minus.svg'

const quatity = (props) => {
  const getIncrementStyles = () => {
    return props.hideIncrement ? styles.hideIncrement : '';
  }

  const disableIncrement = () => {
    const amountAvailable = props.max || 0;
    return props.quantity >= amountAvailable;
  }

  const disableDecrement = () => {
    return props.quantity === 0;
  }

  const handleChange = (event) => {
    const amountAvailable = props.max || 0;
    const reqQuantity = parseInt(event.target.value || 0, 10);
    const quantity = reqQuantity <= amountAvailable ? reqQuantity || 0 : amountAvailable;
    props.onChange(quantity);
  }

  return(
    <div className={styles.quantityContainer}>
      <Button onClick={props.decrementQuantity} disabled={disableDecrement()} variant='success'><Minus width='2.5rem' fill='white'/></Button>
      <input type='number' value={props.quantity.toString()} onChange={handleChange}/>
      <Button  onClick={props.incrementQuantity} disabled={disableIncrement()} variant='success' className={getIncrementStyles()}><Plus width='2.5rem' fill='white'/></Button>
    </div>
  )
}

export default quatity;