import React from 'react';
import CardDeck from 'react-bootstrap/CardDeck';
import { Container, Badge, Form, Button, Card, CardGroup, Row, Col, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Chart } from "react-google-charts";
import Aux from '../../hoc/Auxiliary/Auxiliary';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { ReactComponent as Plus } from '../../assests/images/plus.svg'
import { ReactComponent as Minus } from '../../assests/images/minus.svg'
import stylesProduct from './Product.module.css';
import stylesNav from './Navigation.module.css';
import stylesQty from './Quantity.module.css';
import stylesOrders from './OrderItemHistory.module.css';
import stylesSearch from './Search.module.css'
import stylesProductListItm from './ProductListItem.module.css';
import stylesLogin from './Login.module.css';
import stylesPricing from './Pricing.module.css';
import stylesRegister from './Register.module.css';
import stylesDashboard from './Dashboards.module.css';
import { ReactComponent as Delivery } from '../../assests/images/delivery.svg';
import howtoBuyProductImg from '../../assests/images/howToBuyPineappleExpress.jfif';
import howtoBuyProdImg2 from '../../assests/images/howToBuyGranddaddyPurple.jpg';
import howtoBuyWhiteFire from '../../assests/images/howToBuyWhiteFire.jpg';
import howtoBuyGrapeApe from '../../assests/images/howToBuyGrapeApe.jpg';
import howtoBuyBlueBerry from '../../assests/images/howToBuyBlueBerry.jpg';
import logo from '../../assests/images/logo.jpg';
import ReactGA from 'react-ga';

const trackingId = "UA-189846059-1"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);

const howToSell = () => {

    // const startDate = new Date()
    const today = new Date()
    const startDate =  (today.getMonth() + 1) + '/' + today.getDate() + '/' + today.getFullYear()


    return (
        <div>
            <Card style={{ textAlign: 'center' }}>
                <Card.Header style={{ fontSize: '26px', fontWeight: 'bold', backgroundColor: 'white' }}>How It Works</Card.Header>
                <Card.Header style={{ fontSize: '30px', fontWeight: 'bold', color: 'green' }}>SELLER</Card.Header>
                <Card.Body>
                    <Card.Title style={{ fontSize: '20px' }}>UNLIMITED LISTINGS<br />NO CONTRACT</Card.Title>
                    <Card.Text style={{ fontSize: '18px' }}>
                        Green Leaf Trader makes it easier to sell to your current vendors and find new
                        products and or new vendors throughout the state, all in one easy to use format.
                        <br /><br />
                        Once you have decided to become one of our members,
                        just click on Login / Register in the Top Right hand corner of the main menu.
                    </Card.Text>
                </Card.Body>
            </Card>
            <br />

            {/* BEGIN NAV  */}
            <Navbar style={{ margin: '0 auto', width: '92%', borderStyle: 'solid', borderWidth: '1px' }} expand="lg" className={stylesNav.navbar} >
                <Container className={stylesNav.container}>
                    <Navbar.Brand href="/" className={stylesNav.showOnDesktop}>
                        <img src={logo} alt='Cannalist Ok' className={stylesNav.logo} />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" className='custom-toggler' />
                    <Navbar.Collapse id="responsive-navbar-nav" className={`${stylesNav.maxHeight} ${stylesNav.collapse}`}>
                        <Nav className={`ml-auto ${stylesNav.maxHeight}`}>
                            <Nav.Link className={`${stylesNav.link} ${stylesNav.permanent}`}>Products</Nav.Link>
                            <Aux>
                                <Nav.Link className={`${stylesNav.link} ${stylesNav.permanent}`}>Pricing</Nav.Link>
                            </Aux>
                            <Aux>
                                <Nav.Link className={`${stylesNav.link} ${stylesNav.permanent}`}>Orders</Nav.Link>
                            </Aux>
                            <Nav.Link className={`${stylesNav.link} ${stylesNav.permanent}`} >How It Works</Nav.Link>
                            <Nav.Link className={`${stylesNav.link} ${stylesNav.permanent}`}>Contact Us</Nav.Link>
                            <Nav.Link className={`${stylesNav.link} ${stylesNav.permanent}`} >Login / Register</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                    <Navbar.Brand href="/" className={stylesNav.hideOnDesktop}>
                        <img src={logo} alt='Cannalist Ok' className={stylesNav.logo} />
                    </Navbar.Brand>
                </Container>
            </Navbar>
            <Navbar fixed='bottom' className={stylesNav.navbarBottom}>
                <Container style={{ padding: '1rem' }}>
                    <p>Green Leaf Trader, LLC</p>
                </Container>
            </Navbar>
            <br />
            {/* END NAV  */}

            <div>
                <Form className={stylesSearch.searchBarContainer}>
                    <Row className={stylesSearch.searchBar}>
                        <Col sm={8} md={8}>
                            <Form.Group controlId='q' className={stylesSearch.formGroup}>
                                <Form.Control disabled='true' placeholder='Search by brand or strain' />
                            </Form.Group>
                        </Col>
                        <Col sm={4} md={4}>
                            <Button disabled='true' className={stylesSearch.button} variant="success">Filter</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
            {/* end nav and search */}

            {/* BEGIN Main PRODUCTS */}
            <Aux>
                {/* Main Products Item One */}
                <CardDeck style={{ margin: '0 auto', width: '96%', borderStyle: 'solid', borderWidth: '1px' }}>
                    <Card className={stylesProductListItm.productCard}>
                        <div className={stylesProductListItm.noClick}>
                            <Card.Body>
                                <div className={`${stylesProductListItm.infoContainer} card-text`}>
                                    <h5 style={{ margin: 0 }}><Badge pill className={stylesProductListItm.Sativa}>Sativa</Badge></h5>
                                    {/* <span className={stylesProductListItm.delivery}>
                                        <Delivery width='30px' height='30px' />
                                    </span> */}
                                </div>
                            </Card.Body>
                            <div className={stylesProductListItm.imageContainer}>
                                <Card.Img variant="top" src={howtoBuyBlueBerry} className={stylesProductListItm.image} />
                            </div>
                            <Card.Body>
                                <div className={`${stylesProductListItm.infoContainer} card-text`}>
                                    <p>THC: 23%</p>
                                    <p>CBD: 5%</p>
                                </div>
                                <div className={`${stylesProductListItm.infoContainerCentered} card-text`}>
                                    <p>TRP: 3%</p>
                                </div>
                                <div className={`${stylesProductListItm.infoContainerCenteredGreen} card-text`}>
                                    <p>$1600/Lb</p>
                                </div>
                                <p className={stylesProductListItm.brand}>KONG</p>
                                <Card.Title style={{ justifyContent: 'center', textAlign: 'center', fontWeight: '900', display: 'flex' }}>BLUEBERRY</Card.Title>
                            </Card.Body>
                        </div>
                    </Card>

                    {/* Main products item 2 */}
                    <Card className={stylesProductListItm.productCard}>
                        <div className={stylesProductListItm.noClick}>
                            <Card.Body>
                                <div className={`${stylesProductListItm.infoContainer} card-text`}>
                                    <h5 style={{ margin: 0 }}><Badge pill className={stylesProductListItm.Indica}>Indica</Badge></h5>
                                    {/* <span className={stylesProductListItm.delivery}>
                                        <Delivery width='30px' height='30px' />
                                    </span> */}
                                </div>
                            </Card.Body>
                            <div className={stylesProductListItm.imageContainer}>
                                <Card.Img variant="top" src={howtoBuyProductImg} className={stylesProductListItm.image} />
                            </div>
                            <Card.Body>
                                <div className={`${stylesProductListItm.infoContainer} card-text`}>
                                    <p>THC: 20%</p>
                                    <p>CBD: 3%</p>
                                </div>
                                <div className={`${stylesProductListItm.infoContainerCentered} card-text`}>
                                    <p>TRP: 4%</p>
                                </div>
                                <div className={`${stylesProductListItm.infoContainerCenteredGreen} card-text`}>
                                    <p>$2600/Lb</p>
                                </div>
                                <p className={stylesProductListItm.brand}>LA PAZ</p>
                                <Card.Title style={{ justifyContent: 'center', textAlign: 'center', fontWeight: '900', display: 'flex' }}>SOUR DIESEL</Card.Title>
                            </Card.Body>
                        </div>
                    </Card>

                    {/* Main products item 3 */}
                    <Card className={stylesProductListItm.productCard}>
                        <div className={stylesProductListItm.noClick}>
                            <Card.Body>
                                <div className={`${stylesProductListItm.infoContainer} card-text`}>
                                    <h5 style={{ margin: 0 }}><Badge pill className={stylesProductListItm.Sativa}>Sativa</Badge></h5>
                                    {/* <span className={stylesProductListItm.delivery}>
                                        <Delivery width='30px' height='30px' />
                                    </span> */}
                                </div>
                            </Card.Body>
                            <div className={stylesProductListItm.imageContainer}>
                                <Card.Img variant="top" src={howtoBuyGrapeApe} className={stylesProductListItm.image} />
                            </div>
                            <Card.Body>
                                <div className={`${stylesProductListItm.infoContainer} card-text`}>
                                    <p>THC: 75%</p>
                                    <p>CBD: 1%</p>
                                </div>
                                <div className={`${stylesProductListItm.infoContainerCentered} card-text`}>
                                    <p>TRP: 7%</p>
                                </div>
                                <div className={`${stylesProductListItm.infoContainerCenteredGreen} card-text`}>
                                    <p>$25/Gram</p>
                                </div>
                                <p className={stylesProductListItm.brand}>KONG</p>
                                <Card.Title style={{ justifyContent: 'center', textAlign: 'center', fontWeight: '900', display: 'flex' }}>GRAPE APE</Card.Title>
                            </Card.Body>
                        </div>
                    </Card>

                    {/* Main Orders Item 4 */}
                    <Card className={stylesProductListItm.productCard}>
                        <div className={stylesProductListItm.noClick}>
                            <Card.Body>
                                <div className={`${stylesProductListItm.infoContainer} card-text`}>
                                    <h5 style={{ margin: 0 }}><Badge pill className={stylesProductListItm.Hybrid}>Hybrid</Badge></h5>
                                    {/* <span className={stylesProductListItm.delivery}>
                                        <Delivery width='30px' height='30px' />
                                    </span> */}
                                </div>
                            </Card.Body>
                            <div className={stylesProductListItm.imageContainer}>
                                <Card.Img variant="top" src={howtoBuyWhiteFire} className={stylesProductListItm.image} />
                            </div>
                            <Card.Body>
                                <div className={`${stylesProductListItm.infoContainer} card-text`}>
                                    <p>THC: 91%</p>
                                    <p>CBD: 3%</p>
                                </div>
                                <div className={`${stylesProductListItm.infoContainerCentered} card-text`}>
                                    <p>TRP: 4%</p>
                                </div>
                                <div className={`${stylesProductListItm.infoContainerCenteredGreen} card-text`}>
                                    <p>$30/Unit</p>
                                </div>
                                <p className={stylesProductListItm.brand}>HIGH RISE</p>
                                <Card.Title style={{ justifyContent: 'center', textAlign: 'center', fontWeight: '900', display: 'flex' }}>WHITE FIRE</Card.Title>
                            </Card.Body>
                        </div>
                    </Card>
                </CardDeck>
            </Aux>
            {/* END MAIN PRODUCTS/*/}

            <br />

            {/* Begin Login */}

            <CardGroup  >
                <Aux>
                    <br /><br />
                    <Col className={stylesPricing.col}>
                        <p style={{ fontSize: '18px', textAlign: 'center' }}>Click "Register" at the bottom of the Login screen.</p>
                        <div style={{ margin: '0 auto', width: '90%', borderStyle: 'solid', borderWidth: '1px' }}>
                            <Form style={{ margin: '0 auto', width: '88%' }}>
                                <Form.Label style={{ fontSize: '26px' }}>Login</Form.Label>
                                <Form.Group controlId='username'>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type='email' placeholder='Enter email' />
                                </Form.Group>
                                <Form.Group controlId='password'>
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type='password' placeholder='Enter password' />
                                </Form.Group>
                                <div className={stylesLogin.forgotPassword}>
                                    <a href='#forgotpassword' disabled='true' >Forgot password?</a>
                                </div>
                                <div className={stylesLogin.loginButton}>
                                    <Button type='submit' disabled='true'>Login</Button>
                                </div>
                                <div className={stylesLogin.register}>
                                    <p>Don't have an account yet?</p>
                                    <Button variant="primary" disabled='true' active >Register</Button>
                                    <br />
                                </div>
                            </Form>
                        </div>
                    </Col>

                    <Col className={stylesPricing.col} >
                        <p style={{ fontSize: '18px', textAlign: 'center' }}>Click "Sign Up" at the bottom of the Seller section.</p>
                        <Card bg='warning' style={{ margin: '0 auto', width: '90%', borderColor: 'black', borderStyle: 'solid', borderWidth: '1px' }}>
                            <Card.Header className={stylesPricing.cardHeader}><h2>Seller</h2></Card.Header>
                            <Card.Body className={stylesPricing.cardBody}>
                                <ul className={stylesPricing.price}>
                                    <li className={stylesPricing.grey}>100% FREE<br />FOR GROWERS AND PROCESSORS</li>
                                    <div className={stylesPricing.items}>
                                        <li>No Hidden Costs</li>
                                        <li>1 Company per State</li>
                                        <li>2 Brands</li>
                                        <li style={{ backgroundColor: '#ffc107' }} >UNLIMITED LISTINGS</li>
                                        <li>Ability to Sell Products 24/7</li>
                                        <li>No Contract - Cancel Anytime</li>
                                    </div>
                                    <li className={stylesPricing.grey}><Button disabled='true' variant='success' >Sign Up</Button></li>
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>
                </Aux>
            </CardGroup>
            {/* end login */}
            <br /><br />

            {/* begin registration */}
            <Card.Text style={{ fontSize: '18px', textAlign: 'center' }}>Fill out the registration form
            and click "Register" located at the bottom of the form.
            </Card.Text>

            <CardGroup style={{ margin: '0 auto', width: '80%', borderStyle: 'solid', borderWidth: '1px' }}>
                <Card className={stylesRegister.registerCard} >
                    <Card.Body>
                        <Card.Title style={{ textAlign: 'center' }}>Registration</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
                        <Form>
                            <Form.Row>
                                <Col sm={12} md={6} lg={4}>
                                    <Form.Group>
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control disabled='true' placeholder="Bob" id="firstname" />
                                    </Form.Group>
                                </Col>
                                <Col sm={12} md={6} lg={4}>
                                    <Form.Group>
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control disabled='true' placeholder="Smith" id="lastname" />
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group>
                                        <Form.Label>
                                            Business Name
                                        </Form.Label>
                                        <Form.Control disabled='true' placeholder="Business Name" id="business_name" />
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col sm={12} md={4}>
                                    <Form.Group>
                                        <Form.Label>Business Address
                                         </Form.Label>
                                        <Form.Control disabled='true' placeholder="123 maple ave" id="address" />
                                    </Form.Group>
                                </Col>
                                <Col sm={12} md={4} lg={3}>
                                    <Form.Group>
                                        <Form.Label>City</Form.Label>
                                        <Form.Control disabled='true' placeholder="City" id="city" />
                                    </Form.Group>
                                </Col>
                                <Col sm={12} md={2}>
                                    <Form.Group>
                                        <Form.Label>State</Form.Label>
                                        <Form.Control disabled='true' placeholder="State" id="state" />
                                    </Form.Group>
                                </Col>
                                <Col sm={12} md={2} lg={3}>
                                    <Form.Group>
                                        <Form.Label>Zip</Form.Label>
                                        <Form.Control disabled='true' placeholder="Zip" id="zip" />
                                    </Form.Group>
                                </Col>
                                <Col sm={12} md={3} lg={4}>
                                    <Form.Group>
                                        <Form.Label>Phone</Form.Label>
                                        <Form.Control type='tel' disabled='true' placeholder="Phone" id="phone" />
                                    </Form.Group>
                                </Col>
                                <Col sm={12} md={5} lg={4}>
                                    <Form.Group>
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" disabled='true' placeholder="Email" id="email" />
                                    </Form.Group>
                                </Col>
                                <Col sm={12} md={4}>
                                    <Form.Group>
                                        <Form.Label>Area</Form.Label>
                                        <Form.Control as="select" id="area" disabled='true'  >
                                            {/* {generateOptions(props.areas, 'id', 'Name', 'id', true)} */}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col sm={12} md={6} lg={4}>
                                    <Form.Group>
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder="Password"
                                            id="password"
                                            disabled='true'
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={12} md={6} lg={4}>
                                    <Form.Group>
                                        <Form.Label>Re-enter Password</Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder="Re-enter Password"
                                            id="password2"
                                            disabled='true'
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={12} md={6} lg={4}>
                                    <Form.Group>
                                        <Form.Label>Account Type</Form.Label>
                                        <Form.Control as="select" id="accountType" disabled='true'  >
                                            {/* {generateOptions(accountTypes, 'value', 'value', 'value', true)} */}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col sm={12} md={6} lg={4}>
                                    <Form.Group>
                                        <Form.Label>License Number</Form.Label>
                                        <Form.Control placeholder="ABD1234" id="license_number" disabled='true' />
                                    </Form.Group>
                                </Col>
                                <Col sm={12} md={6} lg={4}>
                                    <Form.Group>
                                        <Form.Label>Sales Representative</Form.Label>
                                        <Form.Control as="select" id="salesrep" disabled='true' >
                                            {/* {generateSalespeople()} */}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Check id="confirmed1" disabled='true' label="
                                            I agree to the Terms of Use and Privacy Policy
                                            for Green Leaf Trader LLC." />
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                            <div align="center" className={stylesRegister.registrationButton}>
                                <Button size="lg" disabled='true' variant="success" type="submit">Register</Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </CardGroup>
            {/* end registration */}

            <br />
            <br />
            {/*BEGIN Product Upload  */}
            <Card.Text style={{ fontSize: '18px', textAlign: 'center' }}>Once registered, sell from your current inventory
            and find new buyers throughout the state, all in a straightforward, standardized format.
                <br />
                Upload your products with your specific information to match your brand.
            </Card.Text>
            <CardGroup style={{ margin: '0 auto', width: '80%', borderStyle: 'solid', borderWidth: '1px' }}>
                <CardGroup style={{ margin: '.5rem' }}>
                    <Card>
                        <Row>
                            <Col lg={4}>
                                <Row>
                                    <Col>
                                        <div>
                                            <p style={{ color: 'red', fontSize: '28px', fontWeight: 'bold' }}>WARNING:</p>
                                            <p style={{ fontWeight: 'bold' }}>MISREPRESENTATION OF PRODUCT'S STRENGTH, GRADE, ETC. WILL RESULT IN AN IMMEDIATE AND PERMANENT REMOVAL FROM SITE.</p>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={6} lg={12}>
                                        <div style={{ marginBottom: '1.25rem' }}>
                                            <p style={{ marginBottom: '0.5rem' }}></p>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={6} lg={12}>
                                        <div style={{ marginBottom: '1.25rem' }}>
                                            <p style={{ marginBottom: '0.5rem' }}>Upload Product Photo</p>
                                            <Button type='submit' variant="outline-secondary" disabled='true'>Choose File</Button> No File Chosen
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={8}>
                                <Form>
                                    <Row>
                                        <Col sm={12} md={6} lg={4}>
                                            <Form.Group controlId='Category'>
                                                <Form.Label>Category</Form.Label>
                                                <Form.Control as='select' disabled='true'>
                                                    {/* {selectOptions(props.configs.categories)} */}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={6} lg={4}>
                                            <Form.Group controlId='Subcategory'>
                                                <Form.Label>Subcategory</Form.Label>
                                                <Form.Control as='select' disabled='true'>
                                                    {/* {selectOptions(props.configs.subcategories, 'Category', props.product.Category.id)} */}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col md={12} lg={4}>
                                            <Form.Group controlId='Tag'>
                                                <Form.Label>Product Type</Form.Label>
                                                <Form.Control as='select' disabled='true'>
                                                    {/* {selectOptions(props.configs.tags, 'Subcategory', props.product.Subcategory.id)} */}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} md={6} lg={4}>
                                            <Form.Group controlId="Strain">
                                                <Form.Label>Strain / Product</Form.Label>
                                                <Form.Control disabled='true' />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={6} lg={4}>
                                            <Form.Group controlId='Brand'>
                                                <Form.Label>Brand / Company</Form.Label>
                                                <Form.Control disabled='true' />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <Form.Group controlId='Description'>
                                                <Form.Label>Description</Form.Label>
                                                <Form.Control as='textarea' rows={3} disabled='true' />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group controlId='DeliveryDetails'>
                                                <Form.Label>Delivery Details</Form.Label>
                                                <Form.Control as='textarea' rows={3} disabled='true' />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} md={4}>
                                            <Form.Group controlId='THC'>
                                                <Form.Label>% THC</Form.Label>
                                                <Form.Control type='number' disabled='true' />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={4}>
                                            <Form.Group controlId='TRP'>
                                                <Form.Label>% TRP</Form.Label>
                                                <Form.Control type='number' disabled='true' />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={4}>
                                            <Form.Group controlId='CBD'>
                                                <Form.Label>% CBD</Form.Label>
                                                <Form.Control type='number' disabled='true' />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} md={6}>
                                            <Form.Group controlId='Price'>
                                                <Form.Label>Price</Form.Label>
                                                <Form.Control type='number' disabled='true' />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <Form.Group controlId='Amount_Available'>
                                                <Form.Label>Available</Form.Label>
                                                <Form.Control type='number' disabled='true' />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Aux>
                                            <Col sm={12} md={6}>
                                                <Form.Group controlId='Tested'>
                                                    <Form.Label>Product tested?</Form.Label>
                                                    <Form.Control as='select' disabled='true'></Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <Form.Group controlId='Price_UOM'>
                                                    <Form.Label>Price per</Form.Label>
                                                    <Form.Control as='select' disabled='true'></Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Aux>
                                    </Row>
                                    <Row>
                                        <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Button type='submit' variant='success' disabled='true'>Show Preview</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Card>
                </CardGroup>
            </CardGroup>
            {/* end product upload */}

            <br /><br />

            {/* begin product preview */}
            <Card.Text style={{ fontSize: '18px', textAlign: 'center' }}>
                Preview your upload before submitting live to Green Leaf Trader.
            </Card.Text>
            <Col xs={10} sm={8} md={7} lg={5} xl={3} style={{ margin: '0 auto', width: '90%', borderStyle: 'solid', borderWidth: '1px' }}>
                <CardGroup >
                    <Card>
                        <Card.Header>Product Preview</Card.Header>
                        <Card.Body>
                            <div className={`${stylesProductListItm.infoContainer} card-text`}>
                                <h5 style={{ margin: 0 }}><Badge pill className={stylesProductListItm.Sativa}>Sativa</Badge></h5>
                                <span className={stylesProductListItm.delivery}>
                                    <Delivery width='30px' height='30px' />
                                </span>
                            </div>
                        </Card.Body>
                        <div className={stylesProductListItm.imageContainer}>
                            <Card.Img variant="top" src={howtoBuyBlueBerry} className={stylesProductListItm.image} />
                        </div>
                        <Card.Body>
                            <div className={`${stylesProductListItm.infoContainer} card-text`}>
                                <p>THC: 23%</p>
                                <p>CBD: 5%</p>
                            </div>
                            <div className={`${stylesProductListItm.infoContainerCentered} card-text`}>
                                <p>TRP: 3%</p>
                            </div>
                            <div className={`${stylesProductListItm.infoContainerCenteredGreen} card-text`}>
                                <p>$1600/Lb</p>
                            </div>
                            <p className={stylesProductListItm.brand}>KONG</p>
                            <Card.Title style={{ justifyContent: 'center', textAlign: 'center', fontWeight: '900', display: 'flex' }}>BLUEBERRY</Card.Title>
                        </Card.Body>
                        <Row>
                            <Card.Body>
                                <div align='right'>
                                    <Button variant="success" disabled="true">Submit</Button>{' '}
                                    <Button variant="danger" disabled="true">Back to Edit</Button>{' '}
                                </div>
                            </Card.Body>
                        </Row>
                    </Card>
                </CardGroup>
            </Col>
            {/* end product preview */}

            <br /><br />

            {/* Product Display */}
            <Card.Text style={{ fontSize: '18px', textAlign: 'center' }}>
                Dispensaries can use the marketplace to create orders, request samples,
                and send messages with a few clicks.
            </Card.Text>
            <Col xs={10} sm={10} md={10} lg={10} xl={7} style={{ margin: '0 auto', width: '80%', borderStyle: 'solid', borderWidth: '1px' }}>
                <CardGroup >
                    <Card>
                        <Aux>
                            <Container className={stylesProduct.container}>
                                <div className={stylesProduct.centeredColumns}>
                                    <div className={stylesProduct.brand}>
                                        <h4>KONG</h4>
                                    </div>
                                </div>
                                <div className={stylesProduct.centeredColumns}>
                                    <div className={stylesProduct.strain}>
                                        <h3>BLUEBERRY</h3>
                                    </div>
                                </div>
                                <Row>
                                    <Col md={6}>
                                        <div className={stylesProduct.imageContainer}>
                                            <img src={howtoBuyBlueBerry} className={stylesProduct.image} style={{ height: '80%' }} />
                                        </div>
                                        <p className={stylesProduct.price}>$1600/Lb</p>
                                        <div className={stylesProduct.buttonContainer}>
                                            <Button block disabled='true' variant='success' className={stylesProduct.button} >Description</Button>
                                            <Button block disabled='true' variant='success' className={stylesProduct.button} >Contact Seller</Button>
                                        </div>
                                    </Col>
                                    <Col md={6} className={stylesProduct.secondDiv}>
                                        <div className={stylesProduct.badgeContainer}>
                                            <h3>
                                                <Badge className={stylesProduct.Sativa}>Sativa</Badge>
                                            </h3>
                                            <h3>
                                                <Badge variant='light' className={stylesProduct.otherBadge}>Flower</Badge>
                                            </h3>
                                            <h3>
                                                <Badge variant='light' className={stylesProduct.otherBadge}>Top Shelf</Badge>
                                            </h3>
                                            <h3>
                                                <Badge variant='light' className={stylesProduct.otherBadge}>OKC</Badge>
                                            </h3>
                                        </div>
                                        <div className={stylesProduct.centeredColumns}>
                                            <p className={stylesProduct.productText}>{`% THC: 23`}</p>
                                            <p className={stylesProduct.productText}>{`% TRP: 3`}</p>
                                            <p className={stylesProduct.productText}>{`% CBD: 5`}</p>
                                        </div>
                                        <div className={stylesProduct.centeredColumns}>
                                            <p className={`${stylesProduct.productText} ${stylesProduct.bold}`}>{`Availibility: 41 Lbs`}</p>
                                        </div>
                                        <div className={stylesProduct.centeredColumns}>
                                            <p className={`${stylesProduct.productText} ${stylesProduct.bold}`}>{`Test Results Available`}</p>
                                        </div>
                                        <div className={stylesProduct.centeredColumns}>
                                            <p className={stylesProduct.productText}>Choose your quantity:</p>
                                        </div>
                                        <div>
                                            <div className={stylesQty.quantityContainer}>
                                                <Button disabled='true' variant='success'><Minus width='2.5rem' fill='white' /></Button>
                                                <input disabled='true' type='number' value={'5'} />
                                                <Button disabled='true' variant='success' ><Plus width='2.5rem' fill='white' /></Button>
                                            </div>
                                        </div>
                                        <div className={`${stylesProduct.buttonContainer} ${stylesProduct.topMargin}`}>
                                            <Button block className={stylesProduct.button} variant='success' disabled="true">Add to Cart</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Aux>
                    </Card>
                </CardGroup>
            </Col>
            {/* end of product */}

            <br /><br />

            {/* My Orders */}

            <Card.Text style={{ fontSize: '18px', textAlign: 'center' }}>
                View, edit, and approve orders from one platform from multiple buyers.
            </Card.Text>
            <Col xs={10} sm={10} md={10} lg={10} xl={7} style={{ margin: '0 auto', width: '90%', borderStyle: 'solid', borderWidth: '1px' }}>
                <CardGroup >
                    <CardGroup>
                        <Card className={stylesOrders.card}>
                            <Card.Header className={stylesOrders.header}>
                                <Row>
                                    <Col xs={12} sm={6} lg={3} >
                                        <div>
                                            <p className={stylesOrders.headerTitle}>Order Placed</p>
                                            <p className={stylesOrders.headerContent}>04/20/2020 4:20 PM</p>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} lg={3}>
                                        <div>
                                            <p className={stylesOrders.headerTitle}>Total</p>
                                            <p className={stylesOrders.total}>$23,600</p>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} lg={3}>
                                        <div>
                                            <p className={stylesOrders.headerTitle}>Order Number</p>
                                            <p className={stylesOrders.orderNumber}>00010245</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={3}>
                                        <p className={stylesOrders.headerContent}>Herb N Out</p>
                                    </Col>
                                    <Col md={3}>
                                        <p className={stylesOrders.headerContent}>1422 Sara Rd.</p>
                                    </Col>
                                    <Col md={3}>
                                        <p className={stylesOrders.headerContent}>405-520-9632</p>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body className={stylesOrders.body}>
                                <Row>

                                    {/* Orders Item One */}
                                    <Aux >
                                        <Col lg={9} md={12} className={stylesOrders.productInfo}>
                                            <p className={stylesOrders.owner}>Sold By: SOONER THC</p>
                                            <Row>
                                                <Col md={3} xl={2} className={stylesOrders.imageColumn}>
                                                    <div className={stylesOrders.imageContainer}>
                                                        <img src={howtoBuyProdImg2} className={stylesOrders.image} />
                                                    </div>
                                                </Col>
                                                <Col md={6} className={stylesOrders.textContainer}>
                                                    <div>
                                                        <p className={stylesOrders.owner}>KONG - BLUEBERRY</p>
                                                        <div className={stylesOrders.numberContainer}>
                                                            <p>{'THC: 23%'}</p>
                                                            <p>{'CBD: 5%'}</p>
                                                            <p>{'TRP: 3%'}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <Row className={stylesOrders.textContainer}>
                                                        <Col md={12} xs={4} className={stylesOrders.quantity}>
                                                            {`Status: Submitted`}
                                                        </Col>
                                                        <Col md={12} xs={4} className={stylesOrders.quantity}>
                                                            {`Quantity: 5 Lb(s)`}
                                                        </Col>
                                                        <Col md={12} xs={4} className={stylesOrders.price}>
                                                            {`Price: $8,000`}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg={3} md={12} className={stylesOrders.controls}>
                                            <Aux>
                                                <Row className={stylesOrders.buttonContainer}>
                                                    <Col lg={6} md={4} className={stylesOrders.row}>
                                                        <Button disabled='true' className={stylesOrders.button} variant='primary'>Edit</Button>
                                                    </Col>
                                                    <Col lg={6} md={4} className={stylesOrders.row}>
                                                        <Button disabled='true' className={stylesOrders.button} variant='danger' >Reject</Button>
                                                    </Col>
                                                    <Col className={stylesOrders.row} md={4} lg={12}>
                                                        <Button disabled='true' className={stylesOrders.button} variant='success'>Accept</Button>
                                                    </Col>
                                                </Row>
                                            </Aux>
                                        </Col>
                                    </Aux>

                                    {/* Order Item Two */}
                                    <Aux >
                                        <Col lg={9} md={12} className={stylesOrders.productInfo}>
                                            <Row>
                                                <Col md={3} xl={2} className={stylesOrders.imageColumn}>
                                                    <div className={stylesOrders.imageContainer}>
                                                        <img src={howtoBuyProductImg} className={stylesOrders.image} />
                                                    </div>
                                                </Col>
                                                <Col md={6} className={stylesOrders.textContainer}>
                                                    <div>
                                                        <p className={stylesOrders.owner}>LA PAZ - SOUR DIESEL</p>
                                                        <div className={stylesOrders.numberContainer}>
                                                            <p>{'THC: 20%'}</p>
                                                            <p>{'CBD: 3%'}</p>
                                                            <p>{'TRP: 4%'}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <Row className={stylesOrders.textContainer}>
                                                        <Col md={12} xs={4} className={stylesOrders.quantity}>
                                                            {`Status: Submitted`}
                                                        </Col>
                                                        <Col md={12} xs={4} className={stylesOrders.quantity}>
                                                            {`Quantity: 6 Lb(s)`}
                                                        </Col>
                                                        <Col md={12} xs={4} className={stylesOrders.price}>
                                                            {`Price: $15,600`}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg={3} md={12} className={stylesOrders.controls}>
                                            <Aux>
                                                <Row className={stylesOrders.buttonContainer}>
                                                    <Col lg={6} md={4} className={stylesOrders.row}>
                                                        <Button disabled='true' className={stylesOrders.button} variant='primary'>Edit</Button>
                                                    </Col>
                                                    <Col lg={6} md={4} className={stylesOrders.row}>
                                                        <Button disabled='true' className={stylesOrders.button} variant='danger' >Reject</Button>
                                                    </Col>
                                                    <Col className={stylesOrders.row} md={4} lg={12}>
                                                        <Button disabled='true' className={stylesOrders.button} variant='success'>Accept</Button>
                                                    </Col>
                                                </Row>
                                            </Aux>
                                        </Col>
                                    </Aux>

                                </Row>
                            </Card.Body>
                        </Card>
                    </CardGroup>

                    <CardGroup>
                        <Card className={stylesOrders.card}>
                            <Card.Header className={stylesOrders.header}>
                                <Row>
                                    <Col lg={3} xs={12} sm={6}>
                                        <div>
                                            <p className={stylesOrders.headerTitle}>Order Placed</p>
                                            <p className={stylesOrders.headerContent}>04/21/2020 7:20 PM</p>
                                        </div>
                                    </Col>
                                    <Col lg={3} xs={12} sm={6}>
                                        <div>
                                            <p className={stylesOrders.headerTitle}>Total</p>
                                            <p className={stylesOrders.total}>$2,250</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} xs={12}>
                                        <div>
                                            <p className={stylesOrders.headerTitle}>Order Number</p>
                                            <p className={stylesOrders.orderNumber}>00010280</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={3}>
                                        <p className={stylesOrders.headerContent}>The Green Lily</p>
                                    </Col>
                                    <Col md={3}>
                                        <p className={stylesOrders.headerContent}>8501 Main St.</p>
                                    </Col>
                                    <Col md={3}>
                                        <p className={stylesOrders.headerContent}>405-855-2121</p>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body className={stylesOrders.body}>
                                <Row>

                                    {/* Order Item 3 */}
                                    <Aux >
                                        <Col lg={9} md={12} className={stylesOrders.productInfo}>
                                            <p className={stylesOrders.owner}>Sold By: SOONER THC</p>
                                            <Row>
                                                <Col md={3} xl={2} className={stylesOrders.imageColumn}>
                                                    <div className={stylesOrders.imageContainer}>
                                                        <img src={howtoBuyGrapeApe} className={stylesOrders.image} />
                                                    </div>
                                                </Col>
                                                <Col md={6} className={stylesOrders.textContainer}>
                                                    <div>
                                                        <p className={stylesOrders.owner}>KONG - GRAPE APE</p>
                                                        <div className={stylesOrders.numberContainer}>
                                                            <p>{'THC: 75%'}</p>
                                                            <p>{'CBD: 1%'}</p>
                                                            <p>{'TRP: 7%'}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <Row className={stylesOrders.textContainer}>
                                                        <Col md={12} xs={4} className={stylesOrders.quantity}>
                                                            {`Status: Submitted`}
                                                        </Col>
                                                        <Col md={12} xs={4} className={stylesOrders.quantity}>
                                                            {`Quantity: 30 Gram(s)`}
                                                        </Col>
                                                        <Col md={12} xs={4} className={stylesOrders.price}>
                                                            {`Price: $750`}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg={3} md={12} className={stylesOrders.controls}>
                                            <Aux>
                                                <Row className={stylesOrders.buttonContainer}>
                                                    <Col lg={6} md={4} className={stylesOrders.row}>
                                                        <Button disabled='true' className={stylesOrders.button} variant='primary'>Edit</Button>
                                                    </Col>
                                                    <Col lg={6} md={4} className={stylesOrders.row}>
                                                        <Button disabled='true' className={stylesOrders.button} variant='danger' >Reject</Button>
                                                    </Col>
                                                    <Col className={stylesOrders.row} md={4} lg={12}>
                                                        <Button disabled='true' className={stylesOrders.button} variant='success'>Accept</Button>
                                                    </Col>
                                                </Row>
                                            </Aux>
                                        </Col>
                                    </Aux>

                                    {/* Order Item 4 */}
                                    <Aux >
                                        <Col lg={9} md={12} className={stylesOrders.productInfo}>
                                            <Row>
                                                <Col md={3} xl={2} className={stylesOrders.imageColumn}>
                                                    <div className={stylesOrders.imageContainer}>
                                                        <img src={howtoBuyWhiteFire} className={stylesOrders.image} />
                                                    </div>
                                                </Col>
                                                <Col md={6} className={stylesOrders.textContainer}>
                                                    <div>
                                                        <p className={stylesOrders.owner}>HIGH RISE - WHITE FIRE</p>
                                                        <div className={stylesOrders.numberContainer}>
                                                            <p>{'THC: 91%'}</p>
                                                            <p>{'CBD: 3%'}</p>
                                                            <p>{'TRP: 4%'}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <Row className={stylesOrders.textContainer}>
                                                        <Col md={12} xs={4} className={stylesOrders.quantity}>
                                                            {`Status: Submitted`}
                                                        </Col>
                                                        <Col md={12} xs={4} className={stylesOrders.quantity}>
                                                            {`Quantity: 50 Gram(s)`}
                                                        </Col>
                                                        <Col md={12} xs={4} className={stylesOrders.price}>
                                                            {`Price: $1,500`}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg={3} md={12} className={stylesOrders.controls}>
                                            <Aux>
                                                <Row className={stylesOrders.buttonContainer}>
                                                    <Col lg={6} md={4} className={stylesOrders.row}>
                                                        <Button disabled='true' className={stylesOrders.button} variant='primary'>Edit</Button>
                                                    </Col>
                                                    <Col lg={6} md={4} className={stylesOrders.row}>
                                                        <Button disabled='true' className={stylesOrders.button} variant='danger' >Reject</Button>
                                                    </Col>
                                                    <Col className={stylesOrders.row} md={4} lg={12}>
                                                        <Button disabled='true' className={stylesOrders.button} variant='success'>Accept</Button>
                                                    </Col>
                                                </Row>
                                            </Aux>
                                        </Col>
                                    </Aux>
                                </Row>
                            </Card.Body>
                        </Card>
                    </CardGroup>
                </CardGroup>
            </Col>
            {/* end orders */}
            <br /><br />

            {/* begin delivery */}
            <Card.Text style={{ fontSize: '18px', textAlign: 'center' }}>Once you choose to accept, select your
            delivery date. All dispensaries will receive a notification with the delivery date you selected,
            per product request.
            </Card.Text>
            <Col xs={9} sm={7} md={5} lg={4} xl={3} style={{ margin: '0 auto', width: '90%' }}>
                <CardGroup style={{ borderStyle: 'solid', borderWidth: '1px' }}>
                    <Card.Text style={{ marginLeft: '1rem', fontSize: '22px', font: 'roboto', fontBold: 'true' }}>Accept Item</Card.Text>
                </CardGroup >
                <CardGroup style={{ borderLeft: 'solid', borderRight: 'solid', borderBottom: 'solid', borderWidth: '1px' }}>
                    <Card>
                        <Card.Body>
                            <Col >
                                <div align='center'>
                                    <Card.Text textAlign='center'>When will the product be delivered?</Card.Text>
                                    <Form.Control disabled='true' placeholder="" value={startDate} id="date" />
                                </div>
                            </Col>
                        </Card.Body>
                        <Card.Body>
                            <div align='right'>
                                <Button variant="success" disabled="true">Submit</Button>{' '}
                                <Button variant="danger" disabled="true">Cancel</Button>{' '}
                            </div>
                        </Card.Body>
                    </Card>
                </CardGroup>
            </Col>
            {/* end delivery */}
            <br /><br />

            {/* begin dashboard */}
            <Card.Text style={{ fontSize: '18px', textAlign: 'center' }}>You will be able to view your personal
            dashboard with live data and keep track of your soon to be low stock of products.
            </Card.Text>
            <CardGroup style={{ margin: '0 auto', width: '80%', borderStyle: 'solid', borderWidth: '1px' }}>
                <Container>
                    <div className={stylesDashboard.topContainer}>
                        <div className={stylesDashboard.header}>
                            Dashboard
                    </div>
                    </div>
                    <div className={stylesDashboard.topContainer}>
                        <Aux>
                            <div className={stylesDashboard.tile}>
                                <div >
                                    <img className={stylesDashboard.icon} src="https://image.flaticon.com/icons/svg/1322/1322818.svg" alt='why did we not download this?' />
                                </div>
                                <div className={stylesDashboard.content}>
                                    <div className={stylesDashboard.title}>TOTAL SALES</div>
                                    <div className={stylesDashboard.body}>
                                        {'$736,500'}
                                    </div>
                                    <div className={stylesDashboard.footer}>

                                    </div>
                                </div>
                            </div>
                        </Aux>
                        <Aux>
                            <div className={stylesDashboard.tile}>
                                <div >
                                    <img className={stylesDashboard.icon} src="https://static.thenounproject.com/png/1375593-200.png" />
                                </div>
                                <div className={stylesDashboard.content}>
                                    <div className={stylesDashboard.title}>LISTED PRODUCTS</div>
                                    <div className={stylesDashboard.body}>
                                        {'35'}
                                    </div>
                                    <div className={stylesDashboard.footer}>

                                    </div>
                                </div>
                            </div>
                        </Aux>
                        <Aux>
                            <div className={stylesDashboard.tile}>
                                <div >
                                    <img className={stylesDashboard.icon} src="https://image.flaticon.com/icons/svg/1838/1838266.svg" />
                                </div>
                                <div className={stylesDashboard.content}>
                                    <div className={stylesDashboard.title}>PENDING ORDERS</div>
                                    <div className={stylesDashboard.body}>
                                        {'11'}
                                    </div>
                                    <div className={stylesDashboard.footer}>

                                    </div>
                                </div>
                            </div>
                        </Aux>
                        <Aux>
                            <div className={stylesDashboard.tile}>
                                <div >
                                    <img className={stylesDashboard.icon} src="https://image.flaticon.com/icons/svg/2521/2521536.svg" />
                                </div>
                                <div className={stylesDashboard.content}>
                                    <div className={stylesDashboard.title}>COMPLETED ORDERS</div>
                                    <div className={stylesDashboard.body}>
                                        {'125'}
                                    </div>
                                    <div className={stylesDashboard.footer}>

                                    </div>
                                </div>
                            </div>
                        </Aux>
                    </div>
                    <div className={stylesDashboard.topContainer}>
                        <div className={stylesDashboard.chartContainer}>
                            <div className={stylesDashboard.title}>SOLD PRODUCTS BY CATEGORY</div>
                            <div>
                                <Chart
                                    width={'200'}
                                    height={'200'}
                                    chartType="PieChart"
                                    loader={<div>Loading Chart</div>}
                                    data={[
                                        ['Category', 'Percent'],
                                        ['Flower', 11],
                                        ['Edibles', 7],
                                        ['Concentrates', 10],
                                        ['Clones', 5],
                                        ['Crop', 7],
                                    ]}
                                    rootProps={{ 'data-testid': '1' }}
                                />
                            </div>
                        </div>
                        <div className={stylesDashboard.chartContainer}>
                            <div className={stylesDashboard.title}>SOLD PRODUCTS BY SUBCATEGORY</div>
                            <div>
                                <Chart
                                    width={'200'}
                                    height={'200'}
                                    chartType="PieChart"
                                    loader={<div>Loading Chart</div>}
                                    data={[
                                        ['Subcategory', 'Percent'],
                                        ['Sativa', 25],
                                        ['Indica', 21],
                                        ['Hybrid', 19],
                                    ]}
                                    rootProps={{ 'data-testid': '1' }}
                                />
                            </div>
                        </div>
                        <div className={stylesDashboard.chartContainer}>
                            <div className={stylesDashboard.title}>SOLD PRODUCTS BY TYPE</div>
                            <div>
                                <Chart
                                    width={'200'}
                                    height={'200'}
                                    chartType="PieChart"
                                    loader={<div>Loading Chart</div>}
                                    data={[
                                        ['Type', 'Percent'],
                                        ['Top Shelf', 80],
                                        ['Rockwool', 21],
                                        ['Mid Grade', 19],
                                        ['Low Grade', 19],
                                    ]}
                                    rootProps={{ 'data-testid': '1' }}
                                />
                            </div>
                        </div>
                        <Aux>
                            <div className={stylesDashboard.chartContainer}>
                                <div className={stylesDashboard.chartTitle}>PRODUCTS WITH LOW STOCK</div>
                                <Container>
                                    <Row >
                                        <Col style={{ color: 'white', backgroundColor: 'black' }}>Brand</Col>
                                        <Col style={{ color: 'white', backgroundColor: 'black' }}>Product</Col>
                                        <Col style={{ color: 'white', backgroundColor: 'black' }}>Available Amount</Col>
                                        <Col style={{ color: 'white', backgroundColor: 'black' }}>Action</Col>
                                    </Row>
                                    <Row>
                                        <Col style={{ backgroundColor: 'lightgray' }}>KONG</Col>
                                        <Col style={{ backgroundColor: 'lightgray' }}>Grape Ape</Col>
                                        <Col style={{ backgroundColor: 'lightgray' }}>12</Col>
                                        <Col style={{ backgroundColor: 'lightgray', color: 'blue' }}>Manage</Col>
                                    </Row>
                                    <Row>
                                        <Col>HIGH RISE</Col>
                                        <Col>Green Crack</Col>
                                        <Col>5</Col>
                                        <Col style={{ color: 'blue' }}>Manage</Col>
                                    </Row>
                                    <Row>
                                        <Col style={{ backgroundColor: 'lightgray' }}>LA PAZ</Col>
                                        <Col style={{ backgroundColor: 'lightgray' }}>White Fire</Col>
                                        <Col style={{ backgroundColor: 'lightgray' }}>3</Col>
                                        <Col style={{ backgroundColor: 'lightgray', color: 'blue' }}>Manage</Col>
                                    </Row>
                                </Container>
                            </div>
                        </Aux>
                    </div>
                    <br /> <br />
                </Container>
                <br /> <br />
            </CardGroup>
            {/* end dashboard */}
            <br /> <br />
            <br /> <br />
        </div >

    );

}
export default howToSell;