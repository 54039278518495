import React from 'react';

const termsOfUse = (props) => {
  return(
    <div>
      <p><b>Terms of Use</b></p>
      <p>
        The following are the Terms of Use for greenleaftrader.com, which may be amended at any time
        without further notice. You may access the current Terms of Use at any time by visiting
        greenleaftrader.com. Each time you access greenleaftrader.com, you are agreeing to be bound by
        the Terms of Use. <b>The Terms of Use affect your legal rights and obligations. Do not use or
        access greenleaftrader.com if you do not agree to the Terms of Use described below. Your
        use of greenleaftrader.com means you agree to the Terms of Use as outlined herein.</b>
      </p><p>
        Only those who are 21 years of age or older and have a current grower, processor, or dispensary
        license issued by the Oklahoma Medical Marijuana Authority have permission to access or use
        greenleaftrader.com.
      </p><p>
        “We,” “our,” and “us” as used herein means greenleaftrader.com and its legal representatives,
        successors, assigns, employees, and agents. “You” and “your” as used herein means any user of
        greenleaftrader.com and his or her agents, representatives, heirs, executors, administrators,
        successors, and assigns. The “Parties” as used herein means you and greenleaftrader.com
        collectively.
      </p><p>
        Your violation of any of the terms outlined herein may result in the termination of your account
        and the indefinite revocation of your limited license to use greenleaftrader.com and all services
        related thereto at our sole discretion.
      </p><p>
        <b>Eligibility:</b> You must be 21 years of age or older and must be the current holder of a grower,
        processor, or dispensary license issued by the Oklahoma Medical Marijuana Authority to visit or
        use greenleaftrader.com in any manner. By visiting greenleaftrader.com, you represent and
        warrant to greenleaftrader.com that you have reached the age of 21, that you are the current
        holder of a grower, processor, or dispensary license issued by the Oklahoma Medical Marijuana
        Authority, and that you have the right, authority, and capacity to agree to and abide by these
        Terms of Use. You also represent and warrant to greenleaftrader.com that you will use the
        website in a manner consistent with any and all applicable laws and regulations.
      </p><p>
        <b>Representations and Warranties:</b> In addition to other representations, warranties, and
        covenants provided herein, the Parties represent, warrant, and covenant as follows:
      </p><div>
        <ol>
          <li>These Terms of Use constitute a legal, valid, and binding obligation of both Parties,
          enforceable against such party in accordance with the Terms of Use; and</li>
          <li>These Terms of Use do not contravene, violate, or conflict with any other agreement of
          either Party.</li>
        </ol>
      </div><p>
        <b>Use of Our Content:</b> greenleaftrader.com authorizes you to view and access a single copy of the
        content available on or from greenleaftrader.com solely for your personal and commercial use.
        The contents of greenleaftrader.com such as text, graphics, images, logos, button icons, software
        and other content, are protected under both United States and foreign copyright, trademark and
        other laws. The contents of greenleaftrader.com are the property of greenleaftrader.com or its
        content suppliers or clients. The compilation (meaning the collection, arrangement, and
        assembly) of all content on greenleaftrader.com is the exclusive property of greenleaftrader.com
        and is protected by United States and foreign copyright, trademark, and other laws. Unauthorized
        use of the content on greenleaftrader.com may violate these laws and is strictly prohibited. You
        must retain all copyright, trademark, service marks, and other proprietary notices contained in
        original greenleaftrader.com content on any authorized copy you make of the content on
        greenleaftrader.com.
      </p><p>
        You agree not to sell or modify any content on greenleaftrader.com or reproduce, display,
        publicly perform, distribute, or otherwise use such content in any way for any public or
        commercial purpose, in connection with products or services that are not those of
        greenleaftrader.com, in any other manner that is likely to cause confusion among consumers, that
        disparages or discredits greenleaftrader.com or its licensors, that dilutes the strength of
        greenleaftrader.com or its licensor’s property, or that otherwise infringes with
        greenleaftrader.com or its licensor’s intellectual property rights. You further agree to in no other
        way misuse content on greenleaftrader.com. The use of the content on greenleaftrader.com on
        any other website or in a networked computer environment for any purpose is prohibited. Any
        code that greenleaftrader.com creates to generate or display any content is also protected by our
        copyright and you may not copy or adapt such code.
      </p><p>
        <b>Site Restrictions:</b> You may not use greenleaftrader.com in order to transmit, post, distribute,
        store or destroy material, including without limitation, any content on greenleaftrader.com, (a) in
        violation of any applicable law or regulation, (b) in a manner that will infringe the copyright,
        trademark, trade secret or other intellectual property rights of others or violate the privacy,
        publicity or other personal rights of others, or (c) that is defamatory, obscene, threatening,
        abusive or hateful.
      </p><p>
        You are also prohibited from violating or attempting to violate the security of
        greenleaftrader.com, including without limitation, the following activities: (a) accessing data not
        intended for you or logging into a server or account which you are not authorized to access; (b)
        attempting to probe, scan or test the vulnerability of a system or network or to breach security or
        authentication measures without proper authorization; (c) attempting to interfere with service to
        any user, host or network, including, without limitation, via means of submitting a virus to
        greenleaftrader.com, overloading, “flooding”, “spamming”, “mailbombing” or “crashing”; or (d)
        forging any TCP/IP packet header or any part of the header information in any e-mail or
        newsgroup posting (i.e. packet injecting). Violations of system or network security may result in
        civil and/or criminal liability. We will investigate occurrences which may involve such
        violations and may involve, and cooperate with, law enforcement authorities in prosecuting any
        use who is involved in such violations.
      </p><p>
        In order to ensure a safe and effective experience for all of our customers, we reserve the right to
        limit the amount of data that may be accessed by you in any given time period. These limits may
        be amended at our sole discretion from time to time.
      </p><p>
        <b>Registration Information:</b> When you register with greenleaftrader.com, you will be asked to
        create an account and provide us with certain information including, without limitation, your
        name, age, address, email address, and your license number from the Oklahoma Medical
        Marijuana Authority (your “Information”). Information you submit will be used in accordance
        with our Privacy Policy.
      </p><p>
        We reserve the right to offer third party services and products to you based on the preferences
        that you identify in your registration and at any time thereafter; such offers may be made by
        greenleaftrader.com or by third parties. Please see greenleaftrader.com’s Privacy Statement, the
        terms of which are incorporated herein by reference, for further details regarding your
        Information.
      </p><p>
        Without limiting any of the other disclaimers of warranty set forth in these Term of Uses,
        greenleaftrader.com does not provide or make any representation as to the quality or nature of
        any of the third-party products or services purchased through greenleaftrader.com, or any other
        representation, warranty or guaranty. Any such undertaking, representation, warranty or
        guarantee would be furnished solely by the provider of such third-party products or services,
        under the terms agreed to by the provider.
      </p><p>
        You understand and acknowledge that you have no ownership rights in your greenleaftrader.com
        account, and that if you cancel your greenleaftrader.com account, all your account information
        from in our possession, will be marked as deleted in our databases and will be removed from any
        public area of greenleaftrader.com, to the extent allowed by applicable laws. Information may
        continue to be available for some period of time because of delays in propagating such deletion
        through greenleaftrader.com’s web servers. In addition, third-parties may retain cached copies of
        your Information.
      </p><p>
        By creating a greenleaftrader.com account, you also consent to receive electronic
        communications from us (e.g., via email, SMS or by posting notices to greenleaftrader.com).
        You may unsubscribe from receiving such notifications at any time by notifying us or by
        modifying the preferences and settings available from your greenleaftrader.com account page.
        These communications may include notices about your account (e.g., payment authorizations,
        password changes and other transactional information) and are part of your relationship with
        cannalsitok.com. You agree that any notices, agreements, disclosures or other communications
        that we send to you electronically will satisfy any legal communication requirements, including,
        but not limited to, that such communications be in writing. You should maintain copies of
        electronic communications from us by printing a paper copy or saving an electronic copy. We
        may also send you promotional communications via email, including, but not limited to,
        newsletters, special offers, surveys and other news and information we think will be of interest to
        you. You may opt out of receiving these promotional emails at any time by following the
        unsubscribe instructions provided therein.
      </p><p>
        <b>Registration and Password:</b> You are responsible for maintaining the confidentiality of your
        greenleaftrader.com account and passwords, as applicable. You may not share your password or
        other account access information with any other party, temporarily or permanently, and you shall
        be responsible for all uses of your greenleaftrader.com registrations and passwords, whether or
        not authorized by you. You agree to immediately notify greenleaftrader.com of any unauthorized
        use of your account or passwords, as the case may be.
      </p><p>
        <b>User Content:</b> You are solely responsible for your greenleaftrader.com account information,
        content (including, without limitation works in any media or technology whether now known or
        later developed), reviews, messages, audio, video, photos, text, images, compilations or other
        information (“User Content”) that you post on greenleaftrader.com or transmit to other users.
        You agree that in submitting User Content or otherwise using greenleaftrader.com that you will
        not impersonate any person or business, or submit any materials to greenleaftrader.com that are
        false, inaccurate, misleading, unlawful, or are otherwise in violation of your obligations under
        these Terms of Use.
      </p><p>
        By providing User Content to greenleaftrader.com, you automatically grant us an irrevocable,
        royalty-free, perpetual, fully paid non-exclusive right (including moral rights) and worldwide
        license to use, copy, reproduce, modify, adapt, publish, translate, communicate to the public,
        perform, display, and distribute such User Content (in whole or in part) and to prepare derivative
        works of, or incorporate into other works (in any form, media or technology now known or later
        developed, for the full term of any rights that may exist in such content) such User Content, and
        to grant and authorize sublicenses thereof (through multiple tiers). Notwithstanding anything
        herein, greenleaftrader.com will not share or distribute to third-parties any personal information.
      </p><p>
        You also represent and warrant that you have the right to grant or that the holder of any rights,
        including moral rights in such content, has completely and effectively waived all such rights and
        validly and irrevocably granted to you the right to grant, the license stated above. If you post
        User Content in any public area of greenleaftrader.com, you also permit any user to access,
        display, view, store and reproduce such User Content for personal use. Subject to the foregoing,
        the owner of such User Content placed on greenleaftrader.com retains any and all rights that may
        exist in such User Content.
      </p><p>
        We act as a passive conduit for the online distribution and publication of User Content and have
        no obligation to screen User Content, communications or information in advance and are not
        responsible for screening or monitoring User Content posted by any user. However, we may
        review and remove any User Content that, in our sole judgment, violates these Terms of Use,
        violates applicable laws, rules or regulations, is abusive, disruptive, offensive or illegal, or
        violates the rights of, or harms or threatens the safety of, other users of greenleaftrader.com. We
        reserve the right to expel users and prevent their further access to greenleaftrader.com for
        violating these Terms of Use or applicable laws, rules or regulations. We may take any action
        with respect to User Content that we deem necessary or appropriate in our sole discretion if we
        believe that such User Content could create liability for us, damage our brand or public image, or
        cause us to lose (in whole or in part) the services of our ISPs or other suppliers.
      </p><p>
        We do not represent or guarantee the truthfulness, accuracy, or reliability of User Content or any
        other communications posted by users or endorse any opinions expressed by users. You
        acknowledge that any reliance on material posted by other users will be at your own risk.
      </p><p>
        <b>Third Party Content:</b> greenleaftrader.com may display content, advertisements and promotions
        from third parties through greenleaftrader.com (“Third-Party Content”). We do not control,
        endorse or adopt any Third-Party Content, and we make no representations or warranties of any
        kind regarding such Third-Party Content, including, without limitation, regarding its accuracy or
        completeness. You acknowledge and agree that your interactions with third-parties providing
        Third-Party Content are solely between you and such third-parties, and that we are not
        responsible or liable in any manner for such interactions or Third-Party Content.
      </p><p>
        <b>Links to Other Sites:</b> greenleaftrader.com may contain links to third-party websites. These links
        are provided solely as a convenience to you and not as an endorsement by greenleaftrader.com of
        the contents on such third-party websites. We are not responsible for the content of linked third-
        party sites and do not make any representations regarding the content or accuracy of materials on
        such third-party websites. If you decide to access linked third-party websites, you do so at your
        own risk.
      </p><p>
        <b>Authorization to Monitor License:</b> By using greenleaftrader.com, you explicitly authorize us to
        use your Information to periodically verify your grower, processor, or dispensary license issued
        by the Oklahoma Medical Marijuana Authority at our sole discretion without first notifying you
        of the same.
      </p><p>
        <b>Certain Disclaimers and Limitation of Liability:</b> Except as specifically provided in these
        Terms of Use, we do not make, and expressly disclaim, any representations or warranties in
        connection with these Terms of Use or your use of greenleaftrader.com, whether express,
        implied, statutory or otherwise, including without limitation, non-infringement of third-party
        rights, title, any warranties arising out of a course of performance, dealing or trade usage, and
        their equivalents under the laws of the State of Oklahoma.
      </p><p>
        WE ARE NOT LIABLE FOR ANY LOSS OR DAMAGE ARISING DIRECTLY OR
        INDIRECTLY FROM YOUR ACCESS TO AND/OR USE OF greenleaftrader.com
        (INCLUDING, WITHOUT LIMITATION, INCIDENTAL AND CONSEQUENTIAL
        DAMAGES, LOST PROFITS, OR DAMAGES RESULTING FROM LOST DATA OR
        BUSINESS INTERRUPTION) WHETHER BASED ON WARRANTY, CONTRACT, TORT,
        OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT greenleaftrader.com IS
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOU AGREE THAT NEITHER
        greenleaftrader.com NOR ITS AFFILIATES, SUPPLIERS, EMPLOYEES, OFFICERS OR
        OWNERS SHALL BE LIABLE FOR ANY LOSS OF DATA OR PRIVACY, LOSS OF
        INCOME, LOSS OF OPPORTUNITY OR PROFITS, COST OF RECOVERY, LOSS,
        HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, ARISING FROM YOUR
        USE OF greenleaftrader.com OR ANY OTHER SERVICE DELIVERED HEREUNDER, OR
        DAMAGE ARISING FROM YOUR USE OF THIRD-PARTY PRODUCTS, OR ANY
        SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR INDIRECT DAMAGES ARISING OUT
        OF OR IN CONNECTION WITH THESE TERMS OF USE, OR YOUR EXPORTATION,
        REEXPORTATION, OR IMPORTATION OF ANY OR ALL OF THE SAME. THIS
        LIMITATION WILL APPLY EVEN IF greenleaftrader.com, ITS AFFILIATES, SUPPLIERS,
        EMPLOYEES, OFFICERS OR OWNERS HAVE BEEN ADVISED OF THE POSSIBILITY
        OF SUCH DAMAGES, AND THESE LIMITATIONS WILL APPLY NOTWITHSTANDING
        ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY PROVIDED
        HEREIN. IN NO EVENT SHALL THE TOTAL AGGREGATE LIABILITY OF
        greenleaftrader.com, ITS AFFILIATES, SUPPLIERS, EMPLOYEES, OFFICERS OR
        OWNERS EXCEED THE AMOUNT OF FEES YOU HAVE PAID TO greenleaftrader.com.
        YOU ACKNOWLEDGE THAT SUCH CONSIDERATION REFLECTS THESE
        ALLOCATIONS OF RISK. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION
        OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES,
        SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY.
      </p><p>
        For purposes of these Terms of Use, the term “Affiliate” shall mean any entity that controls, is
        under common control with, or is controlled by greenleaftrader.com, where “control” means the
        ownership, direct or indirect, of a majority of greenleaftrader.com’s membership interests or
        other interest entitled allowing the owner to direct the affairs of greenleaftrader.com.
      </p><p>
        In addition, note that there are risks, including but not limited to the risk of physical harm, of
        dealing with strangers, underage persons or people acting under false pretenses. You assume all
        risks associated with dealing with other users with whom you come in contact through
        greenleaftrader.com. By its very nature, other people’s information may be offensive, harmful or
        inaccurate, and in some cases will be mislabeled or deceptively labeled. We expect that you will
        use caution and common sense when using greenleaftrader.com.
      </p><p>
        Because user authentication on the internet is difficult, we cannot and do not confirm that each
        user is who they claim to be. Because we do not and cannot control the behavior of participants
        on greenleaftrader.com, in the event that you have a dispute with one or more users, you release
        greenleaftrader.com (and our agents and employees) from claims, demands and damages (actual
        and consequential and direct and indirect) of every kind and nature, known and unknown,
        suspected and unsuspected, disclosed and undisclosed, arising out of or in any way connected
        with such disputes.
      </p><p>
        greenleaftrader.com may contain inaccuracies or typographical errors. We make no
        representations about the accuracy, reliability, completeness, or timeliness of
        greenleaftrader.com or its content. The use of greenleaftrader.com and the content therein is at
        your own risk. Changes are periodically made to greenleaftrader.com and may be made at any
        time. You acknowledge and agree that you are solely responsible for the form, content and
        accuracy of any material placed by you on greenleaftrader.com.
      </p><p>
        We cannot guarantee and do not promise any specific results from use of greenleaftrader.com.
        No advice or information, whether oral or written, obtained by a user through or from
        greenleaftrader.com shall create any warranty not expressly stated herein.
      </p><p>
        <b>Disclaimer of Warranty:</b> WE DO NOT WARRANT THAT greenleaftrader.com WILL
        OPERATE ERROR-FREE OR THAT greenleaftrader.com AND ITS SERVERS ARE FREE OF
        COMPUTER VIRUSES OR OTHER HARMFUL MECHANISMS. IF YOUR USE OF
        greenleaftrader.com OR THE CONTENT THERON RESULTS IN THE NEED FOR
        SERVICING OR REPLACING EQUIPMENT OR DATA, WE ARE NOT RESPONSIBLE
        FOR THOSE COSTS. greenleaftrader.com AND THE CONTENT THEREON ARE
        PROVIDED ON AN “AS IS” BASIS WITHOUT ANY WARRANTIES OF ANY KIND.
        greenleaftrader.com, TO THE FULLEST EXTENT PERMITTED BY LAW, DISCLAIMS ALL
        WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING THE WARRANTY OF
        MERCHANTABILITY, FITNESS FOR PARTICULAR PURPOSE AND NON-
        INFRINGEMENT. WE MAKE NO WARRANTIES ABOUT THE ACCURACY,
        RELIABILITY, COMPLETENESS, OR TIMELINESS OF THE CONTENT ON
        greenleaftrader.com, SERVICES, SOFTWARE, TEXT, GRAPHICS, AND LINKS THEREON.
      </p><p>
        <b>Indemnity:</b> You agree to indemnify, defend and hold harmless greenleaftrader.com and its
        directors, officers, employees, agents or assigns (collectively the “greenleaftrader.com Parties”)
        from and against all liabilities, claims, damages, fines, losses and expenses, including, but not
        limited to, court costs and reasonable attorneys’ fees (collectively “Damages”) as a result of (a)
        your breach of these Terms of Use; (b) your violation of applicable law; or (c) a dispute between
        you and any other user of greenleaftrader.com except to the extent that Damages were caused
        directly by acts or omissions of any greenleaftrader.com Party.
      </p><p>
        You also agree to defend, indemnify, and hold harmless greenleaftrader.com, its affiliates, and
        their respective officers, directors, employees and agents, from and against any claims, actions or
        demands, including without limitation reasonable legal and accounting fees, alleging or resulting
        from (a) any User Content or other material you provide to greenleaftrader.com; or (b) your use
        of any content thereon.
      </p><p>
        If we are entitled to a defense hereunder, we agree to notify you in writing within a reasonable
        time, but not more than ten (10) days, after receiving notice of a claim. If you defend any such
        claim, we (a) must allow the you to control the defense and settlement of the claim, (b) must
        cooperate with the defense and settlement as you may reasonably request, such as furnishing
        records, information and testimony, and (c) may employ attorneys separate from the attorneys
        employed by you in order to monitor and advise us about the matter, but we will bear all costs of
        those attorneys. If you do not assume the defense, we retain the right to employ attorneys and to
        control any such action, and the reasonable fees and expenses of such attorneys shall be at your
        expense.
      </p><p>
        Except with our prior written consent, you may not (a) admit wrongdoing, fault or liability on
        our behalf, (b) consent to any injunction or similar relief binding us, (c) enter any settlement that
        provides any relief other than monetary damages that you pay in full, or (d) enter any settlement
        that fails to unconditionally release us in full.
      </p><p>
        <b>Remedies:</b> If you default under these Terms of Use, we may exercise any and all rights and
        remedies available to the us under these Terms of Use or otherwise at law or in equity.
      </p><p>
        <b>Conflict with Federal Law:</b> You understand that greenleaftrader.com is a resource used solely
        for Oklahoma’s medical cannabis industry as codified and further amended in the Oklahoma
        Statutes and in the rules and regulations adopted by the Oklahoma Department of Health. You
        further understand that the cultivation, sale, processing, and possession of cannabis remains
        illegal pursuant to federal law and agree to indemnify us from any criminal or civil liability you
        may face as a consequence of your election to participate in Oklahoma’s medical cannabis
        industry via greenleaftrader.com despite your knowledge that doing so remains federally illegal.
      </p><p>
        <b>General:</b> These Terms of Use are governed by the laws of the State of Oklahoma, without
        respect to its conflict of laws principles. Jurisdiction for any claims arising under these Terms of
        Use shall lie exclusively with the District Court of Oklahoma County. If any provision of these
        Terms of Use is found to be invalid by any court having competent jurisdiction, the invalidity of
        such provision shall not affect the validity of the remaining provisions of these Terms of Use,
        which shall remain in full force and effect. No waiver of any term of these Terms of Use shall be
        deemed a further or continuing waiver of such term or any other term. In addition, our failure to
        enforce any term of these Terms of Use shall not be deemed as a waiver of such term or
        otherwise affect our ability to enforce such term at any point in the future. Except as expressly
        provided in an additional agreement, additional Terms of Use for certain areas of
        greenleaftrader.com, a particular “Legal Notice,” or software license or material on particular
        greenleaftrader.com pages, these Terms of Use constitute the entire agreement between you and
        greenleaftrader.com with respect to the use of greenleaftrader.com. No changes to these Terms of
        Use shall be made except by a revised posting on this page.
      </p><p>
        <b>Term and Termination:</b> These Terms of Use will remain in full force and effect while you are a
        user of greenleaftrader.com. We reserve the right, at our sole discretion, to pursue all of our legal
        remedies, including but not limited to the removal of your User Content from
        greenleaftrader.com and immediate termination of your registration with or ability to access
        greenleaftrader.com and/or any other services provided to you by us, upon any breach by you of
        these Terms of Use or if we are unable to verify or authenticate any information you submit to
        greenleaftrader.com. This shall include without limitation our ability to immediately terminate
        your registration with or ability to access greenleaftrader.com and/or any other services provided
        to you by us if we are unable to verify that you are the current holder of grower, processor, or
        dispensary license issued by the Oklahoma Medical Marijuana Authority at any time during your
        use of greenleaftrader.com. If such termination is done erroneously, your only recourse shall be
        to notify us of the mistake. If upon our investigation, if we find that such termination was in fact
        done erroneously, your greenleaftrader.com account shall be reinstated within three (3) business
        days of such determination. Even after you are no longer a user of greenleaftrader.com, certain
        provisions of these Terms of Use will remain in effect.
      </p><p>
        <b>Cancellation of Membership:</b> Membership with greenleaftrader.com is provided on a monthly
        basis unless otherwise modified by us. You may cancel your membership at any time. No
        prorations shall be made regardless of date of cancellation by you. For classification purposes
        only, if you were to cancel on December 15, 2019, you would have the right to continued access
        to greenleaftrader.com through December 31, 2019 (or the monthly period during which the
        cancellation was received by us). Nothing contained in this paragraph shall modify or restrict our
        right to immediately terminate or suspend your greenleaftrader.com account for violation or non-
        compliance with these Terms of Use or Privacy Policy.
      </p><p>
        <b>Interstate and International Use:</b> greenleaftrader.com is intended only for users located within
        the State of Oklahoma and is not appropriate or intended for use outside of the State of
        Oklahoma. Access to greenleaftrader.com from states, countries, or territories where such access
        is illegal is prohibited.
      </p><p>
        These Terms of Use constitute a binding agreement between you and greenleaftrader.com, and is
        accepted by you upon your use of greenleaftrader.com.
      </p>
    </div>
  )
}

export default termsOfUse;