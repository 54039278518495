import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';

import Aux from '../../../hoc/Auxiliary/Auxiliary';
import ConfirmCancel from './ConfirmCancel/ConfirmCancel';
import ConfirmSuspend from './ConfirmSuspend/ConfirmSuspend'

import styles from './ManageSubscription.module.css';

const cancelSubscription = (props) => {
  return(
    <Aux>
      <ConfirmCancel 
        show={props.showCancel} 
        onHide={() => {props.onHide('showCancellationModal')}} 
        toggleShowPolicy={props.toggleShowPolicy} 
        showCancellationPolicy={props.showCancellationPolicy}
        doInputChange={props.doInputChange}
        reason={props.reason}
        doCancellation={props.doCancellation}
      />
      <ConfirmSuspend 
        show={props.showSuspend}
        onHide={() => {props.onHide('showSuspensionModal')}}
        doInputChange={props.doInputChange}
        reason={props.reason}
        doSuspension={props.doSuspension}
      />
      <div className={styles.container}>
        <h4>Manage Subscription</h4>
        <div>
          <Row  className={styles.buttonContainer}>
            <Col md={4}>
              <Button block variant='danger' onClick={() => {props.onShow('showCancellationModal')}}>Cancel Subscription</Button>
            </Col>
          </Row>
        </div>
      </div>
    </Aux>
  )
}

export default cancelSubscription;
