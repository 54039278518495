import React, { Component } from 'react';
import { Container, Tabs, Tab } from 'react-bootstrap';
import { withRouter } from 'react-router';

import Toast from '../../components/Toast/Toast'
import Aux from '../../hoc/Auxiliary/Auxiliary';
import Users from "./Users/Users";
import Ads from "./Ads/Ads";
import Category from "./Category/Category";
import Dashboards from "./Dashboards/Dashboards"
import {GET, POST, PUT, DELETE} from '../../helpers/requests';
import User from '../../helpers/user';

class Admin extends Component {
    user = new User();
    _isMounted = false;
    category;
    addConfigModal = {};
    state = {
        areas: [],
        categories: [],
        subcategories: [],
        tags: [],
        uom: [],
        showAddModal: false,
        ExistingUsers: [],
        showNewUser: false,
        showNewAd: false,
        toastConfig: {},
        showToast: false
    }

  capitalize = (s) => {
    if(typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  updateModal = (value) => {
    const newState = {};
    newState[value] = !this.state[value];
    this.setState(newState);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadAllConfigs();
    this.getUsers();
    this.getAds();
  }
  loadAllConfigs = async () => {
      const url = this.props.host + "/configs";
      const data = await GET(url);
          if (this._isMounted) {
              this.setState({areas: data.areas, 
                        categories: data.categories,
                        subcategories: data.subcategories,
                        tags : data.tags,
                        uom : data.uom
                      });
          }
  }
  getUsers = async (event) => {
    let url = this.props.host + "/users";
    if(event){
      url = this.props.host + "/users/"+event.currentTarget.value;
    }
    const data = await GET(url);
      if (this._isMounted) {
        this.setState({ExistingUsers: data});
      }
  }
  getAds = async (event) => {
    let url = this.props.host + "/adconfig";
    if(event){
      url = this.props.host + "/adconfig/"+event.currentTarget.value;
    }
    const data = await GET(url);
      if (this._isMounted) {
        this.setState({ExistingAds: data});
      }
  }
  componentWillUnmount() {
      this._isMounted = false;
  }
  categoryClicked = event => {
    if (this._isMounted) {
      this.setState({
        clickedcategoryid : event.target.dataset.value,
        clickedcategoryname : event.target.dataset.name,
        clickedsubcategoryid : '',
        clickedsubcategoryname : '',

      });
    }
}
subcategoryClicked = event => {
  if (this._isMounted) {
    this.setState({clickedsubcategoryid : event.target.dataset.value,
                    clickedsubcategoryname : event.target.dataset.name});
  }
}
handleClose = () => {
  if (this._isMounted) {
    this.setState({
      editRecord : {},
      showAddModal: false,
      showNewUser: false,
      showNewAd: false
    });
  }
};
handleSubmit = (event) => {
    if(event){
        event.preventDefault();
        if(this.state.typeOfConfig === 'areas'){
            const data = {
                Name: event.currentTarget.name.value,
                State: event.currentTarget.state.value,
                Active: true
            };
            if(this.state.editRecord){
                this.updateRecord(data,'areas', this.state.editRecord.id)
            }else{
                this.createRecord(data, 'areas');
            }
        }else if(this.state.typeOfConfig === 'categories'){
            const data = {
                Name: event.currentTarget.name.value,
                Active: true
            };
            if(this.state.editRecord){
                this.updateRecord(data,'categories', this.state.editRecord.id)
            }else{
                this.createRecord(data, 'categories');
            }
        }else if(this.state.typeOfConfig === 'subcategories'){
            const data = {
                Name: event.currentTarget.name.value,
                Category: this.state.clickedcategoryid,
                Active: true
            };
            if(this.state.editRecord){
                this.updateRecord(data,'subcategories', this.state.editRecord.id)
            }else{
                this.createRecord(data, 'subcategories');
            }
        }else if(this.state.typeOfConfig === 'tags'){
            const data = {
                Name: event.currentTarget.name.value,
                Category: this.state.clickedcategoryid,
                Subcategory : this.state.clickedsubcategoryid,
                Active: true
            };
            if(this.state.editRecord){
                this.updateRecord(data,'tags', this.state.editRecord.id)
            }else{
                this.createRecord(data, 'tags');
            }
        }else if(this.state.typeOfConfig === 'uom'){
          const data = {
              Name: event.currentTarget.name.value,
              Category: this.state.clickedcategoryid,
              Active: true
          };
          if(this.state.editRecord){
              this.updateRecord(data,'uom', this.state.editRecord.id)
          }else{
              this.createRecord(data, 'uom');
          }
      }
    }
};
handleDelete = (event) => {
    if(event){
        event.preventDefault();
        this.deleteRecord(event.currentTarget.dataset.type, event.currentTarget.dataset.value);
    }
}
handleEdit = (event) => {
    event.preventDefault();
    let id = event.target.dataset.id;
    let type = event.target.dataset.value;
    if (this._isMounted) {
      this.state[type].forEach(element => {
          if(element.id === id){
            if(this._isMounted){
              this.setState({editRecord : element});
            }
              this.handleShowAddModal(event);
          }
      });
    }
}
handleShowAddModal = (event) => {
    event.preventDefault();
    if (this._isMounted) {
      this.setState({showAddModal : true});
      let configToAdd = event.target.dataset.value;
      let addConfigModal = {};
      if(configToAdd === 'areas'){
          addConfigModal.title = 'New Area';
          addConfigModal.fields= ['name','state'];
      }else if(configToAdd === 'categories'){
          addConfigModal.title = 'New Category';
          addConfigModal.fields= ['name'];
      }else if(configToAdd === 'subcategories'){
          addConfigModal.title = 'New Subategory';
          addConfigModal.fields= ['name','category'];
      }else if(configToAdd === 'tags'){
          addConfigModal.title = 'New Tag';
          addConfigModal.fields= ['name','category','subcategory'];
      }else if(configToAdd === 'uom'){
        addConfigModal.title = 'New Unit of Measurament';
        addConfigModal.fields= ['name','category'];
    }
      this.setState({addConfigModal : addConfigModal,
                    typeOfConfig: configToAdd});
    }
};
handleCloseAddModal = () => {
  if (this._isMounted) {
    this.setState({showAddModal : false});
  }
};
createRecord = async(data, recordType) => {
    let successMessage = '';
    if(!data.Category){
      successMessage = 'Category added';
    }
    else if(data.Category && !data.Subcategory){
      successMessage = 'Subcategory added'
    }
    else if(data.Category && data.Subcategory){
      successMessage = 'Product Type added'
    }
    const url = `/configs/${recordType}`;
    const config = {
        body: data,
        component: this,
        successMessage
    }
    const response = await POST(url, config);
    this.setState({
      ...response.toast
    }, () => {
      this.handleCloseAddModal();
      this.loadAllConfigs();
    })
}
updateRecord = async(data, recordType, id) => {
    const url = `/configs/${recordType}/${id}`;
    const config = {
        body: data,
        successMessage: `${this.capitalize(recordType)} updated`,
        component: this
    }
    const response = await PUT(url, config);
    this.setState({
      ...response.toast,
      editRecord: {}
    }, () => {
      this.handleCloseAddModal();
      this.loadAllConfigs();
    })
}
deleteRecord = async(recordType, id) => {
    const url = `/configs/${recordType}/${id}`;
    const config = {
        successMessage: 'Delete successful',
        component: this
    }
    const response = await DELETE(url, config);
    this.setState({
      ...response.toast,
    }, () => {
      this.handleCloseAddModal();
      this.loadAllConfigs();
    })
}
newUserClicked = event => {
  if (this._isMounted) {
    this.setState({showNewUser : true});
    this.setState({editRecord: false});
  }
}
newAdClicked = event => {
  if (this._isMounted) {
    this.setState({showNewAd : true});
    this.setState({editAdRecord: false});
  }
}
handleNewUserSubmit = async(event) => {
    event.preventDefault();
    let isAdmin = event.currentTarget.accountType.value === 'Admin' ? true : false;
    let isGrower = event.currentTarget.accountType.value === 'Grower' ? true : false;
    let isProcessor = event.currentTarget.accountType.value === 'Processor' ? true : false;
    let isDispensary = event.currentTarget.accountType.value === 'Dispensary' ? true : false;
    let isSalesRep = event.currentTarget.accountType.value === 'SalesRep' ? true : false;
    let data = {
        firstname : event.currentTarget.firstname.value,
        lastname : event.currentTarget.lastname.value,
        username: event.currentTarget.email.value,
        email: event.currentTarget.email.value,
        // password: event.currentTarget.password.value,
        area: event.currentTarget.area.value,
        status: event.currentTarget.accountStatus.value,
        isAdmin: isAdmin,
        isGrower: isGrower,
        isProcessor: isProcessor,
        isDispensary: isDispensary,
        isSalesRep: isSalesRep,
        license_number: event.currentTarget.license_number.value,
        // license_image: this.state.license_image,
        membership_tier: event.currentTarget.memberTier.value,
        business_name: event.currentTarget.businessName.value,
        salesrep: event.currentTarget.salesrep.value,
        phone: event.currentTarget.phone.value,
        address: event.currentTarget.address.value,
        city: event.currentTarget.city.value,
        state: event.currentTarget.state.value,
        zip: event.currentTarget.zip.value
    }
  
    if(this.state.editRecord){
      this.upsertUser(data, this.state.editRecord.id)
    }else{
        data.password = event.currentTarget.password.value
        this.upsertUser(data);
    }
    
}
upsertUser = async(data, recordId) => {
  const url = recordId ? `/users/${recordId}` : "/users/register";
  const config = {
    body: data,
    successMessage: recordId ? 'Updated User' : 'Created User'
  };
  const response = recordId ? await PUT(url, config) : await POST(url, config);
  this.setState({
    ...response.toast,
    showNewUser : false,
    editRecord : {}
  }, () => {
    this.getUsers();
  })
}
handleResetPwd = async(event) => {
    event.preventDefault();

    const userEmail = event.target.dataset.value;
    const url =  "/users/resetpassword";
    const config = {
        successMessage: 'Password reset token sent',
        body: {email:userEmail},
        component: this
    }
    const response = await POST(url, config)
    this.setState({
      ...response.toast
    })
}
handleUserEdit = (event) => {
    event.preventDefault();
    let id = event.target.dataset.id;
    if (this._isMounted) {
      this.state.ExistingUsers.forEach(element => {
        
          if(element.users.id === id){
            if(this._isMounted){
              this.setState({editRecord : element.users});
            }
              this.setState({showNewUser : true});
          }
      });
    }
  }
  handleAdEdit = (event) => {
    event.preventDefault();
  }
  handleAdSubmit = (event) => {
    console.log('ad Submit');
    if(event){
        event.preventDefault();
        console.log(event.currentTarget);
        let data = {

        }
    }
  }
  handleFileUpload = (file, fileAttr) => {
    const product = this.state.product;
    if(fileAttr && file){
      product[fileAttr] = file;
    }
    this.setState({
      product:{...product}
    });
  }
  render() {
    return(
      <Aux>
        <Toast {...this.state.toastConfig} show={this.state.showToast} onClose={() => {this.updateModal('showToast')}}/>
        <Container style={{marginTop: '1rem'}}>
          <h1>Admin</h1>
          <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
            <Tab eventKey="home" title="Home">
                <Dashboards></Dashboards>
            </Tab>
            <Tab eventKey="category" title="Category">
              <Category {...this.state} 
                            doCategoryClick={this.categoryClicked} 
                            doSubcategoryClick={this.subcategoryClicked}
                            doHandleShowAddModal={this.handleShowAddModal}
                            doHandleCloseAddModal={this.handleCloseAddModal}
                            doHandleSubmit={this.handleSubmit}
                            doHandleDelete={this.handleDelete}
                            doHandleEdit={this.handleEdit}
                        /> 
            </Tab>
            <Tab eventKey="users" title="Users" >
              <Users {...this.state}
                  handleNewUserClick={this.newUserClicked}
                  handleClose={this.handleClose}
                  doNewUserSubmit={this.handleNewUserSubmit}
                  doHandlePwdReset={this.handleResetPwd}
                  doHandleEdit={this.handleUserEdit}
                  listViewChange={this.getUsers}
              />
            </Tab>
            <Tab eventKey="advertisement" title="Advertisement" >
              <Ads {...this.state}
                  handleNewClick={this.newAdClicked}
                  handleClose={this.handleClose}
                  doHandleSubmit={this.handleAdSubmit}
                  doHandleEdit={this.handleAdEdit}
                  onImageUpload={this.handleFileUpload}
              />
            </Tab>
          </Tabs>
        </Container>
      </Aux>
    )
  }
}

export default withRouter(Admin);