import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import Cookies from 'js-cookie';

import Products from '../../containers/Products/Products';
import Product from '../../containers/Product/Product';
import NewProduct from '../../containers/NewProduct/NewProduct';
import List from '../../containers/List/List';
import Under21 from '../Under21/Under21';
import Register from '../../containers/Register/Register';
import Admin from '../../containers/Admin/Admin';
import ResetPassword from '../../containers/ResetPassword/ResetPassword';
import Cart from '../../containers/Cart/Cart'
import Dashboard from '../../containers/Dashboards/Dashboards';
import HowToBuy from '../../containers/Help/HowToBuy';
import HowToSell from '../../containers/Help/HowToSell';
import OrderHistory from '../../containers/OrderHistory/OrderHistory';
import Account from '../../containers/Account/Account';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        Cookies.get('token') !== undefined
            ? <Component {...props}/>
            : <Redirect to='/#Login'/>
    )} />
)

const router = (props) => {
  return(
    <Switch>
      <PrivateRoute path='/orders' component={ () => <OrderHistory {...props}/>}/>
      <PrivateRoute path='/cart' component={ () => <Cart {...props}/>} />
      <Route path='/reset/:token' component={ () => <ResetPassword {...props}/>} />
      <PrivateRoute path='/admin' component={() => <Admin {...props}/>}/>
      <Route path='/register' component={() => <Register {...props}/>}/>
      <Route path='/under21' component={() => <Under21 />}/>
      <Route path='/list' component={() => <List {...props} handleSetUser={props.handleSetUser}/>}/>
      <Route path='/new' component={() => <NewProduct {...props}/>}/>
      <Route path='/manage-listings/:productId' component={() => <NewProduct {...props} handleSetUser={props.handleSetUser}/>}/>
      <Route path='/manage-listings' component={() => <Products {...props} handleSetUser={props.handleSetUser}/>}/>
      <Route path='/dashboard' component={() => <Dashboard {...props} handleSetUser={props.handleSetUser}/>}/>
      <Route path='/account' component={() => <Account {...props}/>}/>
      <Route path='/howtobuy' component={() => <HowToBuy />}/>
      <Route path='/howtosell' component={() => <HowToSell />}/>
      <Route path='/:productId' component={() => <Product {...props} handleSetUser={props.handleSetUser}/>}/>
      <Route path='/' component={() => <Products {...props}/>}/>
     
    </Switch>
  )
}

export default router;