import React from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import Modal from '../../../../components/Modal/Modal';
import styles from './NewAd.module.css';
import UploadImages from '../../../../components/UploadImages/UploadImages';


const newAd = (props) => {
  const area = () => {
    let editArea = '';
    if (props.editRecord && props.editRecord.area) {
      editArea = props.editRecord.area;
    }
    let options = []
    props.areas.forEach(area => {
      if (editArea === area.id) {
        options.push(<option key={area.id} value={area.id} selected>{area.Name}</option>);
      } else {
        options.push(<option key={area.id} value={area.id}>{area.Name}</option>);
      }

    });
    return (
      <Form.Group as={Col} controlId="area">
        <Form.Label>Area</Form.Label>
        <Form.Control as="select" id="area">
          {options}
        </Form.Control>
      </Form.Group>
    )
  }

  const accountStatus = () => {
    let active = false;
    let pending = false;
    let inactive = false;
    if (props.editRecord) {
      if (props.editRecord.status === 'active') {
        active = true;
      }
      if (props.editRecord.status === 'pending') {
        pending = true;
      }
      if (props.editRecord.status === 'inactive') {
        inactive = true;
      }
    }
    let options = []
    options.push(<option value="active" key="active" selected={active}>Active</option>);
    options.push(<option value="pending" key="pending" selected={pending}>Pending</option>);
    options.push(<option value="inactive" key="inactive" selected={inactive}>Inactive</option>);
    return (
      <Form.Group as={Col} controlId="status">
        <Form.Label>Status</Form.Label>
        <Form.Control as="select" id="status">
          {options}
        </Form.Control>
      </Form.Group>
    )
  }
  const displayLicenseImage = () => {
    if (props.editRecord && props.editRecord.license_image) {
      return (
        <img src={props.editRecord.imageLink} alt='Upload' className={styles.image} />
      );
    }
  }
  const actions = () => {
    return (
      <div className={styles.actions}>
        <Button variant="secondary" onClick={props.handleClose}>Cancel</Button>
        <Button variant="primary" type="submit" >Save</Button>
      </div>
    )
  }
  const getFiles = (event) => {
    let fileInput = event.target.files[0]
  };
  const modalDetails = () => {
    if (props.showNewAd) {
      return (
        <Modal show={props.showNewAd} onHide={props.handleClose} title={props.editRecord ? 'Edit Ad' : 'New Ad'}>
          <Form onSubmit={props.doSubmit}>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridname">
                <Form.Label>Name</Form.Label>
                <Form.Control placeholder="Ding dong labs" id="name" required defaultValue={props.editRecord ? props.editRecord.name : ''} />
              </Form.Group>
              {accountStatus()}
            </Form.Row>
            <Form.Row>
            <Form.Group as={Col} controlId="formHorizontalZip">
                <Form.Label>Column</Form.Label>
                <Form.Control placeholder="1" id="column" required defaultValue={props.editRecord ? props.editRecord.column : ''} />
              </Form.Group>
              
              <Form.Group as={Col} controlId="formHorizontalState">
                <Form.Label>row</Form.Label>
                <Form.Control placeholder="1" id="row" required defaultValue={props.editRecord ? props.editRecord.row : ''} />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="displayTime">
                <Form.Label>Display Time</Form.Label>
                <Form.Control placeholder="20" id="displayTime" required defaultValue={props.editRecord ? props.editRecord.displayTime : ''} />
              </Form.Group>
            
              <Form.Group as={Col} controlId="formHorizontalPhone">
                <Form.Label>State</Form.Label>
                <Form.Control placeholder="OK" id="state" required defaultValue={props.editRecord ? props.editRecord.state : ''} />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formHorizontalPhone">
                <Form.Label>Ad Link</Form.Label>
                <Form.Control placeholder="www.google.com" id="adLink" required defaultValue={props.editRecord ? props.editRecord.adLink : ''} />
              </Form.Group>
              <Form.Group as={Col} controlId="formHorizontalLicenseImage">
                <Form.Label>Upload File</Form.Label>
                <br />
                <UploadImages onFileAdded={(file) => { props.onImageUpload(file, 'adImage') }}/*picture={props.ad.Image}*/ />
              </Form.Group>
              {displayLicenseImage()}
            </Form.Row>
            {actions()}
          </Form>
        </Modal>
      )
    }
  }
  return (
    <div>
      {modalDetails()}
    </div>
  );
}
export default newAd;