import React from 'react';
import { Button } from 'react-bootstrap';

import Modal from '../Modal/Modal';

import styles from './BuyerOrSeller.module.css';

const buyerOrSeller = (props) => {

  return (
    <Modal className={styles.buyorsellContainer}
      title='How It Works'
      show={props.show}
      onHide={props.onHide}
      showbutton='true'
    >
      <div className={styles.buyorsellText}>
        <p>Please indicate whether you are a "Buyer" or a "Seller".</p>
      </div>
      <div className={styles.buyorsellContainer}>
        <Button title='BUYER' variant='primary' className={styles.button} onClick={props.updateModal} href="/howtobuy" >I'm a Buyer</Button>
        <Button title='SELLER' variant='success' className={styles.button} onClick={props.updateModal} href="/howtosell">I'm a Seller</Button>
      </div>
    </Modal>
  )
}

export default buyerOrSeller;