import React from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';

import styles from './OrderItem.module.css';
import quatity from "../../../Product/Quantity/Quantity";

const orderItem = (props) => {

  const getPrice = () => {
    return (parseFloat(props.Price) * parseInt(props.Quantity,10)).toFixed(2);
  }

  const buttons = (showOnMobile) => {
    const classes = showOnMobile ? `${styles.buttonContainer}` : `${styles.buttonContainer} ${styles.hideOnMobile}`
    return(
      <div className={classes}>
        <Button 
          variant='success' 
          block
          data-quantity={props.Quantity}
          data-available={props.Amount_Available}
          data-id={props.id}
          data-strain={props.Strain}
          data-brand={props.Brand}
          data-uom={props.Price_UOM}
          data-price={props.Price}
          onClick={props.onEdit}
        >Edit</Button>
        <Button 
          variant='danger' 
          block 
          onClick={props.onDelete} 
          data-id={props.id} 
          data-strain={props.Strain}
          data-brand={props.Brand}
        >Delete</Button>
        <Button variant='dark' block href={`mailto:${props.email}?subject=Green Leaf Trader Product Question - ${props.Brand} ${props.Strain}`}>Contact</Button>
      </div> 
      )
  }

  return(
    <Card className={styles.card}>
      <Card.Body className={styles.cardBody}>
        <div className={styles.container}>
          <div className={styles.imageContainer}>
            <img src={props.Product_Image} alt='Failed to load' className={styles.image}/>
          </div>
          <div className={styles.infoContainer}>
            <div className={styles.info}>
              <p className={styles.brand}>{props.Brand}</p>
              <p className={styles.strain}>{props.Strain}</p>
            </div>
            <Row className={styles.row}>
              <Col className={styles.column}>
                <p className={styles.quantity}>{`${props.Quantity} ${props.Price_UOM}(s)`}</p>
              </Col>
              <Col className={styles.column}>
                <p className={styles.price}>${getPrice()}</p>
              </Col>
            </Row>
            <div>
              {buttons(true)}
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}

export default orderItem;