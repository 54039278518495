import React from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';

import styles from './Search.module.css'

const search = (props) => {
  return(
    <div>
      <Form className={styles.searchBarContainer} onSubmit={event => event.preventDefault()}>
        <Row className={styles.searchBar}>
          <Col sm={4} md={4}>
            <Button className={styles.button} onClick={props.showFilters} variant="success">Add Filters</Button>
          </Col>
          <Col sm={8} md={8}>
            <Form.Group controlId='q' className={styles.formGroup}>
              <Form.Control placeholder='Search by brand or strain' value={props.query} onChange={props.onSearchChange}/>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default search;