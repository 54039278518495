import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Container } from 'react-bootstrap';
import Cookies from 'js-cookie';

import Aux from '../../hoc/Auxiliary/Auxiliary';
import Modal from '../../components/Modal/Modal';
import Pricing from './Pricing/Pricing';
import RegisterForm from './RegisterForm/RegisterForm';
import Payment from './Payment/Payment';
import TermsOfUse from './TermsOfUse/TermsOfUse'
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import Toast from '../../components/Toast/Toast';
import User from '../../helpers/user';
import {GET, POST} from '../../helpers/requests';

import styles from './Register.module.css';
import ReactGA from 'react-ga';

const trackingId = "UA-189846059-1"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);

if(!RegExp.escape) {
  RegExp.escape = string =>{
    return String(string).replace(/[\\^$*+?.()|[\]{}]/g, '\\$&');
  };
}

class Register extends Component {
  user = new User()

  _isMounted = false;
  state = {
    areas: [],
    showModal: false,
    formData: {
      firstname: '',
      lastname: '',
      username: '',
      email: '',
      password: '',
      area: '',
      status: "Inactive",
      isGrower: false,
      isProcessor: false,
      isDispensary: false,
      isSalesRep: false,
      address: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
      license_number: '',
      license_image: '',
      business_name: '',
      salesrep:''
    },
    toastConfig: {},
    showToast: false
  }

  handleInputChange = event => {
    const newState = {};
    if(event.target.id === 'accountType'){
      switch(event.target.value){
        case "Grower":
          newState.isGrower = true;
          newState.isProcessor = false;
          newState.isDispensary = false;
          break;
       case "Processor":
          newState.isGrower = false;
          newState.isProcessor = true;
          newState.isDispensary = false;
          break; 
        case "Dispensary":
          newState.isGrower = false;
          newState.isProcessor = false;
          newState.isDispensary = true;
          break;
        default:
          break;
      }
    } else if(event.target.id === 'area'){
      newState['area'] = event.target.value;
    } else if(event.target.id === 'email'){
      newState['email'] = event.target.value;
      newState['username'] = event.target.value;
    } else if(event.target.id === 'license_number' && (
      (event.target.value.length === 5 && this.state.formData.license_number.length > 5) || 
      (event.target.value.length === 10 && this.state.formData.license_number.length > 10))
    ){
      newState['license_number'] = event.target.value.substring(0,event.target.value.length-1);
    }
    else {
      newState[event.target.id] = event.target.value;
    }
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        ...newState
      }
    }))
  }

  handleStateChange = async event => {
    event.target.value = event.target.value.toUpperCase();
    this.handleInputChange(event);
    if(event.target.value.length === 2){
      const salespeople = await this.getSalesPeopleByState(event.target.value);
      if(salespeople)
      this.setState({
        salespeople: [...salespeople]
      })
    }
  }

  handleLicenseChange = event => {
    let license = event.target.value.toUpperCase();
    if((license.length === 4 || license.length === 9) ){
      license += '-';
    }
    event.target.value = license;
    this.handleInputChange(event);
  }

  getSalesPeopleByState = async state => {
    const url=`/users/salespeople/${state}`;
    const response = await GET(url, {component: this});
    if(!Object.keys(response).includes('toast')){
      return response;
    }
    else{
      return [];
    }
  }

  componentDidMount() {
    this._isMounted = true;
    // this.loadArea();
  }

  loadArea = async () => {
    const url = this.props.host + "/configs/areas";
    const data = await GET(url);
      if (this._isMounted) {
        this.setState({ areas: data.sort((a, b) => (a.Name > b.Name) ? 1 : -1) });
      }
  }

  showTerms = docType => {
    if(docType){
      this.setState(prevState => ({
        showModal: !prevState.showModal,
        modalTitle: docType === 'terms' ? 'Terms of Use'  : 'Privacy Policy',
        content: docType === 'terms' ? <TermsOfUse /> : <PrivacyPolicy />
      }))
    }
    else{
      this.setState(prevState => ({
        showModal: !prevState.showModal
      }))
    }
  }

  onImageUpload = (file, fileAttr) => {
    if (fileAttr && file) {
      this.setState(prevState => ({
        formData: {
          ...prevState.formData,
          license_image: file
        }
      }));
    }
  }

  registrationType = (type) => {
    if (type) {
      this.setState({ regType: type }, () => {
        window.scrollTo(0, 0);
      });
    }
  }
  createNewUser = async (event) => {
    event.preventDefault();
    // if(this.state.formData.license_image === ''){
    //   this.setState({
    //     toastConfig: {
    //       title:'License Image',
    //       message: 'License Image is required for registration',
    //       variant: 'danger',
    //       delay: 10000
    //     },
    //     showToast: true
    //   })
    // }
    // else{      
      // if(this.state.formData.isDispensary){
        this.state.formData.status = 'active';
        console.log('AREA = ' + this.state.formData.area)
        // this.state.formData.area = this.state.formData.area.
      // }
      const url = "/users/register";
      const config = {
        body: this.state.formData,
        successMessage: 'Registration successful',
        component: this
      };
      const response = await POST(url, config);
      if (Object.keys(response).includes('error')) {
          let errorMessage;
          if(typeof response.error.response.data.msg === 'object'){
            if(response.error.response.data.msg.sqlMessage.includes(process.env.REACT_APP_DUPLICATE_ERROR)){
              errorMessage = 'This license number already exists in our database. If you feel that you have received this message in error. Please contact us directly at xxx-xxx-xxxx with questions or concerns.'
              response.toast.toastConfig.autohide = false;
            }
          }
          else{
            errorMessage = response.error.response.data.msg;
          }
          response.toast.toastConfig.message = errorMessage;
          this.setState({ 
            ...response.toast
        });
      } else {
        this.setState({ 
          ...response.toast,
          success: true,
          user: response.data.user
        }, async () => {
          if(this.state.formData.isDispensary || this.state.formData.isProcessor || this.state.formData.isGrower){
            const url = 'users/login';
            const config = {
              body: {
                email: this.state.formData.username,
                password: this.state.formData.password
              }
            };
            const authResponse = await POST(url, config);
            if(response.status === 200){
              this.user.setUser(authResponse.data.userDetails);
              Cookies.set('token', response.data.token);
              this.props.history.push('/#Registered');
            }
          }
        });
      }
   // }
  }

  content = () => {
    if(this.state.regType && this.state.success && (this.state.formData.isProcessor || this.state.formData.isGrower)){
      return <Payment user={this.state.user} userFormData={this.state.formData}/>
    }
    else if (this.state.regType) {
      return (
        <RegisterForm 
          formData={this.state.formData} 
          doInputChange={this.handleInputChange}
          doStateChange={this.handleStateChange}
          doLicenseChange={this.handleLicenseChange}
          onSubmit={this.createNewUser}
          areas={this.state.areas}
          salespeople={this.state.salespeople}
          onImageUpload={this.onImageUpload}
          showTerms={this.showTerms}
        />
      );
    }
    else{
      return <Pricing registrationType={this.registrationType}></Pricing>
    }
  }

  render() {
    return (
      <Aux>
        <Modal show={this.state.showModal} onHide={this.showTerms} title={this.state.modalTitle} showbutton='true'>
          {this.state.content}
        </Modal>
        <Toast {...this.state.toastConfig} show={this.state.showToast} onClose={() => {this.setState({showToast: false})}}/>
        <Container className={styles.container}>
          {this.content()}
        </Container>
      </Aux>
    )
  }
}
export default withRouter(Register);