import React, { Component } from 'react';
import { Container, Tabs, Tab } from 'react-bootstrap'; 
import ListedProducts from './ListedProducts/ListedProducts';
import CompletedOrders from './CompletedOrders/CompletedOrders';
import SubmittedOrders from './SubmittedOrders/SubmittedOrders';
import ActiveUsers from './ActiveUsers/ActiveUsers';
import PendingUsers from './PendingUsers/PendingUsers';
import ActiveGrowers from './ActiveGrowers/ActiveGrowers';
import ActiveProcessors from './ActiveProcessors/ActiveProcessors';
import ActiveDispensaries from './ActiveDispensaries/ActiveDispensaries';
import styles from './Dashboards.module.css';
export default class Dashboards extends Component {
    _isMounted = false;
  
    render(){
        return(
            <Container>
                <div  className={styles.topContainer}>
                    <div className={styles.header}>
                        Dashboard
                    </div>
                </div>
                <div  className={styles.topContainer}>
                    <ActiveUsers></ActiveUsers>
                    <PendingUsers></PendingUsers>
                    <ActiveGrowers></ActiveGrowers>
                    <ActiveProcessors></ActiveProcessors>
                    <ActiveDispensaries></ActiveDispensaries>
                </div>
                <br />
                <div  className={styles.topContainer}>
                    <ListedProducts></ListedProducts>
                    <SubmittedOrders></SubmittedOrders>
                    <CompletedOrders></CompletedOrders>
                </div>
            </Container>
        )
    }
}