import React, { Component } from 'react';
import { Container, Col, Row, Spinner, Card, Button } from 'react-bootstrap';
import { withRouter } from 'react-router'

import { GET, DELETE, PUT, POST } from '../../helpers/requests';
import User from '../../helpers/user'
import Aux from '../../hoc/Auxiliary/Auxiliary'
import Toast from '../../components/Toast/Toast';
import Delete from './Delete/Delete'
import Edit from './Edit/Edit'
import Order from './Order/Order';
import Submit from './Submit/Submit'

import styles from './Cart.module.css';

class Cart extends Component {
  user = new User();

  state={
    user: this.user.getUser(),
    loading: true,
    hasList: false,
    toastConfig: {},
    showToast: false,
    showEditModal: false,
    showDeleteModal: false,
    showSubmitModal: false
  }

  toggle = (toggleProp) => {
    const newState = [];
    newState[toggleProp] = !this.state[toggleProp];
    this.setState(newState)
  }

  componentDidMount = () => {
    this.getList()
  }

  getList = async () => {
    if(this.state.user && this.state.user.listid){
      const url = `/lists/${this.state.user.listid}`;
      const list = await GET(url);
      if(Object.keys(list).includes('toastConfig')){
        this.setState({
          ...list, loading: false
        })
      }
      else{
        const order = await this.generateOrder(list);
        this.setState({
          order,
          loading: false
        })
      }
    }
    else{
     this.setState({loading:false})
    }
  }

  submitOrder = async () => {
    const url = `/lists/${this.state.order.list.id}/Submitted`;
    const config = {
      successMessage: 'Your order has been submitted',
      body: {
        total: this.state.order.list.Total
      },
      component: this
    }

    const response = await POST(url, config);
    this.setState(prevState => ({
      ...response.toast,
      showSubmitModal: false,
      user: {
        ...prevState.user,
        listid: '',
      },
      order: undefined
    }), () => {
      this.getList();
      this.user.setUser({
        ...this.state.user,
        listid: ''
      })
      this.props.history.push('#Submit');
    })
  }

  editPrompt = (event) => {
    this.setState({
      product: {
        id: event.target.dataset.id,
        quantity: parseInt(event.target.dataset.quantity, 10),
        originalQuantity: parseInt(event.target.dataset.quantity, 10),
        max: parseInt(event.target.dataset.available, 10),
        brand: event.target.dataset.brand,
        strain: event.target.dataset.strain,
        uom: event.target.dataset.uom,
        price: event.target.dataset.price
      },
      showEditModal: true
    })
  }

  onQuantityChange = (doDecrement) => {
    let newQuantity = parseInt(this.state.product.quantity, 10);
    if(doDecrement){
      newQuantity -= 1;
    }
    else{
      newQuantity += 1;
    }
    this.setState(prevState => ({
      product : {
        ...prevState.product,
        quantity: newQuantity,
      }
    }))
  }

  onQuantityEdit = (value) => {
    this.setState(prevState => ({
      product: {
        ...prevState.product,
        quantity: value
      }
    }))
  }

  onSubmitEdit = () => {
    this.setState({
      showEditModal: false
    }, async () => {
      if(this.state.product.quantity === 0){
        this.deletePrompt();
      }
      else if(this.state.product.quantity !== this.state.product.originalQuantity){
        const url = `lists/${this.state.order.list.id}/${this.state.product.id}`;
        const product = this.state.product;
        const config = {
          body: {
            quantity: this.state.product.quantity,
            price: this.state.product.price,
            total: parseInt(this.state.product.quantity, 10) * parseFloat(this.state.product.price)
          },
          successMessage: `${product.brand.toUpperCase()} ${product.strain.toUpperCase()} updated to ${product.quantity} ${product.uom}(s) .`,
          component: this
        }
        const response = await PUT(url, config);
        this.setState({...response.toast}, () => {
          this.getList()
        })
      }
    })
  }

  backToProducts= () => {
    this.toggle('showSubmitModal');
    this.props.history.push('/')
  }

  deletePrompt = (event) => {
    this.props.history.push(this.props.location.pathname)
    const product = {};
    if(event){
      product.id = event.target.dataset.id;
      product.brand = event.target.dataset.brand;
      product.strain = event.target.dataset.strain;
    }
    this.setState(prevState => ({
      product: Object.keys(product).length > 0 ? product : prevState.product, 
      showDeleteModal:true
    }))
  }

  handleDelete = () => {
    this.setState({
      showDeleteModal:false
    }, async () => {
      const productId = this.state.product.id;
      const product = this.state.order.products.filter(product => {
        return product.id === productId;
      })[0]
      const url = `/lists/${this.state.user.listid}/${productId}`;
      const config = {
        successMessage: `${product.Brand.toUpperCase()} ${product.Strain.toUpperCase()} was removed from the order`,
        component: this
      }
      const response = await DELETE(url, config);
      this.setState( {
        ...response.toast
      }, () => {
        this.getList();
        this.props.history.push('#Delete')
      })
    })
  }

  getProductIds = (list) => {
    return list.map(listItem => {
      return listItem.List_Products.ProductId;
    })
  }

  generateQueryString = (productIds) => {
    let result = '?';
    if(productIds && Array.isArray(productIds)){
      productIds.forEach((id, index) => {
        if(index !== 0){
          result += '&';
        }
        result += `id=${id}`;
      })
    }
    return result
  }

  generateOrder = async (list) => {
    return new Promise(async (resolve, reject) => {
      if(list && list[0] && list[0].List){
        const productIds = this.getProductIds(list);
        const productUrl = `/products${this.generateQueryString(productIds)}`;
        const response = await GET(productUrl);
        if(Object.keys(response).includes('toastConfig')){
          this.setState({
            ...response, loading:false
          }, reject())
        }
        else{
          const newList = {
            list: {
              ...list[0].List,
              Total: this.getTotal(list, response.products)
            },
            products: list.map(listItem => {
              const product = response.products.filter(product => {
                return listItem.List_Products.ProductId === product.id
              });
              return ({
                ...product[0],
                Quantity: listItem.List_Products.Quantity,
                Status: listItem.List_Products.Status
              })
            })
          }
          resolve(newList);
        }     
      }
      else{
        resolve();
      }
    })
  }


  getTotal = (list, products) => {
    let result = 0;
    list.forEach(listItem => {
      let price = products.filter(product => {
        return product.id === listItem.List_Products.ProductId;
      })[0].Price

      price = parseFloat(price);
      result += price*parseInt(listItem.List_Products.Quantity);
    })
    result = result.toFixed(2)
    return result;
  }

  loading = () => {
    if(this.state.loading){
      return (
        <div className={styles.spinner}>
          <h5>Loading Order</h5>
          <Spinner animation="border"/>
        </div>
      )
    }
  }

  order = () => {
    if(this.state.order){
      return (
        <Col lg={8} md={12}>
          <Order order={this.state.order} onDelete={this.deletePrompt} onEdit={this.editPrompt}/>
        </Col>
      )
    }
    else if(!this.state.loading){
      return (
        <Card className={styles.card}>
          <Card.Body>
            <h3 style={{textAlign: 'center'}}>Your cart is empty</h3>
          </Card.Body>
          <Card.Footer className={styles.footer}>
            <Button variant='success' onClick={() => {this.props.history.push('/')}}>Back to Products</Button>
          </Card.Footer>
        </Card>
      ) 
    }
  }

  submit = () => {
    if(this.state.order){
      return (
        <Submit 
          order={this.state.order} 
          total={this.state.order.list.Total} 
          productCount={this.state.order.products.length}
          show={this.state.showSubmitModal}
          onHide={() => {this.toggle('showSubmitModal')}}
          onBack={this.backToProducts}
          onSubmit={this.submitOrder}
        />
      )
    }
  }



  render() {
    return(
      <Aux>
        <Toast {...this.state.toastConfig} show={this.state.showToast} onClose={() => {this.toggle('showToast')}}/>
        <Delete 
          show={this.state.showDeleteModal}
          onHide={() => {this.toggle('showDeleteModal')}}
          onDelete={this.handleDelete}
          name={this.state.product && this.state.product.brand ? `${this.state.product.brand.toUpperCase()} ${this.state.product.strain.toUpperCase()}` : undefined}
        />
        <Edit 
          show={this.state.showEditModal}
          onHide={() => {this.toggle('showEditModal')}}
          quantity={this.state.product && this.state.product.quantity ? this.state.product.quantity : 0}
          max={this.state.product && this.state.product.max ? this.state.product.max : 0}
          decrementQuantity={() => {this.onQuantityChange(true)}}
          incrementQuantity={() => {this.onQuantityChange()}}
          onChange={this.onQuantityEdit}
          handleSubmitEdit={this.onSubmitEdit}
        />
        <Container className={styles.container}>
          {this.loading()}
          <Row className={styles.row}>
            {this.order()}
            <Col lg={4} md={12} className={styles.submit}>
              {this.submit()}
            </Col>
          </Row>
        </Container>
      </Aux>
    )
  }
}

export default withRouter(Cart);