import React from 'react';
import { Button } from 'react-bootstrap';

import Aux from '../../../hoc/Auxiliary/Auxiliary';
import Modal from '../../../components/Modal/Modal';

const deleteModal = (props) => {
  const actions = () => {
    return(
      <Aux>
        <Button variant='success' onClick={props.onHide}>Cancel</Button>
        <Button variant='danger' onClick={props.onDelete}>Delete</Button>
      </Aux>
    )
  }

  return (
    <Modal title='Delete Product' show={props.show} onHide={props.onHide} actions={actions()}>
      <div>
        <h5>Are you sure you would like to delete this product?</h5>
      </div>
    </Modal>
  )
}


export default deleteModal;