import React, { Component } from 'react';
import { Container, Tabs, Tab } from 'react-bootstrap'; 
import User from '../../helpers/user';
import ListedProducts from './ListedProducts/ListedProducts';
import PendingOrders from './PendingOrders/PendingOrders';
import CompletedOrders from './CompletedOrders/CompletedOrders';
import SoldProductsByCategory from './SoldProductsByCategory/SoldProductsByCategory';
import SoldProductsBySubCategory from './SoldProductsBySubCategory/SoldProductsBySubCategory';
import SoldProductsByTag from './SoldProductsByTag/SoldProductsByTag';
import SoldProductsTotal from './SoldProductsTotal/SoldProductsTotal';
import LowAvailabilityProducts from './LowAvailabilityProducts/LowAvailabilityProducts';
import styles from './Dashboards.module.css';
export default class Dashboards extends Component {
    user = new User();

    _isMounted = false;
  
    state = {
      user: this.user.getUser()
    }
    render(){
        return(
            <Container>
                <div  className={styles.topContainer}>
                    <div className={styles.header}>
                        Dashboard
                    </div>
                </div>
                <div  className={styles.topContainer}>
                    <SoldProductsTotal></SoldProductsTotal>
                    <ListedProducts></ListedProducts>
                    <PendingOrders></PendingOrders>
                    <CompletedOrders></CompletedOrders>
                </div>
                <div  className={styles.topContainer}>
                    <SoldProductsByCategory></SoldProductsByCategory>
                    <SoldProductsBySubCategory></SoldProductsBySubCategory>
                    <SoldProductsByTag></SoldProductsByTag>
                    <LowAvailabilityProducts></LowAvailabilityProducts>
                </div>
                <br /> <br />
            </Container>
        )
    }
}