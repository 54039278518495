import React from 'react';
import { Form, Col, Row, Button } from 'react-bootstrap';
import UploadImages from '../../../components/UploadImages/UploadImages';
import NumberFormat from 'react-number-format';
import Aux from '../../../hoc/Auxiliary/Auxiliary'

const productForm = (props) => {
  const selectOptions = (optionsArray, filterAttr, filterValue) => {
    const options = [];
    options.push(<option value='' key='select'>Select One</option>);
    if (Array.isArray(optionsArray)) {
      optionsArray.forEach(option => {
        if (filterAttr) {
          if (option[filterAttr] === filterValue) {
            options.push(<option value={JSON.stringify(option)} key={option.id}>{option.Name}</option>)
          }
        }
        else {
          options.push(<option value={JSON.stringify(option)} key={option.id}>{option.Name}</option>)
        }
      })
    }
    return options;
  }

  const productTested = () => {
    return [{ id: 1, Name: 'Yes' }, { id: 0, Name: 'No' }]
  }

  const delivery = () => {
    return [{ id: 1, Name: 'Free Delivery' }, { id: 0, Name: 'No Delivery' }]
  }

  const thcFieldLabel = () => {
    if (props.product && props.product.Category) {
      return props.product.Category.Name === 'Edibles' ? 'mg of THC' : '% THC';
    }
    return '% THC';
  }

  const showDeleteButton = () => {
    if(props.isManageListing){
      return <Button variant='danger' style={{marginRight: '0.5rem'}} onClick={props.onDelete}>Delete Product</Button>
    }
  }

  const showTHC = () => {
    if(props.product.Category.Name !== 'Accessories / Other'){
      if(props.product.Category.Name !== 'Edibles'){
        return (
          <Col sm={12} md={4}>
            <Form.Group controlId='THC'>
              <Form.Label>{thcFieldLabel()}</Form.Label>
              <NumberFormat thousandSeparator={false} 
                  allowEmptyFormatting={true}
                  mask="-"
                  decimalScale={'2'} 
                  fixedDecimalScale={true}
                  allowNegative={false}
                  format="##.##"
                  name="THC" id="THC" class="form-control" value={props.product.THC} onChange={props.onInputUpdate} />
            </Form.Group>
          </Col>
        );
      }else{
        return (
          <Col sm={12} md={4}>
            <Form.Group controlId='THC'>
              <Form.Label>{thcFieldLabel()}</Form.Label>
              <NumberFormat thousandSeparator={false} 
                    decimalScale={'0'}
                    isNumericString={true}
                    allowNegative={false}
                    name="THC" id="THC" class="form-control" value={props.product.THC} onChange={props.onInputUpdate} />
            </Form.Group>
          </Col>
        );
      }
    }
  }

  const showTRP = () => {
    if(props.product.Category.Name !== 'Accessories / Other'){
      return (
        <Col sm={12} md={4}>
          <Form.Group controlId='TRP'>
            <Form.Label>% TRP</Form.Label>
            <NumberFormat thousandSeparator={false} 
                allowEmptyFormatting={true}
                mask="-"
                decimalScale={'2'} 
                fixedDecimalScale={true}
                allowNegative={false}
                format="##.##"
                name="TRP" id="TRP" class="form-control" value={props.product.TRP} onChange={props.onInputUpdate} required />
          </Form.Group>
        </Col>
      )
    }
  }

  const showCBD = () => {
    if(props.product.Category.Name !== 'Accessories / Other'){
      return (
        <Col sm={12} md={4}>
          <Form.Group controlId='CBD'>
            <Form.Label>% CBD</Form.Label>
            <NumberFormat thousandSeparator={false} 
                allowEmptyFormatting={true}
                mask="-"
                decimalScale={'2'} 
                fixedDecimalScale={true}
                format="##.##"
                allowNegative={false}
                name="CBD" id="CBD" class="form-control" value={props.product.CBD} onChange={props.onInputUpdate} required />
          </Form.Group>
        </Col>
      )
    }
  }
  const showProductTested = () => {
    if(props.product.Category.Name !== 'Accessories / Other'){
      return (
        <Aux>
          <Col sm={12} md={6}>
            <Form.Group controlId='Tested'>
              <Form.Label>Product tested?</Form.Label>
              <Form.Control as='select' value={JSON.stringify(props.product.Tested)} onChange={props.onInputUpdate} required>
                {selectOptions(productTested())}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col sm={12} md={6}>
            <Form.Group controlId='Price_UOM'>
              <Form.Label>Price per</Form.Label>
              <Form.Control as='select' value={JSON.stringify(props.product.Price_UOM)} onChange={props.onInputUpdate} disabled={!props.product.Category} required>
                {selectOptions(props.configs.uom, 'Category', props.product.Category.id)}
              </Form.Control>
            </Form.Group>
          </Col>
        </Aux>
      )
    }
    else{
      return(<Col sm={12}>
        <Form.Group controlId='Price_UOM'>
          <Form.Label>Price per</Form.Label>
          <Form.Control as='select' value={JSON.stringify(props.product.Price_UOM)} onChange={props.onInputUpdate} disabled={!props.product.Category} required>
            {selectOptions(props.configs.uom, 'Category', props.product.Category.id)}
          </Form.Control>
        </Form.Group>
      </Col>)
    }
  }

  return (
    <Row>
      <Col lg={4}>
        <Row>
          <Col>
            <div>
              <p style={{ color: 'red', fontSize: '28px', fontWeight: 'bold' }}>WARNING:</p>
              <p style={{ fontWeight: 'bold' }}>MISREPRESENTATION OF PRODUCT'S STRENGTH, GRADE, ETC. WILL RESULT IN AN IMMEDIATE AND PERMANENT REMOVAL FROM SITE.</p>
            </div>
          </Col>
          <Col sm={12} md={6} lg={12}>
            <div style={{ marginBottom: '1.25rem' }}>
              <p style={{ marginBottom: '0.5rem' }}></p>
            </div>
          </Col>
          <Col sm={12} md={6} lg={12}>
            <div style={{ marginBottom: '1.25rem' }}>
              <p style={{ marginBottom: '0.5rem' }}>Upload Product Photo</p>
              <UploadImages onFileAdded={(file) => { props.onImageUpload(file, 'Product_Image') }} picture={props.product.Product_Image} />
            </div>
          </Col>
        </Row>
      </Col>
      <Col lg={8}>
        <Form onSubmit={props.onSubmit}>
          <Row>
            <Col sm={12} md={6} lg={4}>
              <Form.Group controlId='Category'>
                <Form.Label>Category</Form.Label>
                <Form.Control as='select' value={JSON.stringify(props.product.Category)} onChange={props.onInputUpdate} required>
                  {selectOptions(props.configs.categories)}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Form.Group controlId='Subcategory'>
                <Form.Label>Subcategory</Form.Label>
                <Form.Control as='select' value={JSON.stringify(props.product.Subcategory)} onChange={props.onInputUpdate} disabled={!props.product.Category} required>
                  {selectOptions(props.configs.subcategories, 'Category', props.product.Category.id)}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={12} lg={4}>
              <Form.Group controlId='Tag'>
                <Form.Label>Product Type</Form.Label>
                <Form.Control as='select' value={JSON.stringify(props.product.Tag)} onChange={props.onInputUpdate} disabled={!props.product.Subcategory} required>
                  {selectOptions(props.configs.tags, 'Subcategory', props.product.Subcategory.id)}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={4}>
              <Form.Group controlId="Strain">
                <Form.Label>Strain / Product</Form.Label>
                <Form.Control value={props.product.Strain} onChange={props.onInputUpdate} required maxLength={40} />
              </Form.Group>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Form.Group controlId='Brand'>
                <Form.Label>Brand / Company</Form.Label>
                <Form.Control value={props.product.Brand} onChange={props.onInputUpdate} required maxLength={20} />
              </Form.Group>
            </Col>
            {/* <Col md={12} lg={4}>
              <Form.Group controlId='Area'>
                <Form.Label>Delivery Area</Form.Label>
                <Form.Control as='select' onChange={props.onInputUpdate} value={JSON.stringify(props.product.Area)} required>
                  {selectOptions(props.configs.areas)}
                </Form.Control>
              </Form.Group>
            </Col> */}
          </Row>
          <Row>
            <Col lg={6}>
              <Form.Group controlId='Description'>
                <Form.Label>Description</Form.Label>
                <Form.Control as='textarea' rows={3} value={props.product.Description} onChange={props.onInputUpdate} required />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group controlId='DeliveryDetails'>
                <Form.Label>Delivery Details</Form.Label>
                <Form.Control as='textarea' rows={3} value={props.product.DeliveryDetails} onChange={props.onInputUpdate} required />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            {showTHC()}
            {showTRP()}
            {showCBD()}

          </Row>
          <Row>
            <Col sm={12} md={6}>
              <Form.Group controlId='Price'>
                <Form.Label>Price</Form.Label>
                <NumberFormat thousandSeparator={false} 
                decimalScale={'2'} 
                fixedDecimalScale={true}
                allowNegative={false}
                name="Price" id="Price" class="form-control" value={props.product.Price} onChange={props.onInputUpdate} required />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='Amount_Available'>
                <Form.Label>Amount Available</Form.Label>
                <NumberFormat thousandSeparator={false} 
                 decimalScale={'0'}
                isNumericString={true}
                allowNegative={false}
                name="Amount_Available" id="Amount_Available" class="form-control" value={props.product.Amount_Available} onChange={props.onInputUpdate} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            {showProductTested()}
          </Row>
          <Row>
            <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {showDeleteButton()}
              <Button type='submit' variant='success'>Show Preview</Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
}

export default productForm;