import React, { Component } from 'react';
import { Container, Card, Tab } from 'react-bootstrap';
import { GET } from '../../../../helpers/requests'
import Aux from '../../../../hoc/Auxiliary/Auxiliary';
import styles from './../Dashboards.module.css';

export default class ListedProducts extends Component {
    _isMounted = false;
    state = {
        count: 0
      }
    componentDidMount() {
        this._isMounted = true;
        this.getCompletedOrders();
      }
    componentWillUnmount() {
        this._isMounted = false;
    }
    getCompletedOrders = async () => {
        const url = `/dashboards/totalproducts`
        const listedProducts = await GET(url);
        this.setState({
            "count": listedProducts.count
        });
    }
    render(){
        return(
            <Aux>
                <div className={styles.tile}>
                    <div >
                        <img className={styles.icon} src="https://static.thenounproject.com/png/1375593-200.png" alt='why did we not download this?'/>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.title}>LISTED PRODUCTS</div>
                        <div className={styles.body}>
                            {this.state.count}
                        </div>
                        <div className={styles.footer}>
                        
                        </div>
                    </div>
                </div>
            </Aux>
        )
    }
}