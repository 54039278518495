import React from 'react';
import { Card, Form, Col, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';

import UploadImages from '../../../components/UploadImages/UploadImages';
import NumberFormat from 'react-number-format';
import styles from '../Register.module.css';
import { ReactComponent as Info } from '../../../assests/images/info.svg';

const regiserForm = (props) => {

  const accountTypes = [
    {
      value: 'Grower',
    },
    {
      value: 'Processor',
    },
    {
      value: 'Dispensary'
    }
  ]
  
  const areas = [
    {
      value: 'Central',
    },
    {
      value: 'Northwest',
    },
    {
      value: 'Northeast'
    },
    {
      value: 'Southeast'
    },
    {
      value: 'Southwest'
    }
  ]
  
  const generateOptions = (list, valueKey, labelKey, idKey, placeholder) => {
    const options = list.map(item => {
      return <option key={item[idKey]} value={item[valueKey]}>{item[labelKey]}</option>
    })
    if(placeholder){
      options.unshift(<option value="" key='default'>Please Select One</option>)
    }
    return options;
  }

  const generateSalespeople = () => {
    let options = [];
    // if(props.formData.state && props.formData.area){
    //   options = props.salespeople.filter(person => person.area === props.formData.area).map(person => {
    //     return <option value={person.id} key={person.id} label={`${person.firstname} ${person.lastname}`}/>
    //   });
    // }
    if(props.salespeople){
      options = props.salespeople.map(person => {
        return <option value={person.id} key={person.id} label={`${person.firstname} ${person.lastname}`}/>
      })
    }
    options.unshift(<option value='N/A' key='N/A'>N/A</option>)
    return options;
  }

  return <div>
  <h2 style={{ textAlign: 'center' }}>Become a Member</h2>

  <Card className={styles.registerCard}>
    <Card.Body>
      <Card.Title style={{textAlign:'center'}}>Registration</Card.Title>
      <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
      <Form onSubmit={props.onSubmit}>
        <Form.Row>
          <Col sm={12} md={6} lg={4}>
            <Form.Group>
              <Form.Label>First Name</Form.Label>
              <Form.Control placeholder="Bob" id="firstname" required onChange={props.doInputChange} value={props.formData.firstname}/>
            </Form.Group>
          </Col>
          <Col sm={12} md={6} lg={4}>
            <Form.Group>
              <Form.Label>Last Name</Form.Label>
              <Form.Control placeholder="Smith" id="lastname" required onChange={props.doInputChange} value={props.formData.lastname}/>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group>
              <Form.Label>
                Business Name
                <OverlayTrigger key='top' placement='top' overlay={
                  <Tooltip id='tooltip-top'>
                    For verification and delivery
                  </Tooltip>
                }>
                  <Info width='1rem' height='1rem' fill='#306a39' style={{marginLeft:'0.5rem'}}/>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control placeholder="Business Name" id="business_name" required onChange={props.doInputChange} value={props.formData.business_name}/>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label>Business Address
                <OverlayTrigger key='top' placement='top' overlay={
                  <Tooltip id='tooltip-top'>
                    Delivery address if applicable
                  </Tooltip>
                }>
                  <Info width='1rem' height='1rem' fill='#306a39' style={{marginLeft:'0.5rem'}}/>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control placeholder="123 maple ave" id="address" required onChange={props.doInputChange} value={props.formData.address}/>
            </Form.Group>
          </Col>
          <Col sm={12} md={4} lg={3}>
            <Form.Group>
              <Form.Label>City</Form.Label>
              <Form.Control placeholder="City" id="city" required onChange={props.doInputChange} value={props.formData.city}/>
            </Form.Group>
          </Col>
          <Col sm={12} md={2}>
            <Form.Group>
              <Form.Label>State</Form.Label>
              <Form.Control placeholder="State" id="state" maxLength={2} required onChange={props.doStateChange} value={props.formData.state}/>
            </Form.Group>
          </Col>
          <Col sm={12} md={2} lg={3}>
            <Form.Group>
                <Form.Label>Zip</Form.Label>
                <Form.Control maxLength={5} minLength={5} placeholder="Zip" id="zip" required onChange={props.doInputChange} value={props.formData.zip}/>
            </Form.Group>
          </Col>
          <Col sm={12} md={3} lg={4}>
            <Form.Group>
              <Form.Label>Phone</Form.Label>
              <NumberFormat format="(###) ###-####" mask="_"
              name="phone" id="phone" className="form-control" value={props.formData.phone} onChange={props.doInputChange} required />
            </Form.Group>
          </Col>
          <Col sm={12} md={5} lg={4}>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="Email" id="email" required onChange={props.doInputChange} value={props.formData.email}/>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label>Area</Form.Label>
                <Form.Control as="select" id="area" required onChange={props.doInputChange}>
                  {generateOptions(areas, 'value', 'value', 'value', true)}            
                </Form.Control>
            </Form.Group>
          </Col>
          <Col sm={12} md={6} lg={4}>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control 
                type="password" 
                placeholder="Password" 
                id="password" 
                required 
                onChange={props.doInputChange} 
                value={props.formData.password}
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                title="Password must have 8 characters, an uppercase character, a lowercase character, and a number."
              />
            </Form.Group>
          </Col>
          <Col sm={12} md={6} lg={4}>
            <Form.Group>
              <Form.Label>Re-enter Password</Form.Label>
              <Form.Control 
                type="password" 
                placeholder="Re-enter Password" 
                id="password2" 
                required 
                pattern={RegExp.escape(props.formData.password)}
                title="Your passwords do not match"
              />
            </Form.Group>
          </Col>
          <Col sm={12} md={6} lg={4}>
            <Form.Group>
              <Form.Label>Account Type</Form.Label>
              <Form.Control as="select" id="accountType" required onChange={props.doInputChange}>
                {generateOptions(accountTypes, 'value', 'value', 'value', true)}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col sm={12} md={6} lg={4}>
            <Form.Group>
              <Form.Label>License Number</Form.Label>
              <Form.Control placeholder="ABDC-1G32-UN43" id="license_number" required onChange={props.doLicenseChange} 
              value={props.formData.license_number} 
              maxLength={14} 
              minLength={14}
              pattern="[PA-Z0-9|GA-Z0-9|DA-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}"
              title="Please enter a valid license number"
              />
            </Form.Group>
          </Col>
          <Col sm={12} md={6} lg={4}>
            <Form.Group>
                <Form.Label>OBNDD</Form.Label>
                <Form.Control maxLength={5} minLength={5} placeholder="12345" id="OBNDD" onChange={props.doInputChange} value={props.formData.OBNDD}/>
            </Form.Group>
          </Col>
          <Col sm={12} md={6} lg={4}>
            <Form.Group>
              <Form.Label>Sales Representative</Form.Label>
              <Form.Control as="select" id="salesrep" required disabled={props.formData.area === ''} onChange={props.doInputChange} value={props.formData.salesrep}>
                {generateSalespeople()}
              </Form.Control>
            </Form.Group>
          </Col>
          {/* <Col sm={12} md={6} lg={4}>
            <Form.Group>
              <Form.Label>Upload Photo of Business License</Form.Label>
              <UploadImages onFileAdded={(file) => { props.onImageUpload(file, 'license_image') }} picture={props.license_image} />
            </Form.Group>
          </Col> */}
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Check id="confirmed1" required label={<span>
                <span>I agree to the </span>
                <a href='#TermsOfUse' onClick={() => {props.showTerms('terms')}}>Terms of Use</a>
                <span> and </span>
                <a href='#Privacy'  onClick={() => {props.showTerms('privacy')}}>Privacy Policy</a>
                <span> for Green Leaf Trader LLC.</span></span>}/>
            </Form.Group>
          </Col>
        </Form.Row>
        <div align="center" className={styles.registrationButton}>
          <Button size="lg" variant="success" type="submit">Register</Button>
        </div>
      </Form>
    </Card.Body>
  </Card>
</div>
}

export default regiserForm;