import React from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';

import styles from './AccountInfoForm.module.css';

if(!RegExp.escape) {
  RegExp.escape = string =>{
    return String(string).replace(/[\\^$*+?.()|[\]{}]/g, '\\$&');
  };
}

const accountInfoForm = (props) => {
  
  const area = () => {
    let options = []
    options.push(<option value="" key="Select">Please Select One</option>);
    options.push(<option value="Central" key="central" selected={props.user.area === 'Central' ? props.user.area : ''}>Central</option>);
    options.push(<option value="Northwest" key="northwest" selected={props.user.area === 'Northwest' ? props.user.area : ''}>Northwest</option>);
    options.push(<option value="Northeast" key="northeast" selected={props.user.area === 'Northeast' ? props.user.area : ''}>Northeast</option>);
    options.push(<option value="Southeast" key="southeast" selected={props.user.area === 'Southeast' ? props.user.area : ''}>Southeast</option>);
    options.push(<option value="Southwest" key="southwest" selected={props.user.area === 'Southwest' ? props.user.area : ''}>Southwest</option>);
    return (
      <Form.Group as={Col} controlId="area">
        <Form.Label>Area</Form.Label>
        <Form.Control as="select" id="area" onChange={props.doInputChange} data-form='accountInfoForm' required >
          {options}
        </Form.Control>
      </Form.Group>
    )
  }
 

  const buildOptions = (optionArray, valueKey, labelKey) => {
    if (optionArray) {
      const options = optionArray.map(option => {
        return (
            <option key={option[valueKey]} value={option[valueKey]}>{option[labelKey]}</option>
        )
      })
      options.unshift(
          <option key='default' value=''>Please Select One</option>
      )
      return options
    }
    return <option key='default' value=''>Please Select One</option>;
  }

  return (
    <div>
    <h4>Account Information</h4>
    <Form onSubmit={props.doAccountUpdate}>
      <Row>
        <Col lg={4} md={6}>
          <Form.Group>
            <Form.Label>First Name</Form.Label>
            <Form.Control 
              type="text" 
              id='firstname' 
              value={props.user.firstname} 
              placeholder='Doug' 
              onChange={props.doInputChange}
              data-form='accountInfoForm'
            />
          </Form.Group>
        </Col>
        <Col lg={4} md={6}>
          <Form.Group>
            <Form.Label>Last Name</Form.Label>
            <Form.Control 
              type="text" 
              id='lastname' 
              value={props.user.lastname}  
              placeholder='Jones'
              onChange={props.doInputChange}
              data-form='accountInfoForm'
            />
          </Form.Group>
        </Col>
        <Col lg={4} md={6}>
          <Form.Group>
            <Form.Label>Business Name</Form.Label>
            <Form.Control 
              type="text" 
              id='business_name' 
              value={props.user.business_name}  
              placeholder="Dank Doug's Dispensary"
              onChange={props.doInputChange}
              data-form='accountInfoForm'
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Form.Group>
            <Form.Label>Business Address</Form.Label>
            <Form.Control 
              type="text" 
              id='address' 
              value={props.user.address} 
              placeholder='123 Easy St.'
              onChange={props.doInputChange}
              data-form='accountInfoForm'
            />
          </Form.Group>
        </Col>
        <Col lg={3} md={3}>
          <Form.Group>
            <Form.Label>City</Form.Label>
            <Form.Control 
              type="text" 
              id='city' 
              value={props.user.city}  
              placeholder='Nowhere'
              onChange={props.doInputChange}
              data-form='accountInfoForm'
            />
          </Form.Group>
        </Col>
        <Col md={2}>
          <Form.Group>
            <Form.Label>State</Form.Label>
            <Form.Control 
              type="text" 
              id='state' 
              value={props.user.state}  
              placeholder="OK" 
              maxLength="2"
              minLength="2"
              onChange={props.soStateChange}
              data-form='accountInfoForm'
            />
          </Form.Group>
        </Col>
        <Col lg={3} md={3}>
          <Form.Group>
            <Form.Label>ZIP Code</Form.Label>
            <Form.Control 
              type="text" 
              id='zip' 
              value={props.user.zip}  
              placeholder='12345'
              onChange={props.doInputChange}
              data-form='accountInfoForm'
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={6}>
          <Form.Group>
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
                type="tel"
                id='phone'
                value={props.user.phone}
                placeholder='5555555555'
                onChange={props.doInputChange}
                data-form='accountInfoForm'
            />
          </Form.Group>
        </Col>
        <Col lg={4} md={6}>
          <Form.Group>
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type='email'
              id='email'
              value={props.user.email}
              placeholder='test@example.com'
              onChange={props.doInputChange}
              data-form='accountInfoForm'
            >
            </Form.Control>
          </Form.Group>
        </Col>
        <Col lg={4} md={6}>
          {area()}
          {/* <Form.Group>
            <Form.Label>Area</Form.Label>
            <Form.Control
                as='select'
                id='area'
                value={props.user.area}
                placeholder='OKC'
                onChange={props.doInputChange}
                data-form='accountInfoForm'
            >
              {buildOptions(props.areas, 'id', 'Name')}
            </Form.Control>
          </Form.Group> */}
        </Col>
      </Row>
      <div className={styles.buttonContainer}>
        <Button variant='success' type='submit' onClick={props.handleAccountSubmit} disabled={props.disableAccountSubmit()}>Submit</Button>
      </div>
    </Form>
  </div>
  )
}

export default accountInfoForm;