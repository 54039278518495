import React from 'react';
import { Button } from 'react-bootstrap';

import Aux from '../../../hoc/Auxiliary/Auxiliary';
import Modal from '../../../components/Modal/Modal';
import ProductListItem  from '../../Products/ProductList/ProductListItem/ProductListItem';

const submitModal = (props) => {
  const product = {
    ...props.product,
    Subcategory: props.product.Subcategory.Name,
    Price_UOM: props.product.Price_UOM.Name
  }

  const actions = () => {
    return(
      <Aux>
        <Button variant='success' onClick={props.onSubmit}>Submit</Button>
        <Button variant='danger' onClick={props.onHide}>Back to Edit</Button>
      </Aux>
    )
  }

  return(
    <Modal show={props.show} onHide={props.onHide} title='Product Preview' actions={actions()}>
      <div style={{display: 'flex', justifyContent:'center'}}>
        <ProductListItem {...product} user={props.user}/>
      </div>
    </Modal>
  )
}

export default submitModal;