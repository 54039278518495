import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Modal from '../../../../components/Modal/Modal';
import Aux from '../../../../hoc/Auxiliary/Auxiliary';
import CancellationPolicy from '../CancellationPolicy/CancellationPolicy';

const confirmCancel = (props) => {
  const content = () => {
    if(!props.showCancellationPolicy){
      return(
        <div>
          <p>
            We are sorry that you are leaving the Green Lead Trader. We would love a chance to better meet your needs in the future.
            Feedback is always appreciated.
          </p>
          <Form>
          <Form.Group>
                <Form.Control 
                  as="textarea"
                  id='cancellationReason' 
                  maxLength={255}
                  rows={3}
                  placeholder="Please leave a brief description of why you are leaving."
                  onChange={props.doInputChange}
                  value={props.cancellationReason}
                />
              </Form.Group>
          </Form>
          <p>Please review our <Link to='#cancellationPolicy' onClick={props.toggleShowPolicy}>Cancellation Policy</Link></p>
        </div>
      )
    }
    else{
      return (
        <Aux>
          <CancellationPolicy />
        </Aux>
      )
    }
  }

  const actions = () => {
    if(props.showCancellationPolicy){
      return <Button variant="success" onClick={props.toggleShowPolicy}>OK</Button> 
    }
    return(
      <Aux>
        <Button variant="danger" onClick={props.doCancellation}>Cancel Subscription</Button>
        <Button variant="success" onClick={props.onHide}>Go Back</Button>
      </Aux>
    )
  }

  return(
    <Modal title='Confirm Cancellation' show={props.show} actions={actions()} onHide={props.onHide} showbutton="true">
      {content()}
    </Modal>
  )
}

export default confirmCancel;