import React from 'react';
import Modal from 'react-bootstrap/Modal';

const modal = (props) => {
  const modalHeader = () => {
    if(props.showbutton === 'true' || props.title){
      return (
        <Modal.Header closeButton={props.showbutton === 'true'}>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.title}
          </Modal.Title>
        </Modal.Header>
      )
    }
  }

  const modalFooter = () => {
    if(props.actions){
      return(
        <Modal.Footer>
          {props.actions}
        </Modal.Footer>
      )
    }
  }
  return(
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {modalHeader()}
      <Modal.Body>
        {props.children}
      </Modal.Body>
      {modalFooter()}
    </Modal>
  )
}

export default modal;