import React from 'react';
import { Button } from 'react-bootstrap';

import Modal from '../../../components/Modal/Modal';

import styles from './AgeVerify.module.css';

const ageVerify = (props) => {
  return(
    <Modal 
      show={props.showAgeVerify} 
    >
      <div className={styles.ageVerifyContainer}>
        <Button variant='success' className={styles.button} onClick={props.updateModal}>21 +</Button>
        <Button variant='danger' className={styles.button} onClick={props.under21Redirect}>Under 21</Button>
      </div>
      <div className={styles.ageVerifyText}>
        <p>By entering this site, I am agreeing that I am over the age of 21</p>
      </div>
    </Modal>
  )
}

export default ageVerify;