import React from 'react';
import { Button, Navbar, Nav, Form, Table }  from 'react-bootstrap';
import NewUser from './NewUser/NewUser';

const existingusers = (props) => {
  const divStyle = {
    display: 'flex'
    
  };
  const getExistingUsers = () => {
    let users = []
    if(props.ExistingUsers){
      props.ExistingUsers.forEach(result => {
        let accountType = '';
        let salesRep = 'N/A';
        if(result.SalesRep && result.SalesRep.firstname !== null){
          salesRep =  result.SalesRep.firstname + ' ' + result.SalesRep.lastname;
        }
        if (result.users.isAdmin) accountType = accountType + 'Admin/';
        if (result.users.isDispensary) accountType = accountType + 'Dispensary/';
        if (result.users.isGrower) accountType = accountType + 'Grower/';
        if (result.users.isProcessor) accountType = accountType + 'Processor/';
        if (result.users.isSalesRep) accountType = accountType + 'Sales Rep';
            users.push(
              <tr key={result.users.id}>
                <td>{result.users.firstname}</td>
                <td>{result.users.lastname}</td>
                <td>{result.users.username}</td>
                <td>{accountType}</td>
                <td>{result.users.license_number}</td>
                <td>{result.users.phone}</td>
                <td>{salesRep}</td>
                <td>
                  <div style={divStyle}>
                    <Button variant="info" onClick={props.doHandleEdit} data-id={result.users.id} ><img src="https://cdn2.iconfinder.com/data/icons/flat-ui-icons-24-px/24/new-24-24.png" height="20px" style={{pointerEvents: 'none'}}></img></Button>
                    <Button variant="info" onClick={props.doHandlePwdReset} data-id={result.users.id} data-value={result.users.email}><img src="https://image.flaticon.com/icons/svg/2746/2746103.svg" height="20px" style={{pointerEvents: 'none'}}></img></Button>
                  </div>
                </td>
              </tr>
            )
          });
    }
    return users;
  }
  return(
    <div>
      <Navbar bg="light" variant="light">
        <Nav className="mr-auto">
        <select name="listView" onChange={props.listViewChange}>
          <option value="">All Users</option>
          <option value="active">Active Users</option>
          <option value="dispensaries">Dispensaries</option>
          <option value="processors">Processors</option>
          <option value="growers">Growers</option>
          <option value="inactive">Inactive Users</option>
        </select>
        </Nav>
        <Form inline>
          <Button variant="info" onClick={props.handleNewUserClick}>New User</Button>
        </Form>
      </Navbar>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Username</th>
            <th>Account Type</th>
            <th>License#</th>
            <th>phone</th>
            <th>SalesRep</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
        {getExistingUsers()}
        </tbody>
      </Table>
      <br />
      <br />
      <NewUser {...props} handleClose={props.handleClose} doSubmit={props.doHandleSubmit}/>
    </div>
  )
}
export default existingusers;