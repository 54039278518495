import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Container } from 'react-bootstrap';

import User from '../../helpers/user';
import AccountInfoForm from './AccountInfoForm/AccountInfoForm';
import PasswordForm from './PasswordForm/PasswordForm';
import ManageSubscription from './ManageSubscription/ManageSubscription';
import { PUT, POST, GET } from '../../helpers/requests';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import Modal from '../../components/Modal/Modal';
import Toast from '../../components/Toast/Toast';

import styles from './Account.module.css';

class Account extends Component {
  user = new User();

  state = {
    user: this.user.getUser(),
    accountInfoForm: {
      firstname: '',
      lastname: '',
      business_name: '',
      phone: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      email:'',
      area:''
    },
    passwordForm: {
      oldPassword: '',
      newPassword: '',
      newPassword2: ''
    },
    toastConfig: {},
    showToast: false,
    showCancellationModal: false,
    showCancellationPolicy: false,
    cancellationReason: ''
  }

  componentDidMount = () => {
    const {
      firstname, lastname, business_name,
      phone, address, city, state, zip, email,
      area
    } = this.state.user;
    this.setState({
      accountInfoForm: {
        firstname,
        lastname,
        business_name,
        phone,
        address,
        city,
        state,
        zip,
        email,
        area
      }
    }, async () => {
      const url = '/configs/areas';
      const response = await GET(url);
      if(response.toast){
        this.setState({...response.toast});
      }
      else{
        this.setState({
          areas:[...response]
        })
      }
    })
  }

  canSubmitAccountUpdate = () => {
    const keys = Object.keys(this.state.accountInfoForm);
    let result = true;
    for(let i=0; i<keys.length; i++){
      if(this.state.accountInfoForm[keys[i]] !== this.state.user[keys[i]]){
        result = false;
        break
      }
    }
    return result
  }

  canSubmitPasswordUpdate = () => {
    const passwordForm = this.state.passwordForm;
    const passwordsAreBlank = passwordForm.oldPassword === '' || passwordForm.newPassword === '' || passwordForm.newPassword2 === ''
    const passwordsMatch = passwordForm.oldPassword === passwordForm.newPassword && passwordForm.oldPassword !== '';
    return passwordsAreBlank || passwordsMatch;
  }

  handleInputChange = (event) => {
    const newState = {};
    const form = event.target.dataset.form;
    const field = event.target.id;

    if(form){
      newState[form] = {...this.state[form]};
      newState[form][field] = event.target.value;
    }
    else{
      newState[field] = event.target.value;
    }

    this.setState({...newState});
  }

  handleStateChange = (event) => {
    event.target.value = event.target.value.toUpperCase();
    this.handleInputChange(event);
  }

  handlePasswordUpdate = async event => {
    event.preventDefault();
    const url = '/users/changepassword';
    const config = {
      successMessage: 'Password has been updated',
      errorMessage: 'The current password you entered is invalid. Please try again.',
      body: {
        id: this.state.user.id,
        password: this.state.passwordForm.newPassword,
        oldPassword: this.state.passwordForm.oldPassword
      },
      component: this
    }

    const response = await POST(url, config);
    this.setState({
      ...response.toast,
      passwordForm: {
        oldPassword: '',
        newPassword: '',
        newPassword2: ''
      }
    })
  }

  handleAccountUpdate = async event => {
    event.preventDefault();
    const url = `/users/${this.state.user.id}`;
    const config = {
      successMessage: 'Account information has been updated',
      body: this.state.accountInfoForm,
      component: this
    }

    const response = await PUT(url, config);
    if(response.status === 200){
      this.setState(prevState => ({
        user: this.user.setUser({
          ...prevState.user,
          ...this.state.accountInfoForm
        })
      }))
    }
    this.setState({...response.toast})
  }

  handleCancellation = async event => {
    event.preventDefault();
    this.handleToggleModal('showCancellationModal', false);
    const url = `/subscriptions/cancellation/${this.state.user.id}`
    const config = {
      body: {
        reason: this.state.reason,
        paypalid: this.state.user.paypal_id
      },
      successMessage: 'Subscription cancelled.',
      component: this
    }

    try{
      const response = await POST(url, config);
      if(response.status === 200){
        this.handleToggleModal('showGoodbye', true);
      }
    }
    catch(error){
      console.log(error);
    }
  }

  handleToggleModal = (modalName, value) => {
    const newState = {};
    newState[modalName] = value;
    this.setState({...newState});
  }

  handleGoodbye = () => {
    this.setState({showGoodbye: false}, () => {
      this.props.history.push('/#Logout');
    })
  }

  render = () => {
    return (
      <Aux>
        <Modal title='Thank You!' show={this.state.showGoodbye} onHide={this.handleGoodbye}>
          <div>
            <p>
              Thank you for doing business with Green Leaf Trader. We are sad to see
              you go, and hope to be able to serve your needs again soon.
            </p>
          </div>
        </Modal>
        <Toast {...this.state.toastConfig} show={this.state.showToast} onClose={() => {this.setState({showToast:false})}}/>
        <Container className={styles.container}>
          <AccountInfoForm 
            user={this.state.accountInfoForm} 
            doInputChange={this.handleInputChange}
            soStateChange={this.handleStateChange}
            disableAccountSubmit={this.canSubmitAccountUpdate}
            doAccountUpdate={this.handleAccountUpdate}
            areas={this.state.areas}
          />
          <PasswordForm 
            passwordForm={this.state.passwordForm}
            doInputChange={this.handleInputChange}
            disablePasswordSubmit={this.canSubmitPasswordUpdate}
            doPasswordUpdate={this.handlePasswordUpdate}
          />
          <ManageSubscription 
            showCancel={this.state.showCancellationModal}
            showSuspend={this.state.showSuspensionModal}
            onHide={modalName => {this.handleToggleModal(modalName, false)}}
            onShow={modalName => {this.handleToggleModal(modalName, true)}}
            showCancellationPolicy={this.state.showCancellationPolicy}
            toggleShowPolicy = {() => {this.setState(prevState => ({showCancellationPolicy: !prevState.showCancellationPolicy}))}}
            doInputChange={this.handleInputChange}
            cancellationReason={this.state.cancellationReason}
            doCancellation={this.handleCancellation}
            doSuspension={this.handleSuspension}
          />
        </Container>
      </Aux>
    )
  }
}

export default withRouter(Account);