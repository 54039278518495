import React from 'react';

import Aux from '../../../hoc/Auxiliary/Auxiliary'

import OrderItem from '../OrderItem/OrderItem';
const sold = (props) => {
  const orderItem = () => {
    if(props.lists.length > 0){
      return props.lists.map(list => {
        return <OrderItem key={list.id} list={list} view='sold' showDeliveryAddress/>
      })
    }
    return <h4>No Sold History</h4>
  }

  return(
    <Aux>
      {orderItem()}
    </Aux>
  )
}

export default sold;