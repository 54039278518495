import React from 'react';
import Modal from '../../../components/Modal/Modal';

const copyright = (props) => {
  return (
  <Modal show={props.show} title='Copyright Notice' onHide={props.onHide}>
    <p>© 2020, Green Leaf Trader, LLC.  All rights reserved.</p>
    <p>
      The Green Leaf Trader website, software source and object code (the “Software”) and its related documentation
      (the “Documentation”) contains original works of authorship owned and copyrighted by Green Leaf Trader, LLC.
    </p>
    <p>DISCLAIMER:</p>
    <p>
      THE SOFTWARE, WEBSITE AND DOCUMENTATION ARE PROVIDED “AS IS”, WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED,
      INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
      NONINFRINGEMENT OF THIRD-PARTY RIGHTS.  IN NO EVENT SHALL THE COPYRIGHT HOLDER OR HOLDERS INCLUDED IN THIS NOTICE
      BE LIABLE FOR ANY CLAIM, OR ANY SPECIAL INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING
      FROM LOSS OF USE, DATA OR PROFITS, WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION,
      ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THE SOFTWARE, THE WEBSITE OR THE DOCUMENTATION.
    </p>
    <p>
      NO PART OF THE SOFTWARE, WEBSITE OR DOCUMENTATION MAY BE REPRODUCED, SCANNED, OR DISTRIBUTED IN ANY PRINTED OR
      ELECTRONIC FORM WITHOUT EXPRESS WRITTEN PERMISSION. PLEASE DO NOT PARTICIPATE IN OR ENCOURAGE PIRACY OF
      COPYRIGHTED MATERIALS IN VIOLATION OF THE COPYRIGHT HOLDER OR HOLDERS RIGHTS.
    </p>
    <p>REQUESTS FOR PERMISSION MAY BE MADE TO:</p>
    <p>
      Green Leaf Trader, LLC<br/>
      Attn: Manager<br/>
      E-Mail: <a href='mailto:​info@greenleaftrader.com'>info@greenleaftrader.com</a>
    </p>
  </Modal>
  )
}

export default copyright;