import React from 'react';
import Resizer from 'react-image-file-resizer';
import { Document, Page } from 'react-pdf';

import styles from './UploadImages.module.css';

const uploadImages = (props) => {
  const displayImage = () => {
    if(props.picture){
      const type = props.picture.split(';')[0];
      if(type !== 'data:application/pdf') {
        return (
          <img  src={props.picture} alt='Upload failed' className={styles.image}/>
        );
      }
      else{
        return (
          <Document file={props.picture}>
            <Page pageNumber={1}/>
          </Document>
        )
      }
    }
  }

  const getFiles = (event) => {
    let fileInput = event.target.files[0]
    if(fileInput) {
      let fileType = fileInput.type;
      if(fileType !== 'application/pdf') {
        Resizer.imageFileResizer(
          event.target.files[0],
          props.maxwidth || 400, //maxWidth
          props.maxheight || 400, //maxHeight
          props.compressformat || 'JPEG',//compressFormat
          props.quality || 100,//quality
          props.rotation || 0,//rotation
          uri => { // callback
            props.onFileAdded(uri)
          },
          props.outputtype || 'base64' //outputtype
        );
      }else{
        getBase64(fileInput, (result) => {
          props.onFileAdded(result);
        });
         
      }
    }
    
    
  };
  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      cb(reader.result);
    }
    reader.onerror = (error) => {
      console.error('Error', error);
    }
  }
  return (
    <div>
      <div>
        <input type="file" onChange={getFiles} id='fileInput'/>
      </div>
      <div className={styles.imageContainer}>
        {displayImage()}
      </div>
    </div>
  )
}
export default uploadImages