import React from 'react';
import { Button } from 'react-bootstrap';

import Modal from '../../../components/Modal/Modal';
import Quantity from '../../Product/Quantity/Quantity';
import Aux from '../../../hoc/Auxiliary/Auxiliary';

const edit = (props) => {
  const actions = () => {
    return(
      <Aux>
        <Button variant='danger' onClick={props.onHide}>Cancel</Button>
        <Button variant='success' onClick={props.handleSubmitEdit}>OK</Button>
      </Aux>
    )
  }

  return (
    <Modal show={props.show} title="Edit Quantity" actions={actions()} onHide={props.onHide}> 
      <Quantity 
        quantity={props.quantity || 0} 
        max={props.max} 
        decrementQuantity={props.decrementQuantity} 
        incrementQuantity={props.incrementQuantity}
        onChange={props.onChange}
      />
    </Modal>
  )
}

export default edit;