import React from 'react';
import styles from './Pricing.module.css';
import { Button, Card, Row, Col } from 'react-bootstrap';
import HunderedPercentFree from '../../../assests/images/100PercentFree.png';
import SixtyDayTrial from '../../../assests/images/60dayTrial.png';


const pricing = (props) => {
    return (
        <div style={{ marginBottom: '50px' }}>
            <h2 style={{ textAlign: 'center' }}>The Green Leaf Trader platform is FREE for all registered cannabis entities.</h2>
            <p style={{ textAlign: 'center' }}>This site is FREE to all dispensaries to ensure maximum listings, a larger selection of products, and ease of ordering.</p>
            <p style={{ textAlign: 'center' }}>This site is FREE to all growers and processors to quickly expand the market place, provide unlimited product uploads, and streamline order management.</p>
            <div className={styles.content}>
                {/* <div>
                    <img src={HunderedPercentFree} className={styles.image} alt='100% Free' />
                </div> */}
                <div>
                    <ul className={styles.featureItems}>
                        <li>✔ No Hidden Costs</li>
                        <li>✔ Increased Sales</li>
                        <li>✔ Product Sustainability</li>
                        <li>✔ Unlimited Orders</li>
                        <li>✔ Unlimited Products</li>
                        <li>✔ Unlimited Buyers</li>
                    </ul>
                </div>
                {/* <div>
                    <img src={SixtyDayTrial} className={styles.image} alt='100% Free' />
                </div> */}
            </div>
            <div className={styles.group}>
                <Row className={styles.row}>
                    <Col lg={6} className={styles.col}>
                        <div align='center'>
                            <img src={HunderedPercentFree} className={styles.image} alt='100% Free' />
                        </div>
                        <Card bg='dark' text='white'>
                            <Card.Header className={styles.cardHeader}><h2>Buyer</h2></Card.Header>
                            <Card.Body className={styles.cardBody}>
                                <ul className={styles.price}>
                                    <li className={styles.grey}>100% FREE<br /> FOR DISPENSARIES</li>
                                    <div className={styles.items}>
                                        <li>No Hidden Costs</li>
                                        <li>Unlimited Orders</li>
                                        <li>Ability to Request Products 24/7</li>
                                        <li>Real-Time Product Menu</li>
                                        <li>No Contract - Cancel Anytime</li>
                                    </div>
                                    <li className={styles.grey}><Button variant='success' onClick={() => { props.registrationType('Dispensary') }}>Sign Up</Button></li>
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col lg={6} className={styles.col}>
                        <div align='center'>
                            <img src={HunderedPercentFree} className={styles.image} alt='100% Free' />
                        </div>
                        <Card bg='warning'>
                            <Card.Header className={styles.cardHeader}><h2>Seller</h2></Card.Header>
                            <Card.Body className={styles.cardBody}>
                                <ul className={styles.price}>
                                    <li className={styles.grey}>100% FREE<br />FOR GROWERS AND PROCESSORS</li>
                                    <div className={styles.items}>
                                        <li>No Hidden Costs</li>
                                        <li>1 Company per State</li>
                                        <li>2 Brands</li>
                                        <li style={{ backgroundColor: '#ffc107' }} >UNLIMITED LISTINGS</li>
                                        <li>Ability to Sell Products 24/7</li>
                                        <li>No Contract - Cancel Anytime</li>
                                    </div>
                                    <li className={styles.grey}><Button variant='success' onClick={() => { props.registrationType('Premium') }}>Sign Up</Button></li>
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
            {/* <div style={{ marginTop: '26px' }}>
                <p style={{ textAlign: 'center' }}>* Seller will be charged on a reoccuring monthly basis.<br />You may cancel at any time however we do not offer prorated refunds.</p>
            </div> */}
        </div>
    );
}
export default pricing;