import React from 'react';

import Modal from '../../../components/Modal/Modal';

const description = (props) => {
  return(
    <Modal show={props.show} onHide={props.onHide} title={props.title} showbutton='true'>
      <p>
        {props.description}
      </p>
    </Modal>
  )
}

export default description;