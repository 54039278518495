import React, { Component } from 'react';
import { Container, Button } from 'react-bootstrap';
import { withRouter } from 'react-router';

import Aux from '../../hoc/Auxiliary/Auxiliary';
import Toast from '../../components/Toast/Toast';
import Modal from '../../components/Modal/Modal';
import ProductForm from './ProductForm/ProductForm';
import User from '../../helpers/user';
import { POST, GET } from '../../helpers/requests';
import SubmitModal from './SubmitModal/SubmitModal';
import DeleteModal from './DeleteModal/DeleteModal';

import styles from './NewProduct.module.css';

class NewProduct extends Component {
  user = new User();

  _isMounted = false;

  state={
    showToast: false,
    showModal: false,
    showSubmitModal: false,
    showDeleteModal: false,
    toastConfig: {
      variant: 'danger',
      title: 'Server Error',
      message: 'Something went wrong. Please try again later.',
      autohide: true,
      delay: 10000
    },
    product:{
      Strain: "",
      Lab_Image: "",
      Product_Image: "",
      Brand: "",
      Description: "",
      DeliveryDetails: "",
      THC: "",
      TRP: "",
      CBD: "",
      Category: "",
      Subcategory: "",
      Tag: "",
      Price: "",
      Amount_Available: "",
      Price_UOM: "",
      Tested: "",
    },
    user: this.user.getUser(),
    managelistings: false
  }

  updateModal = (modalShowProp) => {
    const modalState = {};
    modalState[modalShowProp] = !this.state[modalShowProp];
    this.setState(modalState);
  }

  clearProduct = () => {
    const product = {...this.state.product};
    Object.keys(product).forEach(key => {
      if(key !== 'Area' && key !== 'Owner'){
        product[key] = '';
      }
    })
    this.setState({product}, () => {
      this.updateModal('showModal');
    })
    document.getElementById('fileInput').value='';
  }

  loadAllConfigs = async () => {
    const url = `${this.props.host}/configs`;
    const response = await GET(url);
    if(Object.keys(response).includes('toast')){
      this.setState({...response.toast});
    }
    else{
      this.setState({configs:{...response}}); 
    }
  }

  getUserArea = (userArea, areas) => {
      return areas.filter(area => {
        return area.id === userArea
      })[0]
  }

  handleInputChange = (event) => {
    const attr = event.target.id;
    const product = {...this.state.product};
    product[attr] = event.target.value;
    if(product[attr][0] === '{'){
      product[attr] = JSON.parse(product[attr]);
    }
    this.setState({product: {...product}});   
  }

  handleFileUpload = (file, fileAttr) => {
    const product = this.state.product;
    if(fileAttr && file){
      product[fileAttr] = file;
    }
    this.setState({
      product:{...product}
    });
  }

  goToHome = () => {
    this.props.history.push('/');
  }

  duplicateProductModalActions = () => {
    return(
      <Aux>
        <Button variant='success' onClick={this.clearProduct}>Yes</Button>
        <Button variant='danger' onClick={this.goToHome}>No</Button>
      </Aux>
    )
  }

  handleSubmit = (doDelete) => {
    this.setState({showSubmitModal: false}, async () => {
      const hasPictures = this.validateImage();
      if(hasPictures){
        const url = this.state.managelistings ?  `/products/${this.state.product.id}` : '/products';
        const product = {...this.state.product};
        delete product.createdAt;
        delete product.updateAt;
        const config = {
          body: {
            ...product,
            // Area: this.state.product.Area.id,
            Area: this.state.user.area,
            Category: this.state.product.Category.Name,
            Subcategory: this.state.product.Subcategory.Name,
            Tag: this.state.product.Tag.Name,
            Price_UOM: this.state.product.Price_UOM.Name,
            Tested: this.state.product.Tested.id,
            Owner: this.state.user.id,
            Active: !doDelete
          },
          successMessage: this.state.managelistings && doDelete ? 'Product deleted' : this.state.managelistings ? 'Product updated' : 'Product created',
          component: this
        }
        const response = await POST(url, config);

        this.setState({
          ...response.toast,
          showModal: response.status && response.status === 200 && !this.state.managelistings
        },() =>{
          if(this.state.managelistings){
            window.setTimeout(() =>{
              this.props.history.push('/manage-listings');
            }, 3000); 
          }
        })
      }
    })
  }

  handleDelete = () => {
    this.handleSubmit(true);
  }

  promptSubmit = (event) => {
    event.preventDefault();
    this.setState({
      showSubmitModal: true
    })
  }

  promptDelete = () => {
    this.setState({
      showDeleteModal: true
    })
  }

  validateImage = () => {
    const product= this.state.product;
    if (product.Product_Image === '') {
      this.setState(prevState => (
        {
          toastConfig: {
            ...prevState.toastConfig,
            title: 'Form Error',
            message: 'Please include a product image and a test results sheet',
            delay: 10000,
            variant: 'danger'
          },
          showToast: true
        }
      ))
      return false;
    }
    return true;
  }
  getProduct = async(productId) => {
    const url = `${this.props.host}/products/edit/${productId}`;
    const product = await GET(url);
    if(Object.keys(product).includes('toastConfig')){
      this.setState({...product});
    }
    else{
      product[0].Products.Category = product[0].Category;
      product[0].Products.Subcategory = product[0].Subcategory;
      product[0].Products.Tag = product[0].Tags;
      product[0].Products.Price_UOM = {"id": product[0].Products.Price_UOM, "Name": product[0].Products.Price_UOM};
      product[0].Products.Tested = {"id": product[0].Products.Tested, "Name": product[0].Products.Tested ?
            "Yes" : "No"};
      this.setState({"product": {
          ...product[0].Products,
          TRP: product[0].Products.TRP || ''
        }})
    }
  }
  componentDidMount = () => {
    this._isMounted = true;
    this.loadAllConfigs();
    let isManageListing = false;
    if(this.props.location.pathname.split('/')[1] === 'manage-listings'){
      isManageListing = true;
      this.getProduct(this.props.location.pathname.split('/')[2]);
    }
    this.setState({managelistings: isManageListing});
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  }

  render() {
    return(
      <Aux>
        <SubmitModal 
          show={this.state.showSubmitModal} 
          onHide={() => {this.updateModal('showSubmitModal')}} 
          product={this.state.product}
          user={this.state.user}
          onSubmit={() => this.handleSubmit(false)}
        />
        <DeleteModal 
          show={this.state.showDeleteModal}
          onHide={() => {this.updateModal('showDeleteModal')}}
          onDelete={this.handleDelete}
        />
        <Toast 
          show={this.state.showToast}
          title={this.state.toastConfig.title}
          message={this.state.toastConfig.message}
          delay={this.state.toastConfig.delay}
          autohide={this.state.toastConfig.autohide}
          variant={this.state.toastConfig.variant}
          onClose={() => {this.setState({showToast:false})}}
        />
        <Modal show={this.state.showModal} actions={this.duplicateProductModalActions()}>
          <div>
            <h5>Would you like to add another product?</h5>
          </div>
        </Modal>
        <Container className={styles.container}>
          <ProductForm 
            configs={{...this.state.configs}}
            onInputUpdate={this.handleInputChange}
            onImageUpload={this.handleFileUpload}
            product={{...this.state.product}}
            isManageListing={this.state.managelistings}
            onSubmit={this.promptSubmit}
            onDelete={this.promptDelete}
          />
        </Container>
      </Aux>
    )
  }
}

export default withRouter(NewProduct);