import React from 'react';

import OrderItem from './OrderItem/OrderItem';
import Aux from '../../../hoc/Auxiliary/Auxiliary';

const order = (props) => {
  const orderItems = () => {
    if(props.order && props.order.products && Array.isArray(props.order.products)){
      return props.order.products.map(product => {
        return <OrderItem {...product} key={product.id} onDelete={props.onDelete} onEdit={props.onEdit}/>
      })
    }
    return <div/>  
  }

  return(
    <Aux>
      {orderItems()}
    </Aux>
  )
}

export default order;