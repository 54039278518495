import React from 'react';
import { Toast } from 'react-bootstrap';

import styles from './Toast.module.css';

const toast = (props) => {
  
  const variant = () => {
    const result = props.variant;
    if(result !== undefined){
      return props.variant.toLowerCase();
    }
    return
  }

  const toastClasses = () => {
    let result = `${styles.toast} ${styles[variant()]}`;
    if(!props.show){
      result += ` ${styles.hidden}`;
    }
    return result;
  }

  return(
    <div className={styles.toastContainer}>
      <Toast 
        className={toastClasses()} 
        autohide={props.autohide} 
        onClose={props.onClose} 
        show={props.show}
        delay={props.delay}
      >
        <Toast.Header className={`${styles.header} ${styles[variant()]}`} closeButton>
          <strong className="mr-auto">{props.title}</strong>
          <small>{props.time}</small>
        </Toast.Header>
        <Toast.Body className={`${styles.body} ${styles[variant()]}`}>{props.message}</Toast.Body>
      </Toast>  
    </div>
  )
}

export default toast;