import React, { Component } from 'react';
import Aux from '../../../hoc/Auxiliary/Auxiliary';
import styles from './../Dashboards.module.css';
import { GET } from '../../../helpers/requests'
import User from '../../../helpers/user';
import { Link } from 'react-router-dom';
export default class LowAvailabilityProducts extends Component {
    user = new User();

    _isMounted = false;
  
    state = {
      user: this.user.getUser()
    }
    componentDidMount() {
        this._isMounted = true;
        this.getLowAvailabilityProducts();
      }
    componentWillUnmount() {
        this._isMounted = false;
    }
    getLowAvailabilityProducts = async () => {
        const url = `/dashboards/listedproducts/lowavailability/${this.state.user.id}`
        const results = await GET(url);
        let showChart = results.length > 0;
        const data = results.map((item) => {
            return(
                <tr>
                    <td>{item.Brand}</td>
                    <td>{item.Strain}</td>
                    <td>{item.Amount_Available}</td>
                    <td><Link to={`/manage-listings/${item.Id}`}>Manage</Link></td>
                </tr>
            )
        })
        this.setState({
            "showChart": showChart,
            "data": data
        });
    }
    chartDetails = () =>{
        if(this.state.showChart){
            return(
                <div className={styles.chartContainer}>
                    <div className={styles.chartTitle}>PRODUCTS WITH LOW STOCK</div>
                    <div>
                        <table>
                            <thead>
                                <tr>
                                    <th>Brand</th>
                                    <th>Product</th>
                                    <th>Available Amount</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.data}
                            </tbody>
                        </table>
                    </div>
                </div>
            );
        }else{
            return(
                <span></span>
            )
        }
    }
    render(){
       return ( 
            <Aux>
                {this.chartDetails() }
            </Aux>  
        )
    }
}
