import React, { Component } from 'react';
import { Container, Card, Tab } from 'react-bootstrap';
import { GET } from '../../../../helpers/requests'
import Aux from '../../../../hoc/Auxiliary/Auxiliary';
import styles from './../Dashboards.module.css';

export default class ActiveProcessors extends Component {
    _isMounted = false;
    state = {
        count: 0
      }
    componentDidMount() {
        this._isMounted = true;
        this.getCompletedOrders();
      }
    componentWillUnmount() {
        this._isMounted = false;
    }
    getCompletedOrders = async () => {
        const url = `/dashboards/activeprocessors`
        const listedProducts = await GET(url);
        this.setState({
            "count": listedProducts.count
        });
    }
    render(){
        return(
            <Aux>
                <div className={styles.tile}>
                    <div >
                        <img className={styles.icon} src="https://image.flaticon.com/icons/svg/1306/1306675.svg" alt='why did we not download this?'/>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.title}>ACTIVE PROCESSORS</div>
                        <div className={styles.body}>
                            {this.state.count}
                        </div>
                        <div className={styles.footer}>
                        
                        </div>
                    </div>
                </div>
            </Aux>
        )
    }
}